import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../components";
import { RootState } from "../../../redux/store";
import * as Chart from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingXSC from "highcharts/modules/export-data";
import { HighChartUI } from "./subcomponent";
import useBreakpoint from "use-breakpoint";
import BREAKPOINTS from "../../../const/breakpoint";
import { getGraphCountInst } from "../../../redux/Common/api";
import { TYPE } from "../../../const/getType";
import { CardWraper } from "../../HqManagement/AbsenceManagement/unCovered/subcomponent";
drilldown(Chart);
exporting(Chart);
exportingXSC(Chart);

const Statiscs = (): ReactElement => {
  const { statistic, graphCount, isLoading, userInfo } = useSelector(
    (state: RootState) => ({
      statistic: state.Language.language.Tables.statistic,
      userInfo: state.login.userInfo,
      graphCount: state.common.graphCount,
      isLoading: state.common.isLoading,
    })
  );

  const { sectionTitle } = statistic;

  const dispatch = useDispatch();

  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = breakpoint === "mobile";
  const isDesktop = breakpoint === "desktop";

  const name = graphCount.map((dataa) => dataa.name);
  const graphData = graphCount.map((dataa) => dataa.data);

  const determineUser = () => {
    return {
      chart: {
        type: "areaspline",
      },
      title: {
        text: " ",
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: ["downloadCSV", "downloadXLS"],
          },
        },
        filename: "Absent Statiscs",
      },
      legend: {
        align: "right",
        x: -30,
        verticalAlign: "top",
        y: -10,
        floating: true,
        backgroundColor: "white",
        borderColor: "#2097f5",
        borderWidth: 1,
        shadow: false,
      },
      colors: [
        "#31b7f033",
        " #ffa8B6",
        "#fc527a",
        " #a9c542",
        "#8FC6FD",
        "gray",
      ],
      xAxis: {
        categories: [
          "January",
          "February",
          "March",
          "April ",
          "May",
          "June",
          "July",
          "August",
          "September ",
          "October",
          "November",
          "December",
        ],
        title: {},
      },
      yAxis: {
        labels: {
          format: "{value}",
        },
        title: {
          text: "",
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        areaspline: {
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
        },
      },
      tooltip: {
        shared: true,
        valueSuffix: " days",
      },
      series: [
        isLoading
          ? {
              name: "Loading..",
              data: [0],
            }
          : {
              name: name[0],
              data: graphData[0],
            },
        {
          name: name[1],
          data: graphData[1],
        },
        {
          name: name[2],
          data: graphData[2],
        },
        {
          name: name[3],
          data: graphData[3],
        },
        {
          name: name[4],
          data: graphData[4],
        },
        {
          name: name[5],
          data: graphData[5],
        },
      ],
    };
  };

  useEffect(() => {
    if (userInfo?.type === TYPE.CUSTOMER) {
      dispatch(
        getGraphCountInst({
          ins_id: userInfo?.data?.ins_id,
          date: "",
        })
      );
    } else {
      dispatch(
        getGraphCountInst({
          ins_id: userInfo?.data?.id,
          date: "",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin height={55}>
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        {/* <FlexWrapper>
          <Input
            value={date}
            inputType="date"
            width="30%"
            label={selectDate}
            onChange={(value: any) => {
              setdate(value);
              dispatch(
                getGraphCountInst({
                  ins_id:
                    userInfo?.data?.institute_id || userInfo?.data?.ins_id,
                  date: moment(value).format("YYYY-MM-DD"),
                })
              );
            }}
          />
        </FlexWrapper> */}
        <FlexWrapper>
          {isLoading ? (
            <Loader />
          ) : (
            <HighChartUI
              containerProps={{
                style: { width: isMobile || isDesktop ? "100%" : "90%" },
              }}
              highcharts={Chart}
              options={determineUser()}
            />
          )}
        </FlexWrapper>
      </CardWraper>
    </PageWrapper>
  );
};

export default Statiscs;
