import MenuItems1 from "./MenuItems1";

interface DropDownMenu {
  submenus: Array<any>;
  dropdown: any;
  depthLevel: any;
  to?: any;
}

const Dropdown = ({ submenus, dropdown, depthLevel }: DropDownMenu) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
  return (
    <ul
      style={{ fontWeight: "600" }}
      className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}
    >
      {submenus.map((submenu, index) => (
        <MenuItems1 items={submenu} key={index} depthLevel={depthLevel} />
      ))}
    </ul>
  );
};

export default Dropdown;
