import styled from "styled-components";
import Word from "../../asserts/pdf logo.png";
import { useRef, useState } from "react";

interface FilePickerProps {
  setImageFile: (file: File) => void;
  fileName: string;
}
export interface FontProsp {
  color?: string;
  fontFamily?: string;
  font?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  fontStyle?: string;
  paddingLeft?: string;
  letterSpacing?: string;
  textAlign?: string;
  hoverColor?: string;
}
const FilePicker = ({ setImageFile, fileName }: FilePickerProps) => {
  const ProfileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const handleClickFile = () => {
    if (ProfileRef.current) {
      ProfileRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(URL.createObjectURL(file));
      setImageFile(file);
    }
  };
  console.log(selectedFile, "selectedFile");
  return (
    <FilePickerContainer onClick={handleClickFile}>
      <WordImage src={Word} />
      <Title color="#222b40" font="10px" fontWeight={500}>
        {fileName ? fileName : "Click here Select the file"}
      </Title>
      <input
        type="file"
        ref={ProfileRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="application/pdf"
      />
    </FilePickerContainer>
  );
};

export default FilePicker;

export const FilePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WordImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

export const Title = styled.h1<FontProsp>`
  font-size: ${({ font }) => (font ? font : `36px`)};
  color: ${({ color }) => (color ? color : "black")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : null)};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : null)};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : null)};
  font-style: ${({ fontStyle }) => (fontStyle ? fontStyle : null)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : null)};
  span {
    color: #ff4880;
    font-style: italic;
  }
  &:hover {
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : "black")};
  }
`;
