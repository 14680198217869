import { ReactElement, useEffect, useState } from "react";
import {
  ActionButton,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { CardWraper } from "./subcomponents";
import { TableHeaderData } from "./const";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import {
  getFeedBack,
  GetMyCovered,
} from "../../../../../redux/Teachers/CoverManagenment/api";
import moment from "moment";
import { Rating } from "react-simple-star-rating";

const MyCoveredLesson = (): ReactElement => {
  const { userInfo, getMyCovered, isLoading } = useSelector(
    (state: RootState) => ({
      userInfo: state.login.userInfo,
      getMyCovered: state.coverManagement.getMyCovered,
      isLoading: state.coverManagement.isLoading,
    })
  );
  const dispatch: AppDispatch = useDispatch();
  const [date, setdate] = useState("");
  const [feedback, setFeedback] = useState<any>([]);

  useEffect(() => {
    dispatch(
      GetMyCovered({
        id: userInfo?.data.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noPadding
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle noMargin title="My Covered Lesson" />
          <FlexWrapper>
            <Input
              value={date}
              inputType="date"
              onChange={(value: string) => {
                setdate(value);
              }}
              height="auto"
              width="20"
            />
          </FlexWrapper>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getMyCovered // eslint-disable-next-line
                  ?.filter((listValues) => {
                    if (date === "") {
                      return listValues;
                    } else if (listValues?.v_date.includes(date)) {
                      return listValues;
                    }
                  })
                  ?.map((data, index) => {
                    const {
                      v_date,
                      from_time,
                      to_time,
                      subject,
                      year_group,
                      is_feedback_given,
                      vid,
                      first_name,
                      last_name,
                      publish_to_internal,
                      publish_to_external,
                    } = data;
                    return (
                      <tr style={{ textAlign: "center" }} key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(v_date).format("DD-MM-YYYY")}</td>
                        <td>{from_time}</td>
                        <td>{to_time}</td>
                        <td>{subject}</td>
                        <td>{year_group}</td>

                        <td>
                          <ActionButton
                            onClick={() => {
                              if (is_feedback_given === 0) {
                                dispatch(
                                  getFeedBack({
                                    vid: vid,
                                  })
                                ).then((res) => {
                                  if (
                                    res?.meta?.requestStatus === "fulfilled"
                                  ) {
                                    setFeedback(res?.payload?.message);
                                    if (res?.payload?.message?.length <= 0)
                                      alert("No Feedback is given");
                                  }
                                });
                              }
                            }}
                          >
                            {" "}
                            show
                          </ActionButton>
                        </td>
                        <td>
                          {publish_to_internal == "1"
                            ? `${first_name}${last_name}`
                            : publish_to_external == "1"
                            ? "External Sub"
                            : "none"}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
      <Modal
        show={!!feedback?.length}
        centered
        size="lg"
        onHide={() => {
          setFeedback([]);
        }}
      >
        <Modal.Header closeButton>Feedback</Modal.Header>
        <Modal.Body>
          <div>
            <span>Ratings</span>{" "}
            <Rating
              ratingValue={feedback[0]?.rating}
              iconsCount={5}
              readonly
              transition={true}
            />{" "}
            <br />
            <span>Feedback</span> <p>{feedback[0]?.feedback}</p>
          </div>
        </Modal.Body>
      </Modal>
    </PageWrapper>
  );
};

export default MyCoveredLesson;
