import moment from "moment";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionButton,
  EditableDropdown,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../components";
import { PrimaryButton } from "../../../../components/Button";
import { TYPE } from "../../../../const/getType";
import { getAbsentStaff } from "../../../../redux/Common/api";
import { RootState } from "../../../../redux/store";
import { CardWraper } from "../Vacancies/PublishedVacancies/subcomponents";
import * as XLSX from "xlsx";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import { Title } from "../../../../components/EditableDropdown/subcomponents";

const HQ_AbcentStaff = (): ReactElement => {
  const dispatch = useDispatch();
  const { AbsentStaff, userInfo, lang, isLoading } = useSelector(
    (state: RootState) => ({
      AbsentStaff: state.common.getAbsentStaff,
      isLoading: state.common.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.absentStaff,
    })
  );

  const [searchTerm, setsearchTerm] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState(true);

  const { tableHeader, sectionTitle } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(getAbsentStaff({ ins_id: userInfo?.data.id }));
    } else {
      dispatch(getAbsentStaff({ ins_id: userInfo?.data.ins_id }));
    }
  }, [dispatch, userInfo?.data.id, userInfo?.data.ins_id, userInfo?.type]);
  const filteredAbsentStaff = useMemo(
    () =>
      // eslint-disable-next-line array-callback-return
      AbsentStaff.filter((userFilter) => {
        if (searchTerm === "") {
          return userFilter;
        } else if (
          moment(userFilter.data.from_date)
            .format("YYYY-MM-DD")
            ?.toLowerCase()
            .includes(moment(searchTerm).format("YYYY-MM-DD"))
        ) {
          return userFilter;
        }
      }),
    [AbsentStaff, searchTerm]
  );
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredAbsentStaff.map((item) => ({
        "First Name": item.data.first_name,
        "Last Name": item.data.last_name,
        "From Date": moment(item.data.from_date).format("DD-MM-YYYY"),
        "To Date": moment(item.data.to_date).format("DD-MM-YYYY"),
        Duration: `${moment
          .duration(
            moment(item.data.to_time, "HH:mm:ss").diff(
              moment(item.data.from_time, "HH:mm:ss")
            )
          )
          .hours()}:${moment
          .duration(
            moment(item.data.to_time, "HH:mm:ss").diff(
              moment(item.data.from_time, "HH:mm:ss")
            )
          )
          .minutes()} hours`,
        "Leave Type": item.data.leave_type,
        "Substitute Required":
          item.data.substitute_required === 1 ? "Yes" : "No",
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Absent Staff");
    XLSX.writeFile(workbook, "Absent_Staff.xlsx");
  };
  const [filters, setfilters] = useState({
    leave: "",
    sub: "",
  });
  const [show, setShow] = useState(false);
  const [leave, setLeave] = useState({
    Name: "",
    LeaveType: "",
    SubstituteRequired: "",
    FromDate: "",
    ToDate: "",
    // Status: "",
    // Submittedon: "",
    // Approvedon: "",
    // Approvedby: "",
    // Reason: "",
    // StartTime: "",
    // EndTime: "",
    // Duriation: "",
    // Percentageofyourparentalleave: "",

    // Firstinitialofthechildsname: "",

    // Yearandmonthofthebirthdate: "",
    // Approximateduedate: "",
    // Additionalcomments: "",
    // Pcomments: "",
  });

  // const duration1 = moment.duration(leave.StartTime);
  // const duration2 = moment.duration(leave.EndTime);
  // const difference = duration2.subtract(duration1);
  // const hours = Math.floor(difference.asHours());
  // const day1 = moment(leave.FromDate);
  // const day2 = moment(leave.ToDate);

  // const totalDays = day2.diff(day1, "days") + 1;
  return (
    <PageWrapper>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        // size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Leave Details
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Name:{""} </b> {leave.Name}
              <br />
              <br />
              <b>Leave Type:{""} </b> {leave.LeaveType}
              <br />
              <br />
              <b>Substitute required:{""} </b>
              {leave.SubstituteRequired}
              <br />
              <br />
              <b>From date: {""} </b>
              {leave.FromDate}
              <br />
              <br />
              <b>To date:{""} </b>
              {leave.ToDate}
              <br />
              <br />
              {/* <b>Status:{""} </b>
              {leave.Status}
              <br />
              <br />
              <b>Submitted on: {""} </b>
              {leave.Submittedon}
              <br />
              <br />
              <b>
                {leave.Status === "Cancelled" ? "Denied on" : "Approved on"}:{" "}
                {""}
              </b>
              {leave.Approvedon}
              <br />
              <br />
              <b>
                {leave.Status === "Cancelled" ? "Deniedby" : "Approved by"}:{" "}
                {""}
              </b>
              {leave.Approvedby}
              <br />
              <br />
              {leave.Status === "Cancelled"
                ? `${(<b>Denied reason:{""} </b>)}${leave.Reason}`
                : ""}
              <b>StartTime:{""} </b>
              {leave.StartTime}
              <br />
              <br />
              <b>EndTime:{""} </b>
              {leave.EndTime} */}
              {/* <br />
              <br />
              <b>Duration: {""} </b>
              {leave.FromDate == leave.ToDate
                ? `${hours} hours`
                : `${totalDays} Days`} */}
              {/* <br />
              <br />
              <b> Comments:{""} </b> {leave.Additionalcomments}
              <br />
              <br />
              <b>Reason:{""} </b> {leave.Reason}
              <br />
              <br /> */}
              {/* {leave.LeaveType === "Parental leave" ? (
                <>
                  <b>Percentage of your parental leave: {""} </b>
                  {leave.Percentageofyourparentalleave}
                  <br />
                  <br />
                  <b>First initial of the childs name:{""} </b>
                  {leave.Firstinitialofthechildsname}
                  <br />
                  <br />
                  <b>Year and month of the birth date:{""} </b>{" "}
                  {leave.Yearandmonthofthebirthdate}
                  <br />
                  <br />
                  <b>Child not born yet</b>
                  <br />
                  <br />
                  <b>Additional comments:{""} </b>
                  {leave.Pcomments}
                  <br />
                  <br />
                  <b>Approximate due date:{""} </b> {leave.Approximateduedate}
                  <br />
                  <br />
                </>
              ) : (
                ""
              )} */}
            </Title>
          </FlexWrapper>

          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper width={isLoading ? "75%" : "85%"}>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
          {filter && (
            <FlexWrapper noMargin noPadding justifyContent="space-between">
              <PrimaryButton
                onClick={() => {
                  setsearchTerm(moment(new Date()).format("YYYY-MM-DD"));
                }}
              >
                Today
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  setsearchTerm(
                    moment(new Date().setDate(new Date().getDate() + 1)).format(
                      "YYYY-MM-DD"
                    )
                  );
                }}
              >
                Tomorrow
              </PrimaryButton>
              <PrimaryButton onClick={exportToExcel}>
                Export to Excel
              </PrimaryButton>
              <EditableDropdown
                width="200px"
                dropdownList={[
                  {
                    id: "01",
                    name: "All",
                  },
                  {
                    id: "02",
                    name: "Leave with Pay",
                  },
                  {
                    id: "03",
                    name: "Leave without pay",
                  },
                  {
                    id: "04",
                    name: "VAB",
                  },
                  {
                    id: "05",
                    name: "Vacation",
                  },
                  {
                    id: "06",
                    name: "Parental leave",
                  },
                  {
                    id: "07",
                    name: "Sick",
                  },
                ]}
                placeholder="Leave Type"
                handleSelect={(value: DropdownListProps) => {
                  setfilters({ ...filters, leave: value.name });
                }}
              />
              <EditableDropdown
                width="200px"
                dropdownList={[
                  {
                    id: "01",
                    name: "All",
                  },
                  {
                    id: "02",
                    name: "Yes",
                  },
                  {
                    id: "03",
                    name: "No",
                  },
                ]}
                placeholder="Substitute required"
                handleSelect={(value: DropdownListProps) => {
                  setfilters({ ...filters, sub: value.name });
                }}
              />
              <Input
                value={searchTerm}
                inputType="date"
                onChange={(e: any) => {
                  setsearchTerm(e);
                }}
                height="auto"
                width="auto"
              />
            </FlexWrapper>
          )}
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {AbsentStaff
                  // eslint-disable-next-line array-callback-return
                  .filter((userFilter) => {
                    if (searchTerm === "") {
                      return userFilter;
                    } else if (
                      moment(userFilter.data.from_date)
                        .format("YYYY-MM-DD")
                        ?.toLowerCase()
                        .includes(moment(searchTerm).format("YYYY-MM-DD"))
                    ) {
                      return userFilter;
                    }
                  })
                  .filter((listValuess) => {
                    if (filters.leave === "") {
                      return listValuess;
                    } else if (filters.leave === "All") {
                      return listValuess;
                    } else if (filters.leave === "Leave with Pay") {
                      return listValuess?.data.leave_type === "Leave with Pay";
                    } else if (filters.leave === "Leave without pay") {
                      return (
                        listValuess?.data.leave_type === "Leave without pay"
                      );
                    } else if (filters.leave === "VAB") {
                      return listValuess?.data.leave_type === "VAB";
                    } else if (filters.leave === "Vacation") {
                      return listValuess?.data.leave_type === "Vacation";
                    } else if (filters.leave === "Parental leave") {
                      return listValuess?.data.leave_type === "Parental leave";
                    } else if (filters.leave === "Sick") {
                      return listValuess?.data.leave_type === "Sick";
                    }
                  })
                  .filter((listValuess) => {
                    if (filters.sub === "") {
                      return listValuess;
                    } else if (filters.sub === "All") {
                      return listValuess;
                    } else if (filters.sub === "Yes") {
                      return listValuess.data.substitute_required == 1;
                    } else if (filters.sub === "No") {
                      return listValuess?.data.substitute_required == 0;
                    }
                  })
                  .map((item, i) => {
                    const fromTime = moment(item.data.from_time, "hh:ss:mm");
                    const endTime = moment(item.data.to_time, "hh:ss:mm");
                    const duration = moment.duration(endTime.diff(fromTime));
                    const getHours = duration.hours();
                    const minutes = duration.minutes();
                    let hh = getHours <= 10 ? `${getHours}` : getHours;
                    let mm = minutes <= 10 ? `0${minutes}` : minutes;

                    const fromdateFormate = moment(item.data.from_date).format(
                      "M/DD/YYYY"
                    );
                    const todateFormate = moment(item.data.to_date).format(
                      "M/DD/YYYY"
                    );
                    var fDt = moment(fromdateFormate, "M/DD/YYYY");
                    var tDt = moment(todateFormate, "M/DD/YYYY");
                    var dayDiff = tDt.diff(fDt, "days");

                    return (
                      <tr
                        key={i}
                        style={{ textAlign: "center" }}
                        onClick={() => {
                          setLeave({
                            Name: `${item.data.first_name} ${item.data.last_name}`,
                            LeaveType: item.data.leave_type,
                            SubstituteRequired:
                              item.data.substitute_required === 0
                                ? "No"
                                : "Yes",
                            FromDate: moment(item.data.from_date).format(
                              "YYYY-MM-DD"
                            ),
                            ToDate: moment(item.data.to_date).format(
                              "YYYY-MM-DD"
                            ),
                            // Status:
                            //   item.is_denied === true
                            //     ? "Cancelled"
                            //     : item.is_approved === 0
                            //     ? "Pending"
                            //     : "Approved",
                            // Submittedon: moment(item.created_at).format(
                            //   "YYYY-MM-DD"
                            // ),
                            // Approvedon: moment(
                            //   item.approved_or_denied_on,
                            //   "DD-MM-YYYY"
                            // ).format("YYYY-MM-DD"),
                            // Approvedby: item.approved_by,
                            // Reason: item.reason,
                            // StartTime: item.from_time,
                            // EndTime: item.to_time,
                            // Duriation: `${
                            //   hh === "0" && mm === "00"
                            //     ? `${`${dayDiff + 1} days`}`
                            //     : hh > "01"
                            //     ? `${hh}:${mm} hours `
                            //     : `${hh}:${mm} hour`
                            // }`,
                            // Percentageofyourparentalleave:
                            //   item.Parental_leave_percentage,

                            // Firstinitialofthechildsname: item.child_first_name,

                            // Yearandmonthofthebirthdate: item.parental_birth_date
                            //   ? moment(item.parental_birth_date).format(
                            //       "YYYY/MM"
                            //     )
                            //   : "N/A",
                            // Approximateduedate: item.appro_due_date
                            //   ? moment(item.appro_due_date).format("YYYY/MM/DD")
                            //   : "N/A",
                            // Additionalcomments: item.additional_comment,
                            // Pcomments: item.parental_additional_comment,
                          });
                        }}
                      >
                        <td>{++i}</td>
                        {/* <td>{item.data?.company_name}</td> */}
                        <td>{item.data.first_name}</td>
                        <td>{item.data.last_name}</td>
                        <td>
                          {moment(item.data.from_date).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          {moment(item.data.to_date).format("DD-MM-YYYY")}
                        </td>
                        <td>{`${
                          hh === "0" && mm === "00"
                            ? `${`${dayDiff + 1} days`}`
                            : hh > "01"
                            ? `${hh}:${mm} hours `
                            : `${hh}:${mm} hour`
                        }`}</td>
                        <td>{item.data.leave_type}</td>
                        <td>
                          {item.data.substitute_required === 1 ? "Yes" : "No"}
                        </td>
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => setShow(true)}
                        >
                          {" "}
                          View
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_AbcentStaff;
