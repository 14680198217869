import React from "react";
import { PageWrapper } from "../../../components";

const Employment = () => {
  return (
    <div>
      <PageWrapper>
        <div>
          <h5>
            Allmänna villkor för särskilt visstidsanställning - intermittent
            timanställning
          </h5>
          Arbetsgivaren (Dimensions of HR - DoHR) och Arbetstagaren har kommit
          överens om att följa dessa allmänna villkor som gäller för varje
          enskilt jobb Arbetstagaren accepterar via DoHR mobilappen eller den
          webbaserade appen som publiceras vid behov. Dessa villkor träder i
          kraft när Arbetstagaren accepterar ett jobb via DoHR mobilappen eller
          den webbaserade appen och godkänner anställningsvillkoren vid varje
          enskilt tillfälle. <br />
          <br /> Dessa villkor gäller för tidsperioden mellan starttid och
          sluttid för varje enskilt jobb som Arbetstagaren accepterar via DoHR
          mobilappen eller den webbaserade appen och utför hos Kunden. Så snart
          Arbetstagaren slutför varje enskilt jobb hos Kunden upphör denna
          anställning hos Arbetsgivaren samt dessa anställningsvillkor.
          Arbetstagaren har ingen skyldighet att acceptera framtida jobb. Likaså
          har Arbetsgivaren Inte heller någon skyldighet att erbjuda
          Arbetstagaren fler möjligheter till arbete. <br />
          <br /> Arbetstagaren är anlitad och försäkrad av Arbetsgivaren medan
          denne utför arbete hos Kunden vid varje enskilt jobb denne accepterar.{" "}
          <br />
          <br /> Arbetstagaren ansvarar för att meddela Arbetsgivaren när dennes
          huvudsakliga sysselsättning ändras och underlåtenhet att göra så
          kommer att leda till upphörandet av anställningen.. <br />
          <br /> Arbetsgivaren förbehåller sig också rätten att begära bevis på
          Arbetstagarens huvudsakliga sysselsättning och underlåtenhet att
          tillhandahålla detta kommer att leda till upphörandet av
          anställningen. <br />
          <br />
          <h5>Betalning av arbetade timmar</h5>
          Betalning för arbetade timmar sker månadsvis den 25:e varje månad för
          arbete utfört under föregående månad eller enligt överenskommelse. I
          det fall den 25:e infaller på en röd dag kommer lön att utbetalas den
          närmast föregående vardag. <br />
          <br /> Betalningen sätts in på kontonumret Arbetstagaren angett i sin
          profil i appen eller enligt överenskommelse, till exempel genom Swish.
          Arbetsgivaren kan inte säkerställa betalning i tid om Arbetstagaren
          inte anger ett giltigt kontonummer eller om hen inte skickar in en
          tidrapport för kundens godkännande. Arbetstagaren ansvarar för att
          korrekt rapportera arbetade timmar inom 24 timmar efter att hen har
          slutfört varje jobb. När Arbetstagarens tidrapport har godkänts av
          Kunden kommer hen att få betalt. <br />
          <br /> Semesterersättning på 12,5% och eventuella tillämpliga
          ersättningar (såsom OB) betalas ut tillsammans med varje utbetalning.
          I enlighet med skatteverkets föreskrifter kommer Arbetsgivaren att dra
          av och betala skatt på Arbetstagarens bruttolön. För sidoinkomster
          betalas inkomstskatt enligt skatteverkets föreskrifter som nu är 30%.
          Om jobbet kräver resa samt hotellövernattning får Arbetstagare
          ersättning enligt skatteverkets föreskrifter om kostnadsersättning,
          bilersättning och traktamenten. Arbetstagarens lönebesked kommer att
          skickas till den registrerade e-post varje månad. Arbetsgivaren
          ansvarar för alla aspekter av ersättning, inklusive löner.
          <br />
          <br />
          <h5>Anställningen</h5>
          <br />
          <br />
          Kunden ansvarar för att tillhandahålla arbetsinstruktioner och ledning
          för jobbet. <br />
          <br /> I det fall Arbetstagaren inte kan genomföra det accepterade
          jobbet måste hen avboka det via DoHR mobilappen eller DoHR webbaserade
          appen snarast möjligt. Anställningsvillkor för det avbokade jobbet
          upphör automatiskt. <br />
          <br /> Om en kund avbokar ett jobb som Arbetstagaren har accepterat
          måste hen kontakta oss via mejl och meddela oss om hen fortfarande är
          intresserad av att arbeta den dagen. Arbetstagaren måste vara beredd
          att acceptera lediga jobb i sitt län om hen fortfarande vill ha en
          anställning den dagen. Arbetstagaren har rätt att tacka nej till ett
          av de lediga jobben, men hen förlorar även rätt till ersättning.{" "}
          <br />
          <br /> Om Kunden begär att Arbetstagaren ska jobba utöver de timmar
          som hen har accepterat genom ett publicerat jobb via DoHR mobilappen
          eller DoHR webbaserade appen, kan hen välja att tacka ja till det om
          tillgängligheten tillåter och hen kan rapportera de nya arbetade
          timmar när hen lämnar in tidrapport för det jobbet. Vid inlämning av
          tidrapporten är det viktigt att nämna vem som begärde detta. En sådan
          begäran måste göras skriftligen av Kunden. Om Arbetstagaren blir
          ombedd att göra det är det Arbetstagarens ansvar att behålla bevis för
          en sådan begäran. <br />
          <br /> Övertid måste vara beordrad av Arbetsgivaren i förhand. De
          regler avseende övertid och dess ersättning som gäller hos
          Kundföretaget kommer att tillämpas. <br />
          <br /> Om Arbetstagaren erbjuds ett jobb av Kunden som inte publiceras
          via DoHR mobilappen eller den webbaserade appen, omfattas det inte av
          dessa villkor och Arbetstagaren är inte anställd av Arbetsgivaren.{" "}
          <br />
          <br />
          <h5>Regler och uppförande vid arbete</h5>
          <br />
          Arbetstagaren ska följa kundföretagets policyer och rutiner när hen
          arbetar hos en Kund:
          <ul>
            <li>
              {" "}
              viktigt att komma i tid till jobbet och följa angivna
              instruktioner från Kunden
            </li>
            <li> viktigt att följa Kundens säkerhetsföreskrifter</li>
            <li> viktigt att klä sig professionellt</li>
            <li>
              {" "}
              viktigt att följa de regler som gäller hos Kunden, så länge de
              inte strider mot dessa villkor
            </li>
            <li>
              {" "}
              användning av mobiltelefoner och hörlurar för privata ändamål är
              inte tillåtet förutom när det är nödvändigt
            </li>
            <li>
              ingen tolerans för diskriminering - detta omfattar diskriminering
              på grund av kön, transpersoners identitet eller uttryck,
              etnicitet, religion eller övertygelse, funktionshinder, sexuell
              läggning och ålder{" "}
            </li>
            <li> ingen tolerans för alkohol- eller droganvändning </li>
          </ul>
          <br />
          <br />
          <h5>Sekretess</h5>
          <br />
          När Arbetstagaren utför ett arbete för Arbetsgivaren och dess Kund
          samtycker hen till att inte lämna ut till någon tredje part eller
          använda obehörig information som hen tar del av under jobbets gång.
          Arbetstagaren samtycker till att inte dela de personuppgifter som hen
          kommer i kontakt med under arbete hos Kunden med Arbetsgivaren och
          dess anställda och andra tredje parter.
          <br />
          <br />
          Sekretessförbindelsen gäller även för all information Arbetstagaren
          får tillgång till via Arbetsgivaren, inklusive DoHR mobilappen och
          DoHR webbaserade appen som är skyddad som företagshemlighet enligt lag
          om skydd för företagshemligheter. <br />
          <br /> Tystnadsplikten gäller både under och efter Arbetstagarens
          anställning. Arbetsgivaren har rätt att kräva ersättning om
          Arbetstagaren bryter mot tystnadsplikten och orsakar skada för
          Arbetsgivaren. <br />
          <br /> Arbetstagaren förbinder sig att följa dessa villkor vid varje
          enskilt accepterat jobb via DoHR mobilappen eller den webbaserade
          appen.
          <br />
          <br />
          <h5>Övrigt</h5>
          <br />
          På dessa villkor tillämpas svensk lag. <br />
          <br /> Arbetsgivaren förbehåller sig rätten att göra ändringar till
          dessa villkor som finns tillgängliga på DoHR mobilappen eller den
          webbaserade appen. Dessa villkor ersätter tidigare undertecknade
          villkor med Arbetstagaren.
        </div>
      </PageWrapper>
    </div>
  );
};

export default Employment;
