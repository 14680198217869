import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getTodayAbsenceCount,
  getAbsentStaff,
  getTodayCoverCount,
  getTodayLessonCount,
  getMyConsultants,
  getTodayUnCoverCount,
  getTodayUnLessonCount,
  getCoverCount,
  getUnCoverCount,
  getLessonCount,
  getUnLessonCount,
  getAbsenceCount,
  getTommorowAbsenceCount,
  getTommorowCoverCount,
  getTommorowLessonCount,
  getTommorowUnCoverCount,
  getTommorowUnLessonCount,
  getChooseConsultant,
  addMyConsultant,
  getGraphCountInst,
  getmyInsConsultant,
  CommonDelete,
  handleOpenApplication,
  Getwebsitead,
  handleVacanciesApplication,
  getInsHours,
  getPositions,
} from "./api";
import {
  initialState,
  getMyconsultant,
  AbsentStaffData,
  ChooseConsultant,
  graphData,
  OnChangeHandler,
  ApplicationProps,
  websitecreatead,
  VacanciesApplicationProps,
  GetInsTopHoursResposne,
  GetPositionResposne,
} from "./types";

export const CommonApiSlices = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    addApplicationUser: (state, action: PayloadAction<OnChangeHandler>) => {
      const keys = Object.keys(action.payload)[0];

      const userInfo = {
        ...state.userApplicationProsp,
        [keys]: action.payload[keys],
      };
      state.userApplicationProsp = userInfo;
    },
    updatefaq: (state, action: PayloadAction<string>) => {
      state.faq = action.payload;
    },
    updateRegister: (state, action: PayloadAction<boolean>) => {
      state.hasRegister = action.payload;
    },

    addVacanciesApplicationUser: (
      state,
      action: PayloadAction<OnChangeHandler>
    ) => {
      const keys = Object.keys(action.payload)[0];

      const userInfo = {
        ...state.VacanciesuserApplicationProsp,
        [keys]: action.payload[keys],
      };
      state.VacanciesuserApplicationProsp = userInfo;
    },

    updatevacancyapplicationuser: (state) => {
      state.VacanciesuserApplicationProsp = {
        first_name: "",
        last_name: "",
        address: "",
        email_id: "",
        postalcode: "",
        area_name: "",
        phone_number: "",
        preferred_location: "",
        description: "",
        city: "",
        date_of_birth: null,
        preferred_location_1: "",
        right_to_work_in_Sweden_attachment: "",
        Iam_EU_citizen: null,
        Iam_Non_EU_citizen: null,
        linkedIn: "",
        occup_sector: "",
        occup_sector_id: "",
        studyingteacher: null,
        swedish_bank_account: null,
        otheroccupationfield: "",
        position_id: "",
        working_evening_or_weekends: null,
        qualifiedteacherdate: null,
        work_exp_in_school: null,
        language_skills: { english: "", swedish: "", other: [] },
        info_work_exp_in_school: [],
        work_exp_out_school: null,
        info_work_exp_out_school: [],
        subjectlist: [],
        specificField: "",
        agegroup: [],
        subjectlistno: [],
        agegroupno: [],
        subjectlistyes: [],
        agegroupyes: [],
        employment_history: [],
        relevant_education: [],
        qualifiedTeacher: null,
        swedishLara: null,
        instutionstudyteacher: "",
        preferWorkIn: [],
        occupation: "",
        position: "",
        work_start: null,
        fileswedishlara: "",
        howDoYouKnowUs: "",
        Driving_license: null,
        right_to_work_in_Sweden: null,
        marketing_purpose: null,
        accurate_information: null,
        terms_and_conditions: null,
      };
    },
    updateopenapplicationuser: (state) => {
      state.userApplicationProsp = {
        first_name: "",
        last_name: "",
        address: "",
        email_id: "",
        postalcode: "",
        area_name: "",
        position_id: "",
        phone_number: "",
        preferred_location: "",
        description: "",
        city: "",
        date_of_birth: null,
        preferred_location_1: "",
        right_to_work_in_Sweden_attachment: "",
        Iam_EU_citizen: null,
        Iam_Non_EU_citizen: null,
        linkedIn: "",
        occup_sector: "",
        occup_sector_id: "",
        studyingteacher: null,
        swedish_bank_account: null,
        otheroccupationfield: "",
        working_evening_or_weekends: null,
        qualifiedteacherdate: null,
        work_exp_in_school: null,
        language_skills: { english: "", swedish: "", other: [] },
        info_work_exp_in_school: [],
        work_exp_out_school: null,
        info_work_exp_out_school: [],
        subjectlist: [],
        specificField: "",
        agegroup: [],
        subjectlistno: [],
        agegroupno: [],
        subjectlistyes: [],
        agegroupyes: [],
        employment_history: [
          {
            from: null,
            to: null,
            role: "",
            responsibility: "",
            employer: "",
          },
        ],
        relevant_education: [
          {
            ins_name: "",
            education: "",
            from: null,
            to: null,
          },
        ],
        qualifiedTeacher: null,
        swedishLara: null,
        instutionstudyteacher: "",
        preferWorkIn: [],
        occupation: "",
        position: "",
        work_start: null,
        fileswedishlara: "",
        howDoYouKnowUs: "",
        Driving_license: null,
        right_to_work_in_Sweden: null,
        marketing_purpose: null,
        accurate_information: null,
        terms_and_conditions: null,
      };
    },
  },
  extraReducers: {
    [getAbsentStaff.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getAbsentStaff.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<AbsentStaffData>>
    ) => {
      state.isLoading = false;
      state.getAbsentStaff = action.payload;
    },
    [getAbsentStaff.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getMyConsultants.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getMyConsultants.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMyconsultant>>
    ) => {
      state.isLoading = false;
      state.getMyconsultant = action.payload;
    },
    [getMyConsultants.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getTodayCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.todaycoverCount = action.payload;
    },
    [getTodayUnCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.todayunCoverCount = action.payload;
    },
    [getTodayLessonCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.todaylessonCount = action.payload;
    },
    [getTodayUnLessonCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.todayUnLessonCount = action.payload;
    },
    [getTodayAbsenceCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.todayabsenceCount = action.payload;
    },

    ////custom
    [getCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.customcoverCount = action.payload;
    },
    [getUnCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.customunCoverCount = action.payload;
    },
    [getLessonCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.customlessonCount = action.payload;
    },
    [getUnLessonCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.customUnLessonCount = action.payload;
    },
    [getAbsenceCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.customabsenceCount = action.payload;
    },
    ///// tommorow
    [getTommorowCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.tommorowcoverCount = action.payload;
    },
    [getTommorowUnCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.tommorowunCoverCount = action.payload;
    },
    [getTommorowLessonCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.tommorowlessonCount = action.payload;
    },
    [getTommorowUnLessonCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.tommorowUnLessonCount = action.payload;
    },
    [getTommorowAbsenceCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.tommorowabsenceCount = action.payload;
    },
    [getChooseConsultant.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<ChooseConsultant>>
    ) => {
      state.isLoading = false;
      state.chooseConsultant = action.payload;
    },
    [addMyConsultant.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.addMyConsultantMsg = action.payload;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [getGraphCountInst.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getGraphCountInst.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<graphData>>
    ) => {
      state.isLoading = false;
      state.graphCount = action.payload;
    },
    [getGraphCountInst.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getmyInsConsultant.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getmyInsConsultant.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMyconsultant>>
    ) => {
      state.isLoading = false;
      state.getmyInstituteCons = action.payload;
    },
    [getmyInsConsultant.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [CommonDelete.pending.toString()]: (state) => {
      state.isLoading = false;
      state.reload = false;
    },
    [CommonDelete.fulfilled.toString()]: (state) => {
      state.isLoading = false;
      state.reload = true;
    },
    [CommonDelete.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.reload = false;
    },

    [handleOpenApplication.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [handleOpenApplication.fulfilled.toString()]: (
      state,
      action: PayloadAction<ApplicationProps>
    ) => {
      state.isLoading = false;
      state.hasError = false;
      state.hasRegister = true;
      window.scrollTo({ top: 0 });
      state.userApplicationProsp = action?.payload;
    },
    [handleOpenApplication.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      window.scrollTo({ top: 0 });
    },

    [Getwebsitead.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [Getwebsitead.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<websitecreatead>>
    ) => {
      state.isLoading = false;
      state.Websitecreateadapp = action.payload;
    },
    [Getwebsitead.rejected.toString()]: (state) => {
      state.isLoading = false;
    },

    [handleVacanciesApplication.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasError = false;
    },
    [handleVacanciesApplication.fulfilled.toString()]: (
      state,
      action: PayloadAction<VacanciesApplicationProps>
    ) => {
      state.isLoading = false;
      state.hasError = false;
      state.hasRegister = true;
      window.scrollTo({ top: 0 });
      state.VacanciesuserApplicationProsp = action?.payload;
    },
    [handleVacanciesApplication.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasError = true;
      window.scrollTo({ top: 0 });
    },

    [getInsHours.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getInsHours.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetInsTopHoursResposne>>
    ) => {
      state.isLoading = false;
      state.getInsHoursList = action.payload;
    },
    [getInsHours.rejected.toString()]: (state) => {
      state.isLoading = false;
    },

    [getPositions.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getPositions.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetPositionResposne>>
    ) => {
      state.isLoading = false;
      state.getPositionList = action.payload;
    },
    [getPositions.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default CommonApiSlices.reducer;
