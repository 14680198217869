import React from "react";
import {
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
} from "../../../../components";
import { VacanciesApplicationProps } from "../../../../redux/Common/types";
import { RootState } from "../../../../redux/store";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import WorkExperience from "./workExperience";
import EducationalDetails from "./education";
import Input2 from "../../../../components/Input2";
import { addVacanciesApplicationUser } from "../../../../redux/Common/action";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";

const EducationalSector = ({
  error,
  validateForms,
  worIn,
  worOut,
  name_of_emp,
  key_res,
  title_of_role,
  duration,
  addFormFieldsin,
  addFormFieldsOut,
  removeFormFieldsin,
  removeFormFieldsOut,
  name_of_empout,
  durationout,
  title_of_roleout,
  key_resout,
}: Partial<VacanciesApplicationProps>) => {
  const { string, values } = useSelector(
    (state: RootState) => ({
      string: state.Language.language,
      common: state.common,
      values: state.common
        .VacanciesuserApplicationProsp as VacanciesApplicationProps,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const {
    applicationForm: {
      stateyourOccupation,
      jobTitle,
      position,
      teaching,
      non_teaching,
    },
  } = string;
  return (
    <>
      <FlexWrapper width="100%" noMargin noPadding>
        <DropdownWrapper width="30">
          <EditableDropdown
            placeholder={stateyourOccupation}
            dropdownList={position}
            onBlur={() => {
              validateForms("position");
            }}
            error={error.position}
            handleSelect={(value: DropdownListProps) => {
              dispatch(
                addVacanciesApplicationUser({
                  position: value.name,
                })
              );
              dispatch(
                addVacanciesApplicationUser({
                  position_id: value.id,
                })
              );
            }}
          />
        </DropdownWrapper>
        {values.position === position[0].name && (
          <>
            <DropdownWrapper width="20">
              <Input2
                value={values.occupation}
                label={jobTitle}
                error={error.occupation}
                onBlur={() => {
                  validateForms("occupation");
                }}
                dropdownList={teaching}
                onChange={(value) => {
                  dispatch(
                    addVacanciesApplicationUser({
                      occupation: value,
                    })
                  );
                }}
              />
            </DropdownWrapper>
            <EducationalDetails error={error} validateforms={validateForms} />
          </>
        )}
        {values.position === position[1].name && (
          <DropdownWrapper width="20">
            <Input2
              value={values.occupation}
              label={jobTitle}
              error={error.occupation}
              onBlur={() => {
                validateForms("occupation");
              }}
              dropdownList={non_teaching}
              onChange={(value) => {
                dispatch(
                  addVacanciesApplicationUser({
                    occupation: value,
                  })
                );
              }}
            />
          </DropdownWrapper>
        )}
        {values.occupation === "Other" && (
          <DropdownWrapper width="20">
            <Input2
              value={values.otheroccupationfield}
              label={"Job title"}
              error={error.otheroccupationfield}
              onBlur={() => {
                validateForms("otheroccupationfield");
              }}
              onChange={(value) => {
                dispatch(
                  addVacanciesApplicationUser({
                    otheroccupationfield: value,
                  })
                );
              }}
            />
          </DropdownWrapper>
        )}
        {/* <CheckBoxWrapper
          style={{
            width: "100%",
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: "0px",
          }}
        >
          <DropdownWrapper width="100" marginTop="0px">
            <Title>{preferredWorkIns}</Title>
          </DropdownWrapper>
          <FlexWrapper noPadding noMargin style={{ marginTop: "-1%" }}>
            <DropdownWrapper
              width="100"
              // className={
              //   submitted && !isAtLeastOneCheckboxSelected() ? "errors" : ""
              // }
            >
              <Form.Check
                name="preschool"
                label={preSchool}
                checked={isCheckboxChecked("preschool")}
                onClick={() => handleCheckboxChange("preschool")}
                style={{
                  paddingRight: "2%",
                  marginTop: "8px",
                  color: colors.darkBlue,
                  fontSize: "15px",
                }}
              />
              <Form.Check
                name="School"
                label={school}
                checked={isCheckboxChecked("school")}
                onClick={() => handleCheckboxChange("school")}
                style={{
                  paddingRight: "2%",
                  marginTop: "8px",
                  color: colors.darkBlue,
                  fontSize: "15px",
                }}
              />{" "}
              <Form.Check
                name="Gymnasium"
                label="Gymnasium"
                checked={isCheckboxChecked("Gymnasium")}
                onClick={() => handleCheckboxChange("Gymnasium")}
                style={{
                  paddingRight: "2%",
                  marginTop: "8px",
                  color: colors.darkBlue,
                  fontSize: "15px",
                }}
              />
            </DropdownWrapper>
          </FlexWrapper>
        </CheckBoxWrapper> */}
      </FlexWrapper>
      {values.position === position[1].name && (
        <WorkExperience
          error={error}
          validateforms={validateForms}
          worIn={worIn}
          worOut={worOut}
          name_of_emp={name_of_emp}
          key_res={key_res}
          title_of_role={title_of_role}
          duration={duration}
          addFormFieldsin={addFormFieldsin}
          addFormFieldsOut={addFormFieldsOut}
          removeFormFieldsin={removeFormFieldsin}
          removeFormFieldsOut={removeFormFieldsOut}
          name_of_empout={name_of_empout}
          durationout={durationout}
          title_of_roleout={title_of_roleout}
          key_resout={key_resout}
        />
      )}
    </>
  );
};

export default EducationalSector;
