import { useState, useEffect } from "react";
import { DropdownButton, Dropdown, FormControl } from "react-bootstrap";
import {
  ListInput,
  DropdownInput,
  EditDropdownWrapper,
  Title,
} from "./subcomponents";
import { EditableDropdownProps } from "./typings";
import { colors } from "../../const/theme";
import { Tooltiped } from "../../pages/HqManagement/AbsenceManagement/unCovered/subcomponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditableDropdown = ({
  isRequired,
  width,
  title,
  dropdownList,
  placeholder,
  handleSelect,
  onBlur,
  error,
  isDisabled,
  defaultValue,
  reset,
  hideIndupt,
  toolTip,
}: EditableDropdownProps) => {
  const { name, id } = defaultValue || {};
  const [selectdItem, setSelectedItem] = useState({
    name: name || "",
    id: id || "",
  });

  useEffect(() => {
    if (!!selectdItem.name && !dropdownList.length) {
      setSelectedItem({
        name: "",
        id: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownList]);

  useEffect(() => {
    if (reset) {
      setSelectedItem({
        name: "",
        id: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <EditDropdownWrapper
      width={width}
      isDisabled={isDisabled}
      id="editable-dropdown"
    >
      {title && (
        <Title>
          {`${title}`}
          {isRequired && (
            <strong style={{ color: "red", paddingLeft: "2px" }}>*</strong>
          )}
          {toolTip && (
            <Tooltiped title={toolTip} placement="right">
              <span style={{ color: "#320cdb", marginLeft: "5px" }}>
                <FontAwesomeIcon icon={["fas", "info-circle"]} size="sm" />
              </span>
            </Tooltiped>
          )}
        </Title>
      )}
      <ListInput>
        {hideIndupt ? (
          ""
        ) : (
          <DropdownInput
            id="edu-input"
            readOnly
            placeholder={placeholder}
            aria-label={placeholder}
            onBlur={() => onBlur && onBlur()}
            value={selectdItem.name}
            isValid={!error && !!selectdItem.name}
            isInvalid={!!error}
          />
        )}

        <DropdownButton
          variant="outline-secondary"
          title=""
          id="dropdown-id"
          align="start"
          onSelect={(eventKey) => {
            const value = dropdownList[Number(eventKey)];
            setSelectedItem(value);
            handleSelect(value);
          }}
        >
          {dropdownList.map((item, index) => (
            <Dropdown.Item
              eventKey={index}
              id="item"
              key={`dropdown-${index}`}
              style={{
                color: colors.darkBlue,
                fontFamily: "Montserrat",
                fontSize: "15px",
              }}
            >
              {item?.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        {/* <FormControl.Feedback type="invalid">{error}</FormControl.Feedback> */}
      </ListInput>
    </EditDropdownWrapper>
  );
};

export default EditableDropdown;
