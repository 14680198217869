import { ReactElement } from "react";
import { Route, Redirect } from "react-router-dom";
import ROUTES from "../../const/routes";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface ProtectedRouteProps {
  path: string;
  component: any;
}

const ProtectedRoute = ({
  path,
  component,
}: ProtectedRouteProps): ReactElement => {
  const { isLoggedIn } = useSelector(
    (state: RootState) => ({
      isLoggedIn: state.login.isLoggedIn,
    }),
    shallowEqual
  );
  return isLoggedIn ? (
    <Route exact path={path} component={component} />
  ) : (
    <Redirect to={ROUTES.LANDING_PAGE} />
  );
};

export default ProtectedRoute;
