import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faMobile,
  faUser,
  faUserGroup,
  faSquarePollVertical,
  faClipboardList,
  faPeopleArrows,
  faLock,
  faPen,
  faMobileAlt,
  faBars,
  faInfo,
  faCogs,
  faTrophy,
  faUserCog,
  faFolder,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faTachometerAlt,
  faUserPlus,
  faInfoCircle,
  faDonate,
  faUserTag,
  faNewspaper,
  faUserCircle,
  faSearch,
  faMedal,
  faLineChart,
  faLayerGroup,
  faTimesCircle,
  faUserCheck,
  faXmark,
  faGear,
  faSliders,
  faPencil,
  faFilePdf,
  faPaperPlane,
  faSpinner,
  faPlus,
  faCircleInfo,
  faArrowDownLong,
  faCheck,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

import {
  faStickyNote,
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faEye,
  faFileAlt,
  faBell,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";

import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faCheck,
  faMobile,
  faUser,
  faInfo,
  faPlus,
  faUserGroup,
  faSquarePollVertical,
  faClipboardList,
  faLayerGroup,
  faPeopleArrows,
  faLock,
  faMobileAlt,
  faSliders,
  faBars,
  faCogs,
  faTrophy,
  faStickyNote,
  faUserCog,
  faFolder,
  faCircleInfo,
  faChevronDown,
  faChevronRight,
  faTachometerAlt,
  faUserPlus,
  faInfoCircle,
  faMinus,
  faTrashAlt,
  faEdit,
  faEyeSlash,
  faLineChart,
  faPen,
  faEye,
  faDonate,
  faUserTag,
  faNewspaper,
  faChevronLeft,
  faUserCircle,
  faFileAlt,
  faBell,
  faSearch,
  faMedal,
  faTimesCircle,
  faUserCheck,
  faXmark,
  faGear,
  faBell,
  faTrashCan,
  faPencil,
  faFilePdf,
  faFacebook,
  faLinkedin,
  faTwitter,
  faPaperPlane,
  faSpinner,
  faArrowDownLong
);
