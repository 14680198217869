import styled from "styled-components";
import { H3 } from "../../../../typography";

export const FormLabel = styled.p`
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin-top: 8px;
`;

export const CardHeader = styled(H3)`
  background-color: #2097f5;
  color: white;
  padding: 0.5rem 1rem;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border-bottom: 1px solid #2097f5;
  margin-bottom: 0;
`;
