import api from "../../services";
import apiEndpoints from "../../const/apiEndpoints";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ManagementStatus } from "./types";
import { getCreatedCustomer } from "../login/api";
import { RootState } from "../store";

export const EditCustomerStatus = createAsyncThunk(
  "institute/EditCustomerStatus",
  async (requestPayload: ManagementStatus, { dispatch, getState }) => {
    const {
      login: { userInfo },
    } = getState() as RootState;
    const response = await api.post(
      apiEndpoints.changeCustomerStatus,
      requestPayload
    );
    if (response.status === 200) {
      dispatch(
        getCreatedCustomer({
          ins_id: userInfo?.data.id,
        })
      );
    }
    return response.data;
  }
);
