import { ReactElement, useEffect } from "react";
import { PageWrapper } from "../../Login/subcomponent";
import {
  CardContainer,
  CardImage,
  CardText,
  CardTitle,
  GetStartButton,
  Header,
  InfoHeader,
  MobileWrapper,
  Para,
  JobSeekerHeader,
} from "../Customer/subcomponent";
import Aos from "aos";
import { FlexWrapper, Footers } from "../../../components";
import ROUTES from "../../../const/routes";
import { useHistory } from "react-router-dom";
import { AppText, ContentContainer, ContentWrapper } from "./subcomponents";
import { Card } from "react-bootstrap";
import { colors } from "../../../const/theme";
import Like from "../../../asserts/jonSeekar/like.png";
import Curriculam from "../../../asserts/jonSeekar/curriculum.png";
import Vac from "../../../asserts/jonSeekar/vac.png";
import vacancies from "../../../asserts/vacancies.png";
import { Link } from "react-scroll";
import { StyledCard } from "../../Login/ourSolution/staffAbsence/subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const JobSeeker = (): ReactElement => {
  const history = useHistory();
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));
  const {
    JobSeekerHeader1,
    JobSeekerHeader2,
    JobseekerPara1_1,
    JobSeekerPara,
    JobSeekerPara1,
    JobSeekerPara2,
    Curentopenings,
    Openapplications,
    application,
    browse,
    fill,
    fill1,
    fill2,
    govacancy,
    next,
    review,
    review1,
    review2,
    work,
    openapplication,
  } = lang.signUp.getstarted_jobseekers;

  return (
    <PageWrapper>
      <MobileWrapper background="linear-gradient(to right, #002C99, #2097F5)">
        <div data-aos="fade-right">
          <JobSeekerHeader>
            {JobSeekerHeader1} <br /> {JobSeekerHeader2}
          </JobSeekerHeader>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <Para centered width="90vw">
              {JobSeekerPara}
            </Para>
            <Para centered>
              {JobseekerPara1_1}
              <br />
              {JobSeekerPara1} <br /> {JobSeekerPara2}
            </Para>
          </FlexWrapper>
          <FlexWrapper justifyContent="center">
            <GetStartButton
              onClick={() => {
                history.push(ROUTES.VACANCIES);
              }}
            >
              {Curentopenings}
            </GetStartButton>

            <Link to="openApp" smooth={true}>
              <GetStartButton style={{ marginLeft: "4vw" }} onClick={() => {}}>
                {Openapplications}
              </GetStartButton>
            </Link>
          </FlexWrapper>
          {/* <VacApplicationText>
            If you did not find any suitable vacancy - fill in{" "}
            <span
              style={{
                cursor: "pointer",
                color: "#2097F5",
                textDecoration: "underline",
              }}
              onClick={() => {
                history.push(ROUTES.APPLICATION_FORM);
              }}
            >
              Application
            </span>
            {"  "}
            from here
          </VacApplicationText> */}
        </div>
      </MobileWrapper>
      <ContentWrapper>
        <FlexWrapper justifyContent="center">
          <InfoHeader>{work}</InfoHeader>
        </FlexWrapper>
        <CardContainer paddingTop="0px">
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={Vac} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{govacancy}</CardTitle>
              <CardText>{browse}</CardText>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={Curriculam} />
            <Card.Body>
              <CardTitle
                onClick={() => {
                  history.push(ROUTES.JOB_SEEKER_FORM);
                }}
                style={{ cursor: "pointer" }}
                color={colors.darkBlue}
              >
                {application}
              </CardTitle>
              <CardText>
                {fill}
                <br /> {fill1}
                <br /> {fill2}
              </CardText>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={Like} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{next}</CardTitle>
              <CardText id="openApp">
                {review}
                <br /> {review1}
                <br /> {review2}
              </CardText>
            </Card.Body>
          </StyledCard>
        </CardContainer>
      </ContentWrapper>
      <ContentContainer id="openApp">
        <div
          data-aos="fade-right"
          style={{ padding: "5vw 0px 3vw 4vw", width: "60vw" }}
        >
          <Header color="#002C99">{openapplication}</Header>
          {openapplication === "Open applications" ? (
            <>
              <Para width="100%" color="#002C99">
                We recognize the value of professional networking, <br /> which
                is why we invite you to fill out our <br />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    history.push(ROUTES.JOB_SEEKER_FORM);
                  }}
                >
                  {" "}
                  open application form
                </span>{" "}
                so we can help you <br /> find the role that fits.
              </Para>
              <AppText width="100%" color="#002C99" bold="500">
                We will review your application and contact you <br /> within
                2-3 weeks. Meanwhile, we recommend that <br /> you continue to
                monitor our vacancies.
              </AppText>
              <AppText width="100%" color="#002C99" bold="500">
                Visit our{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    history.push(ROUTES.STATIC_FAQ);
                  }}
                >
                  FAQ
                </span>{" "}
                for more information.{" "}
              </AppText>
            </>
          ) : (
            <>
              <Para width="100%" color="#002C99">
                Vi tror på vikten av professionellt nätverkande, <br />
                och vi inbjuder dig att fylla i vår <br />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    history.push(ROUTES.JOB_SEEKER_FORM);
                  }}
                >
                  {" "}
                  spontanansökan
                </span>{" "}
                så att vi kan hitta ett jobb <br /> som passar just dig.
              </Para>
              <AppText width="100%" color="#002C99" bold="500">
                Vi kommer att granska din ansökan och kontakta <br /> dig inom
                2-3 veckor. Under tiden du väntar på återkoppling från oss,{" "}
                <br /> rekommenderar vi att du fortsätter att hålla utkik efter
                lediga jobb, <br /> eftersom fler nya jobb dyker upp hela tiden.
              </AppText>
              <AppText width="100%" color="#002C99" bold="500">
                Om du har ytterligare frågor, besök gärna vår sida med{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#2097F5",
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    history.push(ROUTES.STATIC_FAQ);
                  }}
                >
                  vanliga frågor.
                </span>{" "}
              </AppText>{" "}
            </>
          )}
        </div>
        <FlexWrapper noMargin noPadding>
          <img
            style={{ width: "40vw", height: "auto" }}
            src={vacancies}
            alt=""
          />
        </FlexWrapper>
      </ContentContainer>
      <Footers />
    </PageWrapper>
  );
};

export default JobSeeker;
