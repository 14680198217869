import { getAbsentStaff } from "./api";
import { CommonApiSlices } from "./reducer";

const {
  addApplicationUser,
  updatefaq,
  updateRegister,
  addVacanciesApplicationUser,
  updatevacancyapplicationuser,
  updateopenapplicationuser,
} = CommonApiSlices.actions;

export {
  getAbsentStaff,
  addApplicationUser,
  updatefaq,
  updateRegister,
  addVacanciesApplicationUser,
  updatevacancyapplicationuser,
  updateopenapplicationuser,
};
