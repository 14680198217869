import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement } from "react";
import { Button, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../../../const/theme";

export const CreateScheduleButton = styled(Button)`
  color: white;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#2097F5"};
  border: none;
  margin-left: 8px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  height: 35px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : null)};
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: ${({ backgroundColor }) =>
      backgroundColor ? backgroundColor : "#2097F5"};
    border: none;
    box-shadow: 0px 2px 5px gray;
  }
`;

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 94%;
  margin: 1rem auto;
`;

export const FormLable = styled(Form.Label)`
  font-family: "Montserrat", sans-serif;
  padding: 0%;
  margin-bottom: -1%;
`;

export const EditIcon = styled(FontAwesomeIcon)`
  color: black;
  :hover {
    color: ${colors.dohrBlue};
    transform: scale(1.1);
  }
`;

export const TrashIcon = styled(FontAwesomeIcon)`
  color: black;
  :hover {
    color: ${colors.dohrBlue};
    transform: scale(1.1);
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40px;
  margin: 0 auto;
`;

interface MyScheduleActionProps {
  handleEdit: () => void;
  handleDelete: () => void;
}

export const MyScheduleAction = ({
  handleEdit,
  handleDelete,
}: MyScheduleActionProps): ReactElement => {
  return (
    <ActionWrapper>
      <TrashIcon onClick={handleDelete} icon={["far", "trash-can"]} />
      <EditIcon onClick={handleEdit} icon={["fas", "pen"]} />
    </ActionWrapper>
  );
};

export const PdfImage = styled.img`
  width: 20px;
  cursor: pointer;
`;
