import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EditAbsenceApi,
  GetLeaveTypeApi,
  getMyAbsencyById,
  getMyAbsencybyTeacherId,
  RegisterAbsenceApi,
} from "./api";
import {
  GetLeaveTypes,
  getMyAbsenceByIdProps,
  getMyAbsenceResponse,
  InitialState,
  response,
} from "./types";

export const initialState: InitialState = {
  isLoading: false,
  hasResgisterError: false,
  LeaveType: [],
  registerLeave: {
    unique_id: "",
    first_name: "",
    additional_comment: "",
    last_name: "",
    leave_type_id: "",
    leave_type: "",
    from_date: "",
    to_date: "",
    from_time: "",
    to_time: "",
    Parental_leave_percentage: "",
    child_first_name: "",
    child_personal_number: "",
    appro_due_date: "",
    LeaveOfAbsence: "",
    reason: "",
    substitute_required: "",
    ins_id: "",
  },
  getMyAbsenceByTeacherid: [],
  registerLeaveResponse: {
    message: "",
  },
  getMyAbsenceById: {
    unique_id: "",
    first_name: "",
    last_name: "",
    leave_type_id: "",
    leave_type: "",
    from_date: "",
    to_date: "",
    from_time: "",
    to_time: "",
    Parental_leave_percentage: "",
    child_first_name: "",
    child_personal_number: "",
    appro_due_date: "",
    LeaveOfAbsence: "",
    reason: "",
    substitute_required: "",
    ins_id: "",
  },
  Reload: false,
  isLoadingEditModal: false,
};

export const AbsenceManagementSlice = createSlice({
  name: "absenceManagement",
  initialState,
  reducers: {
    updateHasRegisterError: (state, action: PayloadAction<boolean>) => {
      state.hasResgisterError = action.payload;
    },
    updateEditModal: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEditModal = action.payload;
    },
  },
  extraReducers: {
    [GetLeaveTypeApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetLeaveTypeApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<GetLeaveTypes>>
    ) => {
      state.isLoading = false;
      state.LeaveType = action?.payload;
    },
    [GetLeaveTypeApi.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [RegisterAbsenceApi.pending.toString()]: (state) => {
      state.isLoading = true;
      state.hasResgisterError = false;
    },
    [RegisterAbsenceApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<response>
    ) => {
      state.isLoading = false;
      state.hasResgisterError = false;
      state.registerLeaveResponse = action.payload;
    },
    [RegisterAbsenceApi.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasResgisterError = true;
    },
    [getMyAbsencybyTeacherId.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getMyAbsencybyTeacherId.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getMyAbsenceResponse>>
    ) => {
      state.isLoading = false;
      state.getMyAbsenceByTeacherid = action.payload;
    },
    [getMyAbsencybyTeacherId.rejected.toString()]: (
      state,
      action: PayloadAction<Array<getMyAbsenceResponse>>
    ) => {
      state.isLoading = false;
    },
    [getMyAbsencyById.pending.toString()]: (state) => {
      state.isLoadingEditModal = false;
    },
    [getMyAbsencyById.fulfilled.toString()]: (
      state,
      action: PayloadAction<getMyAbsenceByIdProps>
    ) => {
      state.isLoadingEditModal = true;
      state.getMyAbsenceById = action.payload;
    },
    [getMyAbsencyById.rejected.toString()]: (state) => {
      state.isLoadingEditModal = false;
    },
    [EditAbsenceApi.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [EditAbsenceApi.fulfilled.toString()]: (
      state,
      action: PayloadAction<response>
    ) => {
      state.isLoading = false;
      state.registerLeaveResponse = action.payload;
      state.Reload = true;
    },
    [EditAbsenceApi.rejected.toString()]: (state) => {
      state.isLoading = false;
      state.hasResgisterError = true;
    },
  },
});

export default AbsenceManagementSlice.reducer;
