import React from "react";
interface pdfProb {
  pdfUrl: any;
}
const PdfViewer = ({ pdfUrl }: pdfProb) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <iframe
        src={pdfUrl}
        width="100%"
        height="500px"
        title="PDF Viewer"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default PdfViewer;
