export interface getMyChatListProps {
  id: string | any;
  type: any;
}

export interface getChatListResponse {
  cons_id: any;
  consultant_fname: string;
  consultant_lname: string;
  customer_fname: string;
  customer_lname: string;
  created_at: any;
  id: any;
  ins_id: any;
  institute_name: string;
  is_active: any;
  vdate: any;
  unique_id: string;
}

export interface getChatMessageResponse {
  chat_id: any;
  con_message: string;
  created_at: any;
  cus_message: string;
  id: any;
}

export interface InitialState {
  isLoading: boolean;
  isLoadingChatSent: boolean;
  getChatDataList: Array<getChatListResponse>;
  getChatMessageData: Array<getChatMessageResponse>;
  updateChatId: any;
}

export const initialState: InitialState = {
  isLoading: false,
  getChatDataList: [],
  getChatMessageData: [],
  updateChatId: "",
  isLoadingChatSent: false,
};
