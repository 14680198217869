import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionButton,
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../components";
import { TYPE } from "../../../../../const/getType";

import {
  ApproveLeaveApi,
  DenyLeaveApi,
  GetAllLeaveApi,
} from "../../../../../redux/Hq_Management/LeaveManagement/api";
import { RootState } from "../../../../../redux/store";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import { IsPendingAction } from "./subcomponents";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";
import { Label } from "../../../../../typography";
import {
  CancleButton,
  DeleteButton,
} from "../../../../Institute/Management/ViewCustomer/subcomponents";

const HQ_PendingLeave = (): ReactElement => {
  const dispatch = useDispatch();
  const { getAllLeaveReq, userInfo, isLoading, lang, message } = useSelector(
    (state: RootState) => ({
      getAllLeaveReq: state.LeaveMaster.getAllLeaveReq,
      isLoading: state.LeaveMaster.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.pending,
      message: state.LeaveMaster.message,
    })
  );

  const [reason, setReason] = useState("");
  const [otherDetails, setOtherDetails] = useState({ id: "", email: "" });
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (userInfo?.type === TYPE.CUSTOMER) {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.ins_id,
          status: "0",
          is_denied: "0",
        })
      );
    } else {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.id,
          status: "0",
          is_denied: "0",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sectionTitle, tableHeader, tableHeaderSh } = lang;
  const [showDetail, setShowDetail] = useState(false);
  const [leave, setLeave] = useState({
    Name: "",
    LeaveType: "",
    SubstituteRequired: "",
    FromDate: "",
    ToDate: "",
    Reason: "",
    StartTime: "",
    EndTime: "",
    Comment: "",
    Createdate: "",
    Parentapercentage: "",
    ChildName: "",
    BirthDate: "",
    AdComment: "",
    DueDate: "",
  });
  const duration1 = moment.duration(leave.StartTime);
  const duration2 = moment.duration(leave.EndTime);
  const difference = duration2.subtract(duration1);
  const hours = Math.floor(difference.asHours());
  const day1 = moment(leave.FromDate);
  const day2 = moment(leave.ToDate);

  const totalDays = day2.diff(day1, "days") + 1;
  const [approve, setApprove] = useState("");
  return (
    <PageWrapper>
      <CardWraper width="90%">
        <Modal
          show={approve ? true : false}
          size={"sm"}
          centered={true}
          backdrop="static"
          keyboard={false}
        >
          <FlexWrapper justifyContent="center" width="100%">
            <FlexWrapper justifyContent="center">
              <Label>Are you sure you want to approve this leave? </Label>
            </FlexWrapper>
            <FlexWrapper noPadding>
              <CancleButton
                onClick={() => {
                  setApprove("");
                }}
              >
                No
              </CancleButton>
              <DeleteButton
                onClick={() => {
                  setApprove("");
                  dispatch(
                    ApproveLeaveApi({
                      id: approve,
                      approved_by: `${userInfo?.data?.first_name} ${userInfo?.data?.last_name}`,
                      approved_by_id: userInfo?.data.id,
                      approved_or_denied_on: moment(new Date()).format(
                        "DD-MM-YYYY"
                      ),
                    })
                  );
                }}
              >
                Yes
              </DeleteButton>
            </FlexWrapper>
          </FlexWrapper>
        </Modal>
        <Modal
          show={showDetail}
          onHide={() => {
            setShowDetail(false);
          }}
          // size="sm"
        >
          <ModalHeader closeButton style={{ textAlign: "center" }}>
            Leave Details
          </ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "start" }}>
                <b>Name: </b> {leave.Name}
                <br />
                <br />
                <b>Leave type: </b> {leave.LeaveType}
                <br />
                <br />
                <b>Substitute required: </b>
                {leave.SubstituteRequired}
                <br />
                <br />
                <b>From date: </b>
                {leave.FromDate}
                <br />
                <br />
                <b>To date: </b>
                {leave.ToDate}
                <br />
                <br />
                <b>Start time: </b>
                {leave.StartTime}
                <br />
                <br />
                <b>End time: </b>
                {leave.EndTime}
                <br />
                <br />
                <b>Duration: </b>
                {leave.FromDate == leave.ToDate
                  ? `${hours} hours`
                  : `${totalDays} Days`}
                <br />
                <br />
                <b>Submitted on: </b>
                {leave.Createdate}
                <br />
                <br />
                <b>Reason: </b>
                {leave.Reason}
                <br />
                <br />
                <b>Comment: </b>
                {leave.Comment}
                <br />
                <br />
                {leave.LeaveType === "Parental leave" ? (
                  <>
                    <b>Percentage of your parental leave: </b>
                    {leave.Parentapercentage}
                    <br />
                    <br />
                    <b>First initial of the childs name: </b>
                    {leave.ChildName}
                    <br />
                    <br />
                    <b>Year and month of the birth date: </b>
                    {leave.BirthDate}
                    <br />
                    <br />

                    <b>Child not born yet</b>

                    <br />
                    <br />
                    <b>Additional comments: </b>
                    {leave.AdComment}
                    <br />
                    <br />

                    <b>Approximate due date: </b>
                    {leave.DueDate}
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </Title>
            </FlexWrapper>
            <FlexWrapper justifyContent="center" noPadding noMargin>
              <ActionButton
                onClick={() => {
                  setShowDetail(false);
                }}
              >
                Close
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        <FlexWrapper justifyContent="center">
          {isLoading ? (
            <Loader />
          ) : (
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                {userInfo?.Role.toLowerCase() === "schoolmanagement" ? (
                  <tr style={{ textAlign: "center" }}>
                    {tableHeaderSh.map((data, index) => (
                      <th key={`Header Unique id -${index}`}>{data}</th>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    {tableHeader.map((data, index) => (
                      <th key={`Header Unique id -${index}`}>{data}</th>
                    ))}
                  </tr>
                )}
              </thead>
              <tbody>
                {getAllLeaveReq.map((item, index) => (
                  <tr key={index} style={{ textAlign: "center" }}>
                    {item?.is_approved === 0 ? (
                      <>
                        <td>{++index}</td>
                        <td>{`${item.first_name}${item.last_name}`}</td>
                        <td>{item.leave_type}</td>
                        <td>{item.substitute_required === 0 ? "No" : "Yes"}</td>
                        <td>{moment(item.from_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(item.to_date).format("DD-MM-YYYY")}</td>
                        <td>
                          {item.from_time === "00:00:00" ? "-" : item.from_time}
                        </td>
                        <td>
                          {item.to_time === "00:00:00" ? "-" : item.to_time}
                        </td>{" "}
                        {/* <td>{item?.reason ? item?.reason : "-"}</td> */}
                        {/* <td>
                          {item?.additional_comment
                            ? item?.additional_comment
                            : "-"}
                        </td> */}
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setLeave({
                              Name: `${item.first_name}${item.last_name}`,
                              LeaveType: item.leave_type,
                              Createdate: moment(item.created_at).format(
                                "YYYY-MM-DD"
                              ),
                              SubstituteRequired:
                                item.substitute_required === 0 ? "No" : "Yes",
                              FromDate: moment(item.from_date).format(
                                "YYYY-MM-DD"
                              ),
                              ToDate: moment(item.to_date).format("YYYY-MM-DD"),
                              Reason: item?.reason ? item?.reason : "-",
                              StartTime:
                                item.from_time === "00:00:00"
                                  ? "-"
                                  : item.from_time,
                              EndTime:
                                item.to_time === "00:00:00"
                                  ? "-"
                                  : item.to_time,
                              Comment: item?.additional_comment
                                ? item?.additional_comment
                                : "-",
                              Parentapercentage: item.Parental_leave_percentage,
                              ChildName: item.child_first_name,
                              BirthDate: item?.parental_birth_date
                                ? moment(item?.parental_birth_date).format(
                                    "YYYY-MM"
                                  )
                                : "N/A",
                              AdComment: item.parental_additional_comment,
                              DueDate: item?.appro_due_date
                                ? moment(item?.appro_due_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : "N/A",
                            });
                          }}
                        >
                          {" "}
                          View
                        </td>
                        {userInfo?.Role.toLowerCase() ===
                          "schoolmanagement" && (
                          <td style={{ width: "150px" }}>
                            <IsPendingAction
                              handleApprove={() => {
                                setApprove(item.id);
                                // dispatch(
                                //   ApproveLeaveApi({
                                //     id: item.id,
                                //     approved_by: `${userInfo?.data?.first_name} ${userInfo?.data?.last_name}`,
                                //     approved_by_id: userInfo.data.id,
                                //     approved_or_denied_on: moment(
                                //       new Date()
                                //     ).format("DD-MM-YYYY"),
                                //   })
                                // );
                              }}
                              handleDeny={() => {
                                setOtherDetails({
                                  id: item.id,
                                  email: item.email_id,
                                });
                                setShow(true);
                              }}
                            />
                          </td>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </FlexWrapper>
      </CardWraper>

      <Modals
        center={true}
        handleCancel={() => {
          setShow(false);
        }}
        handleSubmit={() => {
          dispatch(
            DenyLeaveApi({
              id: otherDetails.id,
              email_id: otherDetails.email,
              reason: reason,
              approved_or_denied_on: moment(new Date()).format("DD-MM-YYYY"),
              who_denied: `${userInfo?.data?.first_name} ${userInfo?.data?.last_name}`,
            })
          );
          setShow(false);
          setShowToast(true);
        }}
        show={show}
        sizeModal="lg"
        title="Reason for denying"
      >
        <FlexWrapper justifyContent="center">
          <DropdownWrapper width="90">
            <Input
              inputType="textarea"
              value={reason}
              onChange={(value: string) => {
                setReason(value);
              }}
              placeholder="Provide reason"
            />
          </DropdownWrapper>
        </FlexWrapper>
      </Modals>
      <ToastMessage
        show={showToast}
        message={
          message === "Absence Denied"
            ? "The status of this leave request has now been changed to 'Denied'."
            : message
        }
        bg={message === "Absence Denied" ? "primary" : "red"}
        onCloseHandler={() => {
          setShowToast(false);
        }}
        top="25%"
        right="40%"
      />
    </PageWrapper>
  );
};

export default HQ_PendingLeave;
