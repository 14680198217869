import { loginSlice } from "./reducer";
import { handleAuthenticate } from "./api";
const {
  updateHasError,
  updateIsLoggedIn,
  updateRegister,
  updateCustomerCre,
  updateCustomerRej,
  uodateUserInfo,
} = loginSlice.actions;

export {
  updateHasError,
  updateIsLoggedIn,
  handleAuthenticate,
  updateRegister,
  updateCustomerRej,
  updateCustomerCre,
  uodateUserInfo,
};
