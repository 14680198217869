import React, { ReactElement, useEffect, useState } from "react";
import { RootState } from "../../../../../redux/store";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PageWrapper,
  FlexWrapper,
  SectionTitle,
  ActionButton,
  Loader,
} from "../../../../../components";
import ROUTES from "../../../../../const/routes";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { tableHeader } from "./const";
import { CardWraper } from "../../unCovered/subcomponent";
import { getCreatevacancyWithoutLesson } from "../../../../../redux/Hq_Management/Vacancy/api";
import moment from "moment";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";

const UncoveredVacancyWithoutLesson = (): ReactElement => {
  const {
    Vacancy: { getWithoutLessonUncoveredList, isLoading },
    userdata,
  } = useSelector(
    (state: RootState) => ({
      Vacancy: state.Vacancy,
      userdata: state.login?.userInfo?.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCreatevacancyWithoutLesson({
        created_by: userdata?.unique_id,
      })
    );
  }, [dispatch, userdata]);
  const [detail, setDetail] = useState(false);
  const [detaildData, setDetailData] = useState({
    Position: "",
    Vacancydate: "",
    Day: "",
    Originaltime: "",
    Breaktime: "",
    preparationTime: "",
    handoverTime: "",
    Location: "",
    Vacancystatus: "",
    Cancelledbyconsultant: "",
    Cancellationcharges: "",
    totalWorkingHours: "",
    assignedto: "",
    discription: "",
  });
  return (
    <PageWrapper>
      <Modal
        show={detail}
        onHide={() => {
          setDetail(false);
        }}
        // size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Detail View
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Position:</b>
              {detaildData.Position}
              <br />
              <br />
              <b>Vacancy date:</b>
              {detaildData.Vacancydate}
              <br />
              <br />
              <b>Day:</b> {detaildData.Day}
              <br />
              <br />
              <b>Original time:</b>
              {detaildData.Originaltime}
              <br />
              <br />
              <b>Break time:</b>
              {detaildData.Breaktime}
              <br />
              <br />
              <b>preparationTime:</b> {detaildData.preparationTime}
              <br />
              <br />
              <b>handover Time:</b>
              {detaildData.handoverTime}
              <br />
              <br />
              <b>Location:</b> {detaildData.Location}
              <br />
              <br />
              <b>Vacancy status:</b> {detaildData.Vacancystatus}
              <br />
              <br />
              <b>Cancelled by consultant:</b>
              {detaildData.Cancelledbyconsultant}
              <br />
              <br />
              <b>Cancellation charges:</b> {detaildData.Cancellationcharges}{" "}
              <br />
              <br />
              <b>total Working Hours:</b>
              {detaildData.totalWorkingHours} <br />
              <br />
              <b>assigned to:</b>
              {detaildData.assignedto} <br />
              <br />
              <b>Discription:</b>
              {detaildData.discription} <br />
              <br />
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                setDetail(false);
              }}
            >
              Close
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
          width="100%"
        >
          <SectionTitle title={"Ad hoc vacancies list"} noMargin />
          <ActionButton
            onClick={() => {
              history.push(ROUTES.CREATE_VACANCY);
            }}
          >
            Create an ad hoc vacancy
          </ActionButton>
        </FlexWrapper>

        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center" noMargin>
            <Table borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader?.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getWithoutLessonUncoveredList?.map((list, index) => {
                  return (
                    <tr key={index}>
                      <td>{list?.position}</td>
                      <td>{list?.v_date}</td>
                      <td>{list?.from_time}</td>
                      <td>{list?.to_time}</td>
                      <td>{list?.location}</td>
                      <td>
                        {(list?.publish_to_external === 1 && "External") ||
                          (list?.publish_to_internal === 1 && "Internal")}
                      </td>
                      <td>
                        {moment(list?.created_at).format("YYYY-MM-DD hh:mm A")}
                      </td>
                      <td></td>
                      <td>
                        {list?.vacancy_status === 0
                          ? "Unfilled"
                          : list?.vacancy_status === 1
                          ? "Filled"
                          : list?.vacancy_status === 2
                          ? "Completed"
                          : list?.is_canceled === 1
                          ? "Canceled"
                          : "None"}
                      </td>
                      <td
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          setDetail(true);
                          setDetailData({
                            Position: list?.position,
                            Vacancydate: list?.v_date,
                            Day: list?.day,
                            Originaltime: `${list?.from_time} -${list?.to_time}`,
                            Breaktime: list?.break_time,
                            preparationTime: list?.preparationTime,
                            handoverTime: list?.handoverTime,
                            Location: list?.location,
                            Vacancystatus:
                              list?.vacancy_status === 0
                                ? "Unfilled"
                                : list?.vacancy_status === 1
                                ? "Filled"
                                : list?.vacancy_status === 2
                                ? "Completed"
                                : list?.is_canceled === 1
                                ? "Canceled"
                                : "None",
                            Cancelledbyconsultant:
                              list?.cancelled_by_consultant === 1
                                ? "Yes"
                                : "No",
                            Cancellationcharges: list?.cancellation_charges,
                            totalWorkingHours: list?.total_whrs,
                            assignedto:
                              list?.publish_to_external === 1
                                ? "External"
                                : list?.publish_to_internal === 1
                                ? "Internal"
                                : "None",
                            discription: list?.discription,
                          });
                        }}
                      >
                        View
                      </td>
                    </tr>
                  );
                })}{" "}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default UncoveredVacancyWithoutLesson;
