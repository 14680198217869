import { ReactElement, useEffect, useState } from "react";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import {
  ActionButton,
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../components";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { GetAllLeaveApi } from "../../../../../redux/Hq_Management/LeaveManagement/api";
import { TYPE } from "../../../../../const/getType";
import moment from "moment";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";
import { updateSuccess } from "../../../../../redux/Hq_Management/LeaveManagement/action";

const HQ_ApprovedLeaveRequest = (): ReactElement => {
  const dispatch = useDispatch();

  const { getAllLeaveReq, userInfo, isLoading, lang, success } = useSelector(
    (state: RootState) => ({
      getAllLeaveReq: state.LeaveMaster.getAllLeaveReq,
      isLoading: state.LeaveMaster.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.approved,
      success: state.LeaveMaster.isSuccess,
    })
  );
  const { sectionTitle, tableHeader } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.id,
          status: "1",
          is_denied: "0",
        })
      );
    } else {
      dispatch(
        GetAllLeaveApi({
          ins_id: userInfo?.data.ins_id,
          status: "1",
          is_denied: "0",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [show, setShow] = useState(false);
  const [leave, setLeave] = useState({
    Name: "",
    LeaveType: "",
    SubstituteRequired: "",
    FromDate: "",
    ToDate: "",
    Status: "",
    Submittedon: "",
    Approvedon: "",
    Approvedby: "",
    Reason: "",
    StartTime: "",
    EndTime: "",
    Duriation: "",
    Percentageofyourparentalleave: "",

    Firstinitialofthechildsname: "",

    Yearandmonthofthebirthdate: "",
    Approximateduedate: "",
    Additionalcomments: "",
    Pcomments: "",
  });

  const duration1 = moment.duration(leave.StartTime);
  const duration2 = moment.duration(leave.EndTime);
  const difference = duration2.subtract(duration1);
  const hours = Math.floor(difference.asHours());
  const day1 = moment(leave.FromDate);
  const day2 = moment(leave.ToDate);

  const totalDays = day2.diff(day1, "days") + 1;
  const [search, setSearch] = useState("");
  const filteredData = getAllLeaveReq.filter(
    (item: any) =>
      item.first_name.toLowerCase().includes(search.toLowerCase()) ||
      item.last_name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <PageWrapper>
      <CardWraper>
        <ToastMessage
          show={success}
          message="The status of this leave request has now been changed to 'Approved'."
          bg="primary"
          onCloseHandler={() => {
            dispatch(updateSuccess(false));
          }}
          right="45%"
        />
        <Modal
          show={show}
          onHide={() => {
            setShow(false);
          }}
          // size="sm"
        >
          <ModalHeader closeButton style={{ textAlign: "center" }}>
            Leave Details
          </ModalHeader>
          <Modal.Body>
            <FlexWrapper justifyContent="center" noPadding>
              <Title style={{ textAlign: "start" }}>
                <b>Name:{""} </b> {leave.Name}
                <br />
                <br />
                <b>Leave Type:{""} </b> {leave.LeaveType}
                <br />
                <br />
                <b>Substitute required:{""} </b>
                {leave.SubstituteRequired}
                <br />
                <br />
                <b>From date: {""} </b>
                {leave.FromDate}
                <br />
                <br />
                <b>To date:{""} </b>
                {leave.ToDate}
                <br />
                <br />
                <b>Status:{""} </b>
                {leave.Status}
                <br />
                <br />
                <b>Submitted on: {""} </b>
                {leave.Submittedon}
                <br />
                <br />
                <b>
                  {leave.Status === "Cancelled" ? "Denied on" : "Approved on"}:{" "}
                  {""}
                </b>
                {leave.Approvedon}
                <br />
                <br />
                <b>
                  {leave.Status === "Cancelled" ? "Deniedby" : "Approved by"}:{" "}
                  {""}
                </b>
                {leave.Approvedby}
                <br />
                <br />
                {leave.Status === "Cancelled"
                  ? `${(<b>Denied reason:{""} </b>)}${leave.Reason}`
                  : ""}
                <b>StartTime:{""} </b>
                {leave.StartTime}
                <br />
                <br />
                <b>EndTime:{""} </b>
                {leave.EndTime}
                <br />
                <br />
                <b>Duration: {""} </b>
                {leave.FromDate == leave.ToDate
                  ? `${hours} hours`
                  : `${totalDays} Days`}
                <br />
                <br />
                <b> Comments:{""} </b> {leave.Additionalcomments}
                <br />
                <br />
                <b>Reason:{""} </b> {leave.Reason}
                <br />
                <br />
                {leave.LeaveType === "Parental leave" ? (
                  <>
                    <b>Percentage of your parental leave: {""} </b>
                    {leave.Percentageofyourparentalleave}
                    <br />
                    <br />
                    <b>First initial of the childs name:{""} </b>
                    {leave.Firstinitialofthechildsname}
                    <br />
                    <br />
                    <b>Year and month of the birth date:{""} </b>{" "}
                    {leave.Yearandmonthofthebirthdate}
                    <br />
                    <br />
                    <b>Child not born yet</b>
                    <br />
                    <br />
                    <b>Additional comments:{""} </b>
                    {leave.Pcomments}
                    <br />
                    <br />
                    <b>Approximate due date:{""} </b> {leave.Approximateduedate}
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}
              </Title>
            </FlexWrapper>

            <FlexWrapper justifyContent="center" noPadding noMargin>
              <ActionButton
                onClick={() => {
                  setShow(false);
                }}
              >
                Close
              </ActionButton>
            </FlexWrapper>
          </Modal.Body>
        </Modal>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
          <DropdownWrapper width="20">
            <Input
              value={search}
              inputType="text"
              label="Search by name"
              placeholder="Search first or last name"
              onChange={(value: string) => {
                setSearch(value);
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getAllLeaveReq
                  ?.filter((listValues) => {
                    if (search.length === 0) {
                      return listValues;
                    } else if (search.length > 0) {
                      return (
                        listValues?.first_name == search ||
                        listValues?.last_name == search
                      );
                    }
                  })
                  ?.map((item, index) => {
                    const fromTime = moment(item.from_time, "hh:ss:mm");
                    const endTime = moment(item.to_time, "hh:ss:mm");
                    const duration = moment.duration(endTime.diff(fromTime));
                    const getHours = duration.hours();
                    const minutes = duration.minutes();
                    let hh = getHours <= 10 ? `${getHours}` : getHours;
                    let mm = minutes <= 10 ? `0${minutes}` : minutes;

                    const fromdateFormate = moment(item.from_date).format(
                      "M/DD/YYYY"
                    );
                    const todateFormate = moment(item.to_date).format(
                      "M/DD/YYYY"
                    );
                    var fDt = moment(fromdateFormate, "M/DD/YYYY");
                    var tDt = moment(todateFormate, "M/DD/YYYY");
                    var dayDiff = tDt.diff(fDt, "days");
                    return (
                      <tr
                        key={index}
                        style={{ textAlign: "center" }}
                        onClick={() => {
                          setLeave({
                            Name: `${item.first_name} ${item.last_name}`,
                            LeaveType: item.leave_type,
                            SubstituteRequired:
                              item.substitute_required === 0 ? "No" : "Yes",
                            FromDate: moment(item.from_date).format(
                              "YYYY-MM-DD"
                            ),
                            ToDate: moment(item.to_date).format("YYYY-MM-DD"),
                            Status:
                              item.is_denied === true
                                ? "Cancelled"
                                : item.is_approved === 0
                                ? "Pending"
                                : "Approved",
                            Submittedon: moment(item.created_at).format(
                              "YYYY-MM-DD"
                            ),
                            Approvedon: moment(
                              item.approved_or_denied_on,
                              "DD-MM-YYYY"
                            ).format("YYYY-MM-DD"),
                            Approvedby: item.approved_by,
                            Reason: item.reason,
                            StartTime: item.from_time,
                            EndTime: item.to_time,
                            Duriation: `${
                              hh === "0" && mm === "00"
                                ? `${`${dayDiff + 1} days`}`
                                : hh > "01"
                                ? `${hh}:${mm} hours `
                                : `${hh}:${mm} hour`
                            }`,
                            Percentageofyourparentalleave:
                              item.Parental_leave_percentage,

                            Firstinitialofthechildsname: item.child_first_name,

                            Yearandmonthofthebirthdate: item.parental_birth_date
                              ? moment(item.parental_birth_date).format(
                                  "YYYY/MM"
                                )
                              : "N/A",
                            Approximateduedate: item.appro_due_date
                              ? moment(item.appro_due_date).format("YYYY/MM/DD")
                              : "N/A",
                            Additionalcomments: item.additional_comment,
                            Pcomments: item.parental_additional_comment,
                          });
                        }}
                      >
                        {item?.is_approved === 1 ? (
                          <>
                            <td>{index + 1}</td>
                            <td>{`${item.first_name}${item.last_name}`}</td>
                            <td>{item.leave_type}</td>
                            <td>
                              {" "}
                              {item.substitute_required === 0
                                ? "No"
                                : "Yes"}{" "}
                            </td>
                            <td>
                              {moment(item.from_date).format("DD-MM-YYYY")}
                            </td>
                            <td>{moment(item.to_date).format("DD-MM-YYYY")}</td>
                            <td>
                              {item.is_denied === true ? (
                                <span style={{ color: "red" }}>Cancelled</span>
                              ) : item.is_approved === 0 ? (
                                <span style={{ color: "red" }}>Pending</span>
                              ) : (
                                <span style={{ color: "green" }}>Approved</span>
                              )}
                            </td>
                            <td>{`${
                              hh === "0" && mm === "00"
                                ? `${`${dayDiff + 1} days`}`
                                : hh > "01"
                                ? `${hh}:${mm} hours `
                                : `${hh}:${mm} hour`
                            }`}</td>
                            <td>
                              {moment(item.created_at).format("DD-MM-YYYY")}
                            </td>
                            <td>
                              {item.approved_or_denied_on
                                ? item.approved_or_denied_on
                                : "-"}
                            </td>
                            <td>{item.approved_by ? item.approved_by : "-"}</td>
                            <td
                              style={{ color: "blue", cursor: "pointer" }}
                              onClick={() => setShow(true)}
                            >
                              {" "}
                              View
                            </td>
                          </>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_ApprovedLeaveRequest;
