import { ReactElement, useEffect, useState } from "react";
import {
  CardWrapper,
  DropdownWrapper,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../components";
import { ProfileFlexWrapper } from "../../../components/Header/subcomponent";
import {
  ProfileImage,
  Title,
  Wrapper,
  ProfileDetails,
  UserName,
  Role,
  Label,
  InputFileUploader,
} from "./subcomponents";
import profile from "../../../asserts/profile.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { EditProfile, getProfile } from "../../../redux/login/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal } from "react-bootstrap";
import { BASEURL } from "../../../services";

const MyProfile = (): ReactElement => {
  const {
    login: { userInfo, getPrfileData, isLoading },
  } = useSelector((state: RootState) => ({
    login: state.login,
  }));

  const dispatch: AppDispatch = useDispatch();

  const [edit, setEdit] = useState<any>({});

  useEffect(() => {
    dispatch(
      getProfile({
        id: userInfo?.data.id,
        type: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageWrapper>
      <Title>My Profile</Title>
      {getPrfileData?.map(
        (
          {
            address,
            contact_number,
            email_id,
            institute_type,
            telephone_number,
            first_name,
            organization_type,
            last_name,
            title,
            profile_img,
            postal_code,
            area_name,
          },
          index
        ) => {
          return (
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <CardWrapper key={index}>
                    <ProfileFlexWrapper>
                      <ProfileImage
                        src={`${BASEURL}${profile_img}` || profile}
                        alt="prImg"
                      />
                      <Wrapper>
                        <UserName>{`${first_name} ${last_name}`} </UserName>
                        <Role>{userInfo?.Role}</Role>
                      </Wrapper>
                    </ProfileFlexWrapper>
                  </CardWrapper>
                  <CardWrapper>
                    <FlexWrapper
                      noMargin
                      noPadding
                      justifyContent="space-between"
                      hasBorder
                    >
                      <SectionTitle title="About" noMargin />
                      {userInfo?.Role !== "INSTITUTE" && (
                        <div>
                          <Icon
                            onClick={() => {
                              setEdit(getPrfileData[0]);
                            }}
                          >
                            <FontAwesomeIcon icon={["fas", "pencil"]} />
                          </Icon>
                        </div>
                      )}
                    </FlexWrapper>
                    <div style={{ paddingTop: "4%" }}>
                      {/* <ProfileDetails>Bio: </ProfileDetails> */}
                      <ProfileDetails>
                        Name of Organization:<b> {institute_type || title}</b>
                      </ProfileDetails>
                      <ProfileDetails>
                        Organization Type:<b> {organization_type || title}</b>
                      </ProfileDetails>
                      <ProfileDetails>
                        Email Address:<b> {email_id}</b>
                      </ProfileDetails>
                      <ProfileDetails>
                        Contact Phone Number:
                        <b> {contact_number || telephone_number}</b>
                      </ProfileDetails>
                      <ProfileDetails>
                        Address:
                        <b>
                          {" "}
                          {address}, {area_name}, {postal_code}
                        </b>
                      </ProfileDetails>
                      {/* <ProfileDetails>Profile:</ProfileDetails> */}
                      {/* <ProfileDetails>Picture:</ProfileDetails> */}
                      {/* <ProfileDetails>Short Description:</ProfileDetails> */}
                    </div>
                  </CardWrapper>
                </>
              )}
            </>
          );
        }
      )}
      <Modal
        show={edit?.id}
        onHide={() => {
          setEdit({});
        }}
      >
        <Modal.Header closeButton> Edit profile</Modal.Header>
        <Modal.Body>
          <DropdownWrapper width="100%">
            <Label htmlFor="edu-input">Profile picture</Label>
            <InputFileUploader
              isRequired
              type="file"
              label={"Batch Logo"}
              onChange={(event: any) => {
                const { files } = event.target;
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = () => {
                  setEdit({
                    ...edit,

                    image: reader.result,
                  });
                };
              }}
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="100%">
            <Input
              value={edit?.first_name}
              label="First name"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  first_name: value,
                });
              }}
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="100%">
            <Input
              value={edit?.last_name}
              label="Last name"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  last_name: value,
                });
              }}
            />
          </DropdownWrapper>
          {/* <DropdownWrapper width="100%">
            <Input
              value={edit?.institute_type || edit?.title}
              label="Name of Organization"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  institute_type: value,
                  title: value,
                });
              }}
            />
          </DropdownWrapper>{" "} */}
          {/* <DropdownWrapper width="100%">
            <Input
              value={edit?.organization_type || edit?.title}
              label="Organization Type"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  organization_type: value,
                  title: value,
                });
              }}
            />
          </DropdownWrapper>{" "} */}
          {/* <DropdownWrapper width="100%">
            <Input
              value={edit?.email_id}
              label="Email Address"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  email_id: value,
                });
              }}
            />
          </DropdownWrapper>{" "} */}
          <DropdownWrapper width="100%">
            <Input
              value={edit?.contact_number || edit?.telephone_number}
              label="Contact phone number"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  contact_number: value,
                  telephone_number: value,
                });
              }}
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="100%">
            <Input
              value={edit?.address}
              label="Address"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  address: value,
                });
              }}
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="100%">
            <Input
              value={edit?.area_name}
              label="Area name"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  area_name: value,
                });
              }}
            />
          </DropdownWrapper>{" "}
          <DropdownWrapper width="100%">
            <Input
              value={edit?.postal_code}
              label="Postal code"
              onChange={(value: string) => {
                setEdit({
                  ...edit,
                  postal_code: value,
                });
              }}
            />
          </DropdownWrapper>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setEdit({});
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              dispatch(
                EditProfile({
                  payload:
                    userInfo?.Role === "INSTITUTE"
                      ? "EditMyInstitute"
                      : "Edit_Profile_Customer",
                  ...edit,
                })
              ).then((res) => {
                if (res?.meta?.requestStatus === "fulfilled") {
                  alert("Your profile has been successfully updated!");
                  setEdit({});
                  dispatch(
                    getProfile({
                      id: userInfo?.data.id,
                      type: 1,
                    })
                  );
                }
              });
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </PageWrapper>
  );
};
export default MyProfile;
