import { OutlinedInput } from "@mui/material";
import { Card, Form } from "react-bootstrap";
import styled from "styled-components";
import fonts, { weight } from "../../const/fonts";

export const PageContainer = styled.div`
  display: flex;
  margin-top: 3%;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 30px;
  padding: 0 12px;
  @media (max-width: 415px) {
    width: 98%;
    margin-top: 3%;
    margin: 24px 0;
  }
`;

interface Props {
  width?: string;
}

export const CheckBoxWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "21%")};
  margin-top: 1%;
  margin-left: 2%;
`;

export const CardWrapper = styled(Card)`
  margin-top: 2vw;
`;

export const FormCheckBox = styled(Form.Check)`
  font-size: 15px;
  .form-check {
    min-width: 5rem;
  }
`;

export const TitleCard = styled.div`
  padding-left: 14px;
  margin: 7px 0 7px 0;
  color: #111111;
  font-size: ${fonts.small}px;
  font-weight: ${weight.xnormal};
  @media (max-width: 415px) {
    width: 100%;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
`;

export const StyledOutlineInput = styled(OutlinedInput)`
  font-family: "Montserrat" !important;
  font-size: 15px !important;
  color: #002c99 !important;
`;
