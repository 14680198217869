import { ReactElement, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ActionButton,
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  PageWrapper,
  ToastMessage,
} from "../../../../../components";
import {
  GetOrganization,
  GetRole,
} from "../../../../../redux/Hq_Management/Vacancy/api";
import { Data } from "../../../../../redux/Hq_Management/Vacancy/types";
import { RootState } from "../../../../../redux/store";
import { AutoCompleteWrapper } from "../subcomponent";
import moment from "moment";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { updatehasCreateVacancySuccess } from "../../../../../redux/Hq_Management/Vacancy/action";

const CreateVacancy = (): ReactElement => {
  //Dispatch And History
  const dispatch = useDispatch();
  //RootState
  const { createVacancyState, unCoveredData, hasCreateVacancySuccess } =
    useSelector(
      (state: RootState) => ({
        createVacancyState: state.Vacancy.createVacancy.Vacancy,
        hasCreateVacancySuccess: state.Vacancy.hasCreateVacancySuccess,
        unCoveredData: state.Vacancy.updateUncovered,
        userInfo: state.login.userInfo,
        setSelectedTeacherId: state.Vacancy.setSelectedTeacherId,
        selectedUpdateTeacherState: state.Vacancy.selectedUpdateTeacherExternal,
      }),
      shallowEqual
    );

  //StateS
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateValues, setupdateValues] = useState(unCoveredData as Data);
  const [createVacancy, setCreateVacancy] = useState(createVacancyState[0]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [breakeTime, setbreakeTime] = useState<any>("");
  const [isDraft, setisDraft] = useState(false);
  //times
  const startTime = moment(updateValues?.from_time, "HH:mm:ss a");
  const endTime = moment(updateValues?.to_time, "HH:mm:ss a");
  const duration = moment.duration(endTime.diff(startTime));
  const getHours = duration.hours();
  const minutes = duration.minutes();
  let hh = getHours < 10 ? `0${getHours}` : getHours;
  let mm = minutes < 10 ? `0${minutes}` : minutes;

  useEffect(() => {
    if (getHours >= 5) {
      setbreakeTime(
        moment(getHours, "hh:mm:ss").add(30, "minutes").format("hh:mm")
      );
    } else {
      setbreakeTime(`${hh}:${mm}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHours]);

  //UseEffects
  useEffect(() => {
    dispatch(GetRole());
    dispatch(GetOrganization());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <FlexWrapper>
        <DropdownWrapper width="20">
          <Input value={updateValues?.title} isDisabled label="Position" />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.from_date}
            label="From Date"
            isDisabled
            inputType="date"
            onChange={(value: string) => {
              setCreateVacancy({ ...createVacancy, v_date: value });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.to_date}
            label="To Date"
            isDisabled
            inputType="date"
            onChange={(value: string) => {
              setCreateVacancy({ ...createVacancy, v_date: value });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.start_time}
            inputType="time"
            isDisabled
            label="From Time"
            onChange={(value: string) => {
              setCreateVacancy({ ...createVacancy, f_time: value });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.to_time}
            inputType="time"
            isDisabled
            label="To Time"
            onChange={(value: string) => {
              setCreateVacancy({ ...createVacancy, f_time: value });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.institute_name}
            inputType="text"
            isDisabled
            label="Institute Name"
            placeholder="Institute Name"
            onChange={(value: string) => {
              setCreateVacancy({
                ...createVacancy,
                name_of_institution: value,
              });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.locality}
            inputType="text"
            label="Location"
            isDisabled
            placeholder="City"
            onChange={(value: string) => {
              setCreateVacancy({ ...createVacancy, location: value });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.institute_type}
            isDisabled
            label="Type of Institution"
          />
        </DropdownWrapper>
        <AutoCompleteWrapper>
          <Input value={updateValues?.address} label="Address" isDisabled />
        </AutoCompleteWrapper>
        <DropdownWrapper width="20">
          <Input
            value={updateValues?.postcode}
            placeholder="Postal Code"
            isDisabled
            label="Postal Code"
            onChange={(value: string) => {
              setCreateVacancy({
                ...createVacancy,
                postcode: value,
              });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper>
          <EditableDropdown
            placeholder="Choose consultants"
            dropdownList={[]}
            title="Consultants"
            handleSelect={(value: DropdownListProps) => {
              setCreateVacancy({
                ...createVacancy,
                institution_type: value?.name,
                institution_type_id: value?.id,
              });
            }}
          />
        </DropdownWrapper>
        <DropdownWrapper width="75">
          <Input
            value={updateValues?.subject}
            height="100px"
            label="Description"
            isDisabled
            inputType="textarea"
            onChange={(value: string) => {
              setCreateVacancy({
                ...createVacancy,
                description: value,
              });
            }}
          />
        </DropdownWrapper>
      </FlexWrapper>
      <FlexWrapper justifyContent="end">
        {/*==================================== draft=========================== */}
        <ActionButton
          onClick={() => {
            setisDraft(true);
          }}
        >
          Save as Draft
        </ActionButton>
        {/*==================================== publish=========================== */}
        <ActionButton
          onClick={() => {
            setisDraft(false);
          }}
        >
          Save and Publish
        </ActionButton>
      </FlexWrapper>
      <ToastMessage
        show={hasCreateVacancySuccess}
        onCloseHandler={() => {
          dispatch(updatehasCreateVacancySuccess(false));
        }}
        bg="success"
        message={isDraft ? "Vacancy Saved in Draft" : "Vacancy Published"}
      ></ToastMessage>
    </PageWrapper>
  );
};

export default CreateVacancy;
