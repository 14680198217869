import axios from "axios";
import ROUTES from "../const/routes";

// export const BASEURL = "http://13.60.88.61:4000/";
export const BASEURL = "https://api.dohr.io/";
// export const BASEURL = "https://virtually-rational-donkey.ngrok-free.app/";
// https://api.dohr.io/
//http://localhost:4000/
// https://virtually-rational-donkey.ngrok-free.app/
//http://13.60.88.61:4000/"
const axiosConfig = {
  baseURL: BASEURL,
  timeout: 30000,
  headers: {
    "content-type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
};

const api = axios.create(axiosConfig);

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== ROUTES.LANDING_PAGE
    ) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
