import {
  GetLeaveTypeApi,
  RegisterAbsenceApi,
  getMyAbsencybyTeacherId,
} from "./api";
import { AbsenceManagementSlice } from "./reducer";

const { updateHasRegisterError, updateEditModal } =
  AbsenceManagementSlice.actions;

export {
  GetLeaveTypeApi,
  RegisterAbsenceApi,
  getMyAbsencybyTeacherId,
  updateHasRegisterError,
  updateEditModal,
};
