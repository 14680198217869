import { ReactElement, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  FlexWrapper,
  Icon,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../components";
import { TYPE } from "../../../const/getType";
import { CommonDelete, getmyInsConsultant } from "../../../redux/Common/api";
import { RootState } from "../../../redux/store";
import { CardWraper } from "../../HqManagement/AbsenceManagement/unCovered/subcomponent";
import { TrashIcon } from "../../Teachers/AbsenceManagement/MyAbsence/subcomponents";
import { Label } from "../../../typography";
import {
  CancleButton,
  DeleteButton,
} from "../Management/ViewCustomer/subcomponents";
import apiEndpoints from "../../../const/apiEndpoints";

const MyConsultants = (): ReactElement => {
  const dispatch = useDispatch();

  const { data, myConsultants, isLoading, userInfo, lang, reload } =
    useSelector((state: RootState) => ({
      data: state.login.userInfo?.data,
      userInfo: state.login.userInfo,
      myConsultants: state.common.getmyInstituteCons,
      isLoading: state.common.isLoading,
      lang: state.Language.language.Tables.myconsultant,
      reload: state.common.reload,
    }));

  const { sectionTitle, tableHeader } = lang;

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(
        getmyInsConsultant({
          ins_id: data?.id,
        })
      );
    } else {
      dispatch(
        getmyInsConsultant({
          ins_id: data?.ins_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);
  const [deleteLeave, setDeleteleave] = useState("");

  return (
    <PageWrapper>
      <Modal
        show={deleteLeave ? true : false}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            <Label>Are you sure you want to delete? </Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton
              onClick={() => {
                setDeleteleave("");
              }}
            >
              No
            </CancleButton>
            <DeleteButton
              onClick={() => {
                setDeleteleave("");
                dispatch(
                  CommonDelete({
                    id: deleteLeave,
                    endPoint: apiEndpoints.DeleteConsultant,
                  })
                );
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin height={55}>
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper>
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {myConsultants.map((data, i) => {
                  const {
                    first_name,
                    last_name,
                    id,
                    // preferred_work_institution,
                  } = data;
                  return (
                    <tr style={{ textAlign: "center" }}>
                      <td>{i + 1}</td>
                      <td>{`${first_name}${last_name}`}</td>
                      <td>
                        {" "}
                        <Icon
                          onClick={() => {
                            setDeleteleave(id);
                          }}
                        >
                          <TrashIcon icon={["far", "trash-can"]} id="testing" />
                        </Icon>
                      </td>
                      {/* <td>{preferred_work_institution}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default MyConsultants;
