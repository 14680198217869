import { useState, useEffect } from "react";
import { DropdownButton, Dropdown, FormControl } from "react-bootstrap";
import {
  ListInput,
  DropdownInput,
  EditDropdownWrapper,
  FormControlWrapper,
} from "./subcomponents";
import { DropdownListProps, EditableDropdownProps } from "./typings";
import { Label } from "../../typography";

const MultiSelectDropdown = ({
  isRequired,
  width,
  title,
  dropdownList,
  placeholder,
  handleSelect,
  onBlur,
  error,
  isDisabled,
  defaultValue,
  isMultiChoice,
  handleMultiSelect,
  reset,
  multireset,
  isFilterRequired,
  isMultiDefaultValue,
  fontColor,
}: EditableDropdownProps) => {
  const { name, id } = defaultValue || {};
  const [selectdItem, setSelectedItem] = useState({
    name: name || "",
    id: id || "",
  });
  const [multiSelect, setMultiSelect] = useState<any>([]);
  const [filterValue, setFilterValue] = useState("");
  const multiChoiceValue = multiSelect
    ?.map((item: DropdownListProps) => item.name)
    .join(",");

  useEffect(() => {
    if (!!selectdItem?.name && !dropdownList?.length) {
      setSelectedItem({
        name: "",
        id: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownList]);

  useEffect(() => {
    const { name, id } = defaultValue || {};
    setSelectedItem({
      name: name || "",
      id: id || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (reset) {
      setSelectedItem({
        name: "",
        id: "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (isMultiDefaultValue) {
      setMultiSelect(isMultiDefaultValue);
    }
  }, [isMultiDefaultValue]);

  useEffect(() => {
    if (multireset) {
      setMultiSelect([]);
    }
  }, [multireset]);

  return (
    <EditDropdownWrapper
      width={width}
      isDisabled={isDisabled}
      id="editable-dropdown"
    >
      {title && (
        <Label color={fontColor}>
          {`${title}`}
          {isRequired && <strong>*</strong>}
        </Label>
      )}
      <ListInput dropdownWidth={!!filterValue} className="mt-1">
        <DropdownInput
          disabled
          readOnly
          placeholder={placeholder}
          aria-label={placeholder}
          onBlur={() => onBlur && onBlur()}
          value={isMultiChoice ? multiChoiceValue : selectdItem.name}
          isValid={!error && !!selectdItem.name}
          isInvalid={!!error}
        />
        <DropdownButton
          variant="outline-secondary"
          title=""
          id="dropdown-id"
          align="end"
          onSelect={(eventKey) => {
            const value = dropdownList[Number(eventKey)];
            setSelectedItem(value);
            if (isMultiChoice) {
              const isSelected = multiSelect?.some(
                (selectedItem: DropdownListProps) =>
                  value.name === selectedItem.name
              );
              if (isSelected) {
                const filteredItems = multiSelect?.filter(
                  (item: DropdownListProps) => item.name !== value.name
                );
                setMultiSelect(filteredItems);
                if (handleMultiSelect) handleMultiSelect(filteredItems);
              } else {
                const multiChoice = [...multiSelect, value];
                setMultiSelect(multiChoice);
                if (handleMultiSelect) handleMultiSelect(multiChoice);
              }
            } else {
              if (handleSelect) handleSelect(value);
            }
          }}
          autoClose={isMultiChoice ? "outside" : true}
        >
          {isFilterRequired && (
            <FormControlWrapper>
              <FormControl
                type="text"
                value={filterValue?.toLowerCase()}
                onChange={(e: any) => {
                  setFilterValue(e?.target?.value);
                }}
              />
            </FormControlWrapper>
          )}
          {dropdownList
            // eslint-disable-next-line array-callback-return
            ?.filter((data) => {
              if (filterValue === "") {
                return data;
              } else if (data?.name?.toLowerCase().includes(filterValue)) {
                return data;
              }
            })
            ?.map((item, index) => {
              const isSelected = multiSelect?.some(
                (selectedItem: DropdownListProps) =>
                  item.name === selectedItem.name
              );
              return (
                <Dropdown.Item eventKey={index} key={`dropdown-${index}`}>
                  {item?.name}
                  {isMultiChoice && isSelected && (
                    <i className="bx bx-check check"></i>
                  )}
                </Dropdown.Item>
              );
            })}
        </DropdownButton>
        <FormControl.Feedback style={{ fontSize: "11.5px" }} type="invalid">
          {error}
        </FormControl.Feedback>
      </ListInput>
    </EditDropdownWrapper>
  );
};

export default MultiSelectDropdown;
