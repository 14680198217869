import styled from "styled-components";
import { InputGroup, FormControl } from "react-bootstrap";
import { EditDropdownWrapperProps } from "./typings";
import { H3 } from "../../typography";
import { colors } from "../../const/theme";

export const ListInput = styled(InputGroup)`
  height: 43%;
  #dropdown-id {
    /* border: 0.5px solid ${colors.white}; */
    border: 0.5px solid ${colors.lightGrey};
    border-radius: 0 5px 5px 0;
    /* color: ${colors.white}; */
    color: ${colors.lightGrey};
    background: rgba(255, 255, 255, 0.09) !important;
    &:hover,
    &:focus {
      background: ${colors.active};
      box-shadow: none;
      /* color: ${colors.white}; */
    }
  }
  .dropdown-menu.show {
    max-height: 300px;
    width: ${({ dropdownWidth }) => (dropdownWidth ? "370px" : "auto")};
    overflow: auto;
    transform: translate(0, 40px) !important;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 5px;
`;

export const DropdownInput = styled(FormControl)`
  background: rgba(255, 255, 255, 0.09) !important;
  border: 0.5px solid ${colors.lightGrey};
  border-radius: 8px;
  color: #222b40;
  ::placeholder {
    background: transparent !important;
    color: ${colors.lightGrey};
    opacity: 0.6;
    font-size: 13px;
  }
  &.is-valid + #dropdown-id {
    border-color: ${colors.purple} !important;
  }

  &.is-invalid + #dropdown-id {
    border-color: ${`#dc3545`};
  }
  &:focus {
    box-shadow: none;
    border-color: ${colors.gray};
    border-right: none;
  }
`;

export const EditDropdownWrapper = styled.div<EditDropdownWrapperProps>`
  width: ${({ width }) => width || "100%"};
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  color: ${({ value }) => (value ? "#49384a" : "rgb(109, 108, 108)")};
  @media (max-width: 415px) {
    width: 100%;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
pointer-events: none;
opacity: 0.7;
`}.form-control {
  }
  .form-control.is-valid {
    border-color: ${colors.purple} !important;
    background-image: none;
  }
  .check {
    background-color: #ff6100;
    padding: 1px;
    color: white;
    font-size: 16px;
    border-radius: 50%;
    margin-left: 10px;
  }
`;

export const FormControlWrapper = styled.div`
  display: flex;
  margin: 10px;
`;
