import { Card } from "react-bootstrap";
import styled from "styled-components";
import fonts, { weight } from "../../../../const/fonts";
import { colors } from "../../../../const/theme";

export const CardWraper = styled(Card)`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 1rem auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: "50px";
`;
export const LocationFont = styled.p`
  color: ${colors.dohrBlue};
  font-size: ${fonts.small}px;
  font-weight: ${weight.bold};
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1%;
  margin-left: 1%;
`;
