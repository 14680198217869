/* eslint-disable react/jsx-no-target-blank */
import { TextField } from "@mui/material";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { Accordion, Button, Form, Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  Modals,
  ToastMessage,
} from "../../components";
import { Title } from "../../components/EditableDropdown/subcomponents";
import { DropdownListProps } from "../../components/EditableDropdown/typings";
import Input2 from "../../components/Input2";
import { TitleCard } from "../../container/Login/ChooseSignIn/register/subcomponent";
import { addApplicationUser } from "../../redux/Common/action";
import { ApplicationProps } from "../../redux/Common/types";
import { RootState } from "../../redux/store";
import EducationalSector from "./educationalSector";
import { CheckBoxWrapper } from "./subcomponent";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  DropdownWrapperfor,
  PlusButton,
} from "../../container/Login/ChooseSignIn/register/subcomponent";
import { colors } from "../../const/theme";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialValuesBoolan } from "../../container/Login/ChooseSignIn/register/const";
import PrivacyPolicy from "../policies/privacy";

const Common = ({
  errors,
  validateForms,
  isLanguageSkillsValid,
  checkBox1,
  toggleTerms,
  toggleAccurateInfo,
  toggleMarketing,
  submitted,
  from,
  todate,
  employer,
  responsiblity,
  selectedDateFrom,
  selectedDateTo,
  workHistory,
  addFormFields,
  removeFormFields,
  roleof,
  insname,
  frominsname,
  toinsname,
  eduinsname,
  addFormFields2,
  removeFormFields2,
  relevantEdu,
  selectedDateFromedu,
  selectedDateToedu,
  worIn,
  worOut,
  name_of_emp,
  key_res,
  title_of_role,
  duration,
  addFormFieldsin,
  addFormFieldsOut,
  removeFormFieldsin,
  removeFormFieldsOut,
  name_of_empout,
  durationout,
  title_of_roleout,
  key_resout,
  validateRelevanteducation,
  validateWorkHistory,
  rating,
  englishlang,
  swedishlang,
  sweSkills,
  engSkills,
}: Partial<ApplicationProps>): ReactElement => {
  const { string, values } = useSelector(
    (state: RootState) => ({
      string: state.Language.language,
      common: state.common,
      login: state.login,
      values: state.common.userApplicationProsp as ApplicationProps,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const [checkBox, setcheckBox] = useState(initialValuesBoolan);
  const [otherLang, setOtherLang] = useState({
    other: "",
    ratin: 0,
    proficiency: "",
  });

  const [fileValidat, setFileValidat] = useState(false);
  const [fileSize, setFileSize] = useState(false);
  const {
    applicationForm: {
      workSweden,
      occupitationalSector,
      langSkills,
      areEveningsweekends,
      workexperience,
      OccupitationalList,
      Employmenthistory,
      Employer,
      From,
      to,
      Relevanteducation,
      Institutionname,
      Education,
      linkedin,
      hear,
      howKnowAboutUs,
      avalibalityList,
      Occuppation,
    },
    register: {
      selectDp,
      drivingLicence,
      workStatrt,
      swedish,
      english,
      titleofRole,
      keyRes,
      Native,
      Fluent,
      Proficient,
      Intermediate,
      Beginner,
      otherLange,
      declaration_1,
      declaration_2,
      declaration_3,
      checkbox_1,
      checkbox_2,
      checkbox_3,
      other,
      Icanstart,
      date,
      YESORNO,
      permitlaw,
      permitlaw2,
      DATE,
    },
  } = string;

  const handleChange = (newValue: Dayjs | null) => {
    dispatch(addApplicationUser({ specify_date: newValue }));
  };

  const dateformatTime = (time: Date | null | string) => {
    return moment(time).format("YYYY-MM");
  };

  const [privacy, setPrivacy] = useState(false);

  return (
    <>
      <Accordion defaultActiveKey={""}>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <Title>{workexperience}</Title>
          </Accordion.Header>
          <Accordion.Body style={{ padding: "0px" }}>
            <FlexWrapper noMargin marginbtm="-2%">
              <DropdownWrapper style={{ marginBottom: "30px" }}>
                <EditableDropdown
                  placeholder={occupitationalSector}
                  dropdownList={OccupitationalList}
                  onBlur={() => {
                    validateForms("occup_sector");
                  }}
                  handleSelect={(value: DropdownListProps) => {
                    dispatch(addApplicationUser({ occup_sector: value.name }));
                    dispatch(addApplicationUser({ occup_sector_id: value.id }));
                    validateForms("occup_sector");
                  }}
                  error={errors.occup_sector}
                />
              </DropdownWrapper>
              {values?.occup_sector === OccupitationalList[4].name && (
                <DropdownWrapper width="26">
                  <Input2
                    value={values.specificField}
                    label={Occuppation}
                    onBlur={() => {
                      validateForms("specificField");
                    }}
                    error={errors.specificField}
                    width="100%"
                    onChange={(value) => {
                      dispatch(addApplicationUser({ specificField: value }));
                      validateForms("specificField");
                    }}
                  />
                </DropdownWrapper>
              )}
            </FlexWrapper>

            {values?.occup_sector === OccupitationalList[0].name && (
              <FlexWrapper noMargin width="100%">
                <EducationalSector
                  error={errors}
                  validateForms={validateForms}
                  worIn={worIn}
                  worOut={worOut}
                  name_of_emp={name_of_emp}
                  key_res={key_res}
                  title_of_role={title_of_role}
                  duration={duration}
                  addFormFieldsin={addFormFieldsin}
                  addFormFieldsOut={addFormFieldsOut}
                  removeFormFieldsin={removeFormFieldsin}
                  removeFormFieldsOut={removeFormFieldsOut}
                  name_of_empout={name_of_empout}
                  durationout={durationout}
                  title_of_roleout={title_of_roleout}
                  key_resout={key_resout}
                />
              </FlexWrapper>
            )}

            <FlexWrapper
              noMargin
              noPadding
              width="100%"
              style={{ flexDirection: "column" }}
            >
              <FlexWrapper noPadding backgroundColor={colors.darkBlue}>
                <DropdownWrapperfor>
                  <div>
                    <TitleCard
                      style={{ marginBottom: "10px" }}
                      color="white"
                      className={
                        submitted && validateWorkHistory() ? "error" : ""
                      }
                    >
                      {Employmenthistory}
                    </TitleCard>
                  </div>
                  {/* {values.employment_history?.length + 1 <= 4 ? (
                    <div>
                      <PlusButton onClick={addFormFields} color="white">
                        <FontAwesomeIcon icon={["fas", "plus"]} />
                      </PlusButton>
                    </div>
                  ) : null} */}
                </DropdownWrapperfor>
              </FlexWrapper>
              <FlexWrapper noPadding width="100%">
                <DropdownWrapper>
                  <Input2
                    value={workHistory.employer}
                    onBlur={() => {
                      validateForms("employment_history");
                    }}
                    onChange={employer}
                    label={Employer}
                    error={errors.employment_history}
                  />
                </DropdownWrapper>
                <DropdownWrapper style={{ marginTop: "-5px" }}>
                  <DatePicker
                    selected={selectedDateFrom}
                    dateFormat="yyyy/MM"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    placeholderText={DATE}
                    onChange={from}
                    customInput={
                      <Input
                        label={From}
                        value={selectedDateFrom}
                        placeholder="Select date"
                        error={errors.employment_history}
                        onBlur={() => {
                          validateForms("employment_history");
                        }}
                      />
                    }
                  />
                </DropdownWrapper>

                <DropdownWrapper style={{ marginTop: "-5px" }}>
                  <DatePicker
                    selected={selectedDateTo}
                    dateFormat="yyyy/MM"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    placeholderText={DATE}
                    onChange={todate}
                    customInput={
                      <Input
                        label={to}
                        value={selectedDateTo}
                        placeholder="Select date"
                        error={errors.employment_history}
                        onBlur={() => {
                          validateForms("employment_history");
                        }}
                      />
                    }
                  />
                </DropdownWrapper>

                <DropdownWrapper>
                  <Input2
                    value={workHistory.role}
                    onChange={roleof}
                    label={titleofRole}
                    error={errors.employment_history}
                    onBlur={() => {
                      validateForms("employment_history");
                    }}
                  />
                </DropdownWrapper>
                <FlexWrapper
                  noMargin
                  noPadding
                  style={{ marginTop: "-2%" }}
                  width="100%"
                >
                  <DropdownWrapper width="90.5">
                    <TitleCard>{keyRes}</TitleCard>
                    <Input
                      height="auto"
                      inputType="textarea"
                      error={errors.employment_history}
                      value={workHistory.responsibility}
                      onBlur={() => {
                        validateForms("employment_history");
                      }}
                      onChange={responsiblity}
                    />
                  </DropdownWrapper>
                </FlexWrapper>
                {values.employment_history?.length + 1 <= 2 ? (
                  <div style={{ marginTop: "1%", marginLeft: "2%" }}>
                    <Button onClick={addFormFields}>+</Button>
                  </div>
                ) : (
                  <div>
                    <Button disabled>+</Button>
                  </div>
                )}
              </FlexWrapper>
              {values.employment_history?.map((item, index) => (
                <FlexWrapper noPadding width="100%">
                  <DropdownWrapper width="21">
                    <Input2
                      value={item.employer}
                      onChange={() => {}}
                      label={Employer}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper width="21">
                    <Input2
                      value={dateformatTime(item.from)}
                      onChange={() => {}}
                      label={From}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper width="21">
                    <Input2
                      value={dateformatTime(item.to)}
                      onChange={() => {}}
                      label={to}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper width="21">
                    <Input2
                      value={item.role}
                      onChange={() => {}}
                      label={titleofRole}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper width="21">
                    <Input2
                      value={item.responsibility}
                      onChange={() => {}}
                      label={keyRes}
                    />
                  </DropdownWrapper>
                  <div style={{ marginLeft: "2%", marginTop: "12px" }}>
                    <Button
                      onClick={() => {
                        removeFormFields(index);
                      }}
                    >
                      -
                    </Button>
                  </div>
                </FlexWrapper>
              ))}
            </FlexWrapper>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <Title
              className={
                submitted && validateRelevanteducation() ? "error" : ""
              }
            >
              {Relevanteducation}
            </Title>
          </Accordion.Header>
          <Accordion.Body>
            <FlexWrapper
              noMargin
              width="100%"
              style={{ flexDirection: "column" }}
            >
              <FlexWrapper noPadding noMargin width="100%">
                <DropdownWrapper width="21">
                  <Input2
                    value={relevantEdu.ins_name}
                    error={errors.relevant_education}
                    onBlur={() => {
                      validateForms("relevant_education");
                    }}
                    onChange={insname}
                    label={Institutionname}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="21">
                  <Input2
                    value={relevantEdu.education}
                    error={errors.relevant_education}
                    onBlur={() => {
                      validateForms("relevant_education");
                    }}
                    onChange={eduinsname}
                    label={Education}
                  />
                </DropdownWrapper>
                <DropdownWrapper style={{ marginTop: "-5px" }}>
                  <DatePicker
                    selected={selectedDateFromedu}
                    dateFormat="yyyy/MM"
                    showMonthDropdown
                    showYearDropdown
                    placeholderText={DATE}
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    onChange={frominsname}
                    customInput={
                      <Input
                        label={From}
                        value={selectedDateFromedu}
                        placeholder="Select date"
                        error={errors.relevant_education}
                      />
                    }
                  />
                </DropdownWrapper>

                <DropdownWrapper style={{ marginTop: "-5px" }}>
                  <DatePicker
                    selected={selectedDateToedu}
                    dateFormat="yyyy/MM"
                    showMonthDropdown
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    scrollableYearDropdown
                    placeholderText={DATE}
                    onChange={toinsname}
                    customInput={
                      <Input
                        label={to}
                        value={selectedDateToedu}
                        placeholder="Select date"
                        error={errors.relevant_education}
                      />
                    }
                  />
                </DropdownWrapper>
                {values.relevant_education?.length + 1 <= 2 ? (
                  <div style={{ marginTop: "1%", marginLeft: "2%" }}>
                    <Button onClick={addFormFields2}>+</Button>
                  </div>
                ) : (
                  <div>
                    <Button disabled>+</Button>
                  </div>
                )}
              </FlexWrapper>
              {values.relevant_education?.map((item, index) => (
                <FlexWrapper noPadding width="100%">
                  <DropdownWrapper width="21">
                    <Input2
                      value={item.ins_name}
                      onChange={() => {}}
                      label={Institutionname}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper width="21">
                    <Input2
                      value={item.education}
                      onChange={() => {}}
                      label={Education}
                    />
                  </DropdownWrapper>

                  <DropdownWrapper width="21">
                    <Input2
                      value={dateformatTime(item.from)}
                      onChange={() => {}}
                      label={From}
                    />
                  </DropdownWrapper>
                  <DropdownWrapper width="21">
                    <Input2
                      value={dateformatTime(item.to)}
                      onChange={() => {}}
                      label={to}
                    />
                  </DropdownWrapper>

                  <div style={{ marginLeft: "2%", marginTop: "12px" }}>
                    <Button
                      onClick={() => {
                        removeFormFields2(index);
                      }}
                    >
                      -
                    </Button>
                  </div>
                </FlexWrapper>
              ))}
            </FlexWrapper>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <Title>{langSkills}</Title>
          </Accordion.Header>
          <Accordion.Body>
            {/* checkBoc */}
            <FlexWrapper noMargin noPadding>
              <DropdownWrapperfor
                width="60"
                className={submitted && !isLanguageSkillsValid() ? "error" : ""}
              >
                <div style={{ width: "20%" }}>
                  <TitleCard
                    className={
                      submitted && !isLanguageSkillsValid() ? "error" : ""
                    }
                  >
                    {swedish}:
                  </TitleCard>{" "}
                  <span style={{ color: colors.darkBlue, fontSize: "14px" }}>
                    {sweSkills}
                  </span>
                </div>

                <CheckBoxWrapper>
                  <Rating
                    initialRating={rating.swedish}
                    onChange={swedishlang}
                  />
                </CheckBoxWrapper>
              </DropdownWrapperfor>
            </FlexWrapper>
            <FlexWrapper noMargin noPadding>
              <DropdownWrapperfor
                width="60"
                className={submitted && !isLanguageSkillsValid() ? "error" : ""}
              >
                <div style={{ width: "20%" }}>
                  <TitleCard
                    className={
                      submitted && !isLanguageSkillsValid() ? "error" : ""
                    }
                  >
                    {english}:
                  </TitleCard>
                  <span style={{ color: colors.darkBlue, fontSize: "14px" }}>
                    {engSkills}
                  </span>
                </div>

                <CheckBoxWrapper>
                  <Rating
                    initialRating={rating.english}
                    onChange={englishlang}
                  />
                </CheckBoxWrapper>
              </DropdownWrapperfor>
            </FlexWrapper>

            {values.language_skills?.other.map((item: any, i: any) => (
              <FlexWrapper noMargin noPadding>
                <DropdownWrapperfor width="60">
                  <div style={{ width: "20%" }}>
                    <TitleCard>{item.lang}:</TitleCard>
                    <span style={{ color: colors.darkBlue, fontSize: "14px" }}>
                      {item.proficiency}
                    </span>
                  </div>

                  <CheckBoxWrapper>
                    <Rating initialRating={item.ratin} onChange={() => {}} />
                  </CheckBoxWrapper>
                  <div>
                    <Button
                      style={{ marginTop: "2px", marginLeft: "32px" }}
                      onClick={() => {
                        let newFormValues = [...values.language_skills.other];
                        newFormValues.splice(i, 1);
                        dispatch(
                          addApplicationUser({
                            language_skills: {
                              ...values.language_skills,
                              other: newFormValues,
                            },
                          })
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={["fas", "minus"]} />
                    </Button>
                  </div>
                </DropdownWrapperfor>
              </FlexWrapper>
            ))}
            {values.language_skills?.other.length <= 2 ? (
              <FlexWrapper noPadding noMargin width="100%">
                <DropdownWrapperfor width="40">
                  <div>
                    <TitleCard>{otherLange}</TitleCard>
                  </div>

                  <PlusButton
                    onClick={() => {
                      setcheckBox({
                        ...checkBox,
                        other: !checkBox?.other,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={["fas", "plus"]} />
                  </PlusButton>

                  <CheckBoxWrapper>
                    {/* <Form.Check
                      name=""
                      checked={checkBox?.other}
                      onClick={(e: any) => {
                        setcheckBox({
                          ...checkBox,
                          other: !checkBox?.other,
                        });
                        if (!checkBox?.other) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              otherLang: e.target.name,
                            },
                          });
                        } else if (checkBox?.other) {
                          setValues({
                            ...values,
                            language_skills: {
                              ...values?.language_skills,
                              otherLang: "",
                            },
                          });
                          setRating({ ...rating, other: 0 });
                        }
                      }}
                      style={{
                        marginTop: "2px",
                        color: colors.darkBlue,
                      }}
                    /> */}
                  </CheckBoxWrapper>
                </DropdownWrapperfor>
              </FlexWrapper>
            ) : null}

            <Modals
              show={checkBox?.other}
              handleCancel={() => {
                setcheckBox({
                  ...checkBox,
                  other: false,
                });
              }}
              center={true}
              handleSubmit={() => {
                dispatch(
                  addApplicationUser({
                    language_skills: {
                      ...values.language_skills,
                      other: [
                        ...values.language_skills.other,
                        {
                          proficiency: otherLang.proficiency,
                          lang: otherLang.other,
                          ratin: otherLang.ratin,
                        },
                      ],
                    },
                  })
                );

                setOtherLang({ other: "", ratin: 0, proficiency: "" });
                setcheckBox({
                  ...checkBox,
                  other: false,
                });
              }}
              sizeModal="md"
              title={otherLang.proficiency}
            >
              <FlexWrapper
                width="100%"
                justifyContent="center"
                noMargin
                noPadding
              >
                <DropdownWrapperfor width="50" style={{ marginTop: "12px" }}>
                  <Input2
                    value={otherLang?.other}
                    label={otherLange}
                    width="100%"
                    onChange={(value: string) => {
                      setOtherLang({
                        ...otherLang,
                        other: value,
                      });
                    }}
                  />
                </DropdownWrapperfor>
                <CheckBoxWrapper width="40%" style={{ marginTop: "16px" }}>
                  <Rating
                    initialRating={otherLang.ratin}
                    onChange={(rate: any) => {
                      if (rate === 1) {
                        setOtherLang({
                          ...otherLang,
                          proficiency: Beginner,
                          ratin: rate,
                        });
                      } else if (rate === 2) {
                        setOtherLang({
                          ...otherLang,
                          proficiency: Intermediate,
                          ratin: rate,
                        });
                      } else if (rate === 3) {
                        setOtherLang({
                          ...otherLang,
                          proficiency: Proficient,
                          ratin: rate,
                        });
                      } else if (rate === 4) {
                        setOtherLang({
                          ...otherLang,
                          proficiency: Fluent,
                          ratin: rate,
                        });
                      } else if (rate === 5) {
                        setOtherLang({
                          ...otherLang,
                          proficiency: Native,
                          ratin: rate,
                        });
                      }
                    }}
                  />
                </CheckBoxWrapper>
              </FlexWrapper>
            </Modals>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <Title>{other}</Title>
          </Accordion.Header>
          <Accordion.Body>
            <FlexWrapper noMargin>
              <FlexWrapper width="100%" noMargin noPadding>
                <DropdownWrapper width="30">
                  <TitleCard
                    style={{
                      marginBottom: "2%",
                      width: "100%",
                      color: colors.darkBlue,
                    }}
                  >
                    {workStatrt}
                  </TitleCard>
                  <Input2
                    value={
                      values.work_start
                        ? avalibalityList[0].name
                        : values?.work_start === false
                        ? avalibalityList[1].name
                        : ""
                    }
                    label={Icanstart}
                    error={errors.work_start}
                    dropdownList={avalibalityList}
                    onBlur={() => {
                      validateForms("work_start");
                    }}
                    onChange={(item: string) => {
                      dispatch(
                        addApplicationUser({
                          work_start:
                            item === avalibalityList[0].name ? true : false,
                        })
                      );
                      validateForms("work_start");
                    }}
                  />
                </DropdownWrapper>
                {values?.work_start === false && (
                  <DropdownWrapper marginTop="4%">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={date}
                        inputFormat={"YYYY/MM/DD"}
                        value={values?.specify_date}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            sx={{
                              input: {
                                color: colors.darkBlue,
                                fontFamily: "Montserrat",
                              },
                              label: {
                                color: colors.darkBlue,
                                fontFamily: "Montserrat",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </DropdownWrapper>
                )}
              </FlexWrapper>
              <DropdownWrapper width="30">
                <TitleCard style={{ marginBottom: "2%" }}>
                  {areEveningsweekends}
                </TitleCard>
                <Input2
                  value={
                    values?.working_evening_or_weekends
                      ? YESORNO[0].name
                      : values?.working_evening_or_weekends === false
                      ? YESORNO[1].name
                      : ""
                  }
                  label={selectDp}
                  onBlur={() => {
                    validateForms("working_evening_or_weekends");
                  }}
                  error={errors.working_evening_or_weekends}
                  dropdownList={YESORNO}
                  onChange={(value) => {
                    dispatch(
                      addApplicationUser({
                        working_evening_or_weekends:
                          value === YESORNO[0].name ? true : false,
                      })
                    );
                    validateForms("working_evening_or_weekends");
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>

            <FlexWrapper noMargin style={{ marginTop: "-2%" }}>
              <DropdownWrapper width="29" style={{ marginTop: "-6px" }}>
                <TitleCard style={{ marginBottom: "4%" }}>
                  {workSweden}
                </TitleCard>
                <Input2
                  value={
                    values?.right_to_work_in_Sweden
                      ? YESORNO[0].name
                      : values?.right_to_work_in_Sweden === false
                      ? YESORNO[1].name
                      : ""
                  }
                  label={selectDp}
                  error={errors.right_to_work_in_Sweden}
                  onBlur={() => {
                    validateForms("right_to_work_in_Sweden");
                  }}
                  width="100%"
                  dropdownList={YESORNO}
                  onChange={(value) => {
                    dispatch(
                      addApplicationUser({
                        right_to_work_in_Sweden:
                          value === YESORNO[0].name ? true : false,
                      })
                    );
                    validateForms("right_to_work_in_Sweden");
                  }}
                />
              </DropdownWrapper>

              {values?.right_to_work_in_Sweden === false && (
                <TitleCard style={{ marginLeft: "2%", marginTop: "2%" }}>
                  {permitlaw} <br /> {permitlaw2}{" "}
                  <a href="https://www.migrationsverket.se" target="_blank">
                    {" "}
                    https://www.migrationsverket.se
                  </a>
                </TitleCard>
              )}
            </FlexWrapper>

            <FlexWrapper noMargin style={{ marginTop: "-2%" }} width="100%">
              <DropdownWrapper width="30">
                <TitleCard style={{ marginBottom: "2%" }}>{linkedin}</TitleCard>
                <Input2
                  value={values.linkedIn}
                  error={errors.linkedIn}
                  onBlur={() => {
                    validateForms("linkedIn");
                  }}
                  width="100%"
                  label="http://"
                  onChange={(value) => {
                    dispatch(addApplicationUser({ linkedIn: value }));
                    validateForms("linkedIn");
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>

            <FlexWrapper noMargin style={{ marginTop: "-2%" }} width="100%">
              <DropdownWrapper width="30">
                <TitleCard style={{ marginBottom: "2%" }}>
                  {drivingLicence}
                </TitleCard>
                <Input2
                  value={
                    values?.Driving_license
                      ? YESORNO[0].name
                      : values?.Driving_license === false
                      ? YESORNO[1].name
                      : ""
                  }
                  label={selectDp}
                  onBlur={() => {
                    validateForms("Driving_license");
                  }}
                  error={errors.Driving_license}
                  width="100%"
                  dropdownList={YESORNO}
                  onChange={(value) => {
                    dispatch(
                      addApplicationUser({
                        Driving_license:
                          value === YESORNO[0].name ? true : false,
                      })
                    );
                    validateForms("Driving_license");
                  }}
                />
              </DropdownWrapper>
              <FlexWrapper
                noMargin
                noPadding
                style={{ marginTop: "-1%" }}
                width="100%"
              >
                <DropdownWrapper width="30">
                  <TitleCard style={{ marginBottom: "2%" }}>{hear}</TitleCard>
                  <Input2
                    value={values?.howDoYouKnowUs}
                    error={errors.howDoYouKnowUs}
                    label={selectDp}
                    onBlur={() => {
                      validateForms("howDoYouKnowUs");
                    }}
                    width="100%"
                    dropdownList={howKnowAboutUs}
                    onChange={(value) => {
                      dispatch(addApplicationUser({ howDoYouKnowUs: value }));
                      validateForms("howDoYouKnowUs");
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
            </FlexWrapper>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <FlexWrapper noMargin>
        <DropdownWrapper width="80" style={{ display: "flex" }}>
          <div
            style={{
              paddingLeft: "10px",
              marginBottom: "4px",
              color: colors.darkBlue,
              fontSize: "15px",
            }}
          >
            {declaration_1}{" "}
            <span
              style={{
                cursor: "pointer",
                color: "#2097F5",
                textDecoration: "underline",
              }}
              onClick={() => {
                setPrivacy(!privacy);
              }}
            >
              {declaration_2}
            </span>
            {declaration_3}
          </div>
        </DropdownWrapper>
        <DropdownWrapper
          width="100"
          style={{ display: "flex", marginLeft: "3%" }}
        >
          <Form.Check
            name="I have read, understand and agree to the above Integrity and User Policy"
            onClick={toggleTerms}
            checked={checkBox1?.terms}
          />
          <div
            style={{
              paddingLeft: "10px",
              marginBottom: "4px",
              color: colors.darkBlue,
              fontSize: "15px",
            }}
          >
            {checkbox_1}
          </div>
        </DropdownWrapper>
        <DropdownWrapper
          width="80"
          style={{ display: "flex", marginLeft: "3%" }}
        >
          <Form.Check
            name="the information I provided is complete and truthful, and that I understand that inaccurate information may result in the rejection or termination of my application"
            onClick={toggleAccurateInfo}
            checked={checkBox1?.accurate_info}
          />
          <div
            style={{
              paddingLeft: "10px",
              marginBottom: "4px",
              color: colors.darkBlue,
              fontSize: "15px",
            }}
          >
            {checkbox_2}
          </div>
        </DropdownWrapper>
        <DropdownWrapper
          width="80"
          style={{ display: "flex", marginLeft: "3%" }}
        >
          <Form.Check
            name="I agree that DoHR AB may save and process the information submitted and subsequently collected, share information about new assignments, and use information for job-related marketing purposes"
            onClick={toggleMarketing}
            checked={checkBox1?.marketing}
          />
          <div
            style={{
              paddingLeft: "10px",
              marginBottom: "4px",
              color: colors.darkBlue,
              fontSize: "15px",
            }}
          >
            {checkbox_3}
          </div>
        </DropdownWrapper>

        {/* <DropdownWrapper width="100" style={{ display: "flex" }}>
            <Form.Check
              onClick={() => {
                setcheckBox({
                  ...checkBox,
                  accept: !checkBox?.accept,
                });
              }}
              checked={checkBox?.accept}
            />
            <div style={{ paddingLeft: "10px" }}>Ja, jag accepterar </div>
          </DropdownWrapper> */}
        {fileValidat && (
          <ToastMessage
            show={!!fileValidat}
            bg="danger"
            top="4%"
            right="40%"
            onCloseHandler={() => {
              setFileValidat(false);
            }}
            message="File format should be PDF, JPEG, JPG, PNG, or GIF. "
          ></ToastMessage>
        )}
        {fileSize && (
          <ToastMessage
            show={!!fileSize}
            bg="danger"
            top="4%"
            right="40%"
            onCloseHandler={() => {
              setFileSize(false);
            }}
            message="File is too large"
          ></ToastMessage>
        )}
      </FlexWrapper>
      <Modal
        show={privacy}
        onHide={() => {
          setPrivacy(false);
        }}
        size="xl"
        style={{ marginTop: "50px" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <PrivacyPolicy />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Common;
