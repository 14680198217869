import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FlexWrapper,
  Icon,
  Loader,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { TYPE } from "../../../../../const/getType";
import ROUTES from "../../../../../const/routes";
import { updateSelectedVacancyDetails } from "../../../../../redux/Hq_Management/Vacancy/action";
import { GetMyDraft } from "../../../../../redux/Hq_Management/Vacancy/api";
import { RootState } from "../../../../../redux/store";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import { Label } from "../../../../../typography";
import {
  CancleButton,
  DeleteButton,
} from "../../../../Institute/Management/ViewCustomer/subcomponents";
import { CommonDelete } from "../../../../../redux/Common/api";
import apiEndpoints from "../../../../../const/apiEndpoints";

interface SpecialProps {
  width?: string;
}
const HQ_MyDraft = ({ width }: SpecialProps): ReactElement => {
  const { Draft, userInfo, lang, isLoading, reload } = useSelector(
    (state: RootState) => ({
      Draft: state.Vacancy.getMyDraft,
      isLoading: state.Vacancy.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.draft,
      reload: state.common.reload,
    })
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(
        GetMyDraft({ unique_id: userInfo?.data?.unique_id, is_draft: true })
      );
    } else {
      dispatch(
        GetMyDraft({ unique_id: userInfo?.data?.unique_id, is_draft: true })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const { sectionTitle, tableHeader } = lang;
  const [deleteDraft, setDeleteDraft] = useState("");
  return (
    <PageWrapper>
      <Modal
        show={deleteDraft ? true : false}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            <Label>
              Please confirm if you want to delete your draft. Select 'Yes' to
              proceed or 'No' to cancel.{" "}
            </Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton
              onClick={() => {
                setDeleteDraft("");
              }}
            >
              No
            </CancleButton>
            <DeleteButton
              onClick={() => {
                setDeleteDraft("");

                dispatch(
                  CommonDelete({
                    id: deleteDraft,
                    endPoint: apiEndpoints.DeleteVacancy,
                  })
                );
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <CardWraper width={width}>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Draft.map((data, index) => {
                  const { position, v_date, day, from_time, to_time, id } =
                    data;
                  return (
                    <tr style={{ textAlign: "center" }}>
                      <td id="testing">{++index}</td>
                      <td>{position}</td>
                      <td>{day}</td>
                      <td>{moment(v_date).format("DD-MM-YYYY")}</td>
                      <td>{from_time}</td>
                      <td>{to_time}</td>
                      <td>
                        <Icon>
                          <FontAwesomeIcon
                            icon={["fas", "pencil"]}
                            onClick={() => {
                              dispatch(updateSelectedVacancyDetails([data]));
                              history.push(
                                ROUTES.HQ_MANAGEMENT.HQ_UNCOVEREDSEPERATEVACANCY
                              );
                            }}
                          />
                        </Icon>
                        <Icon>
                          <FontAwesomeIcon
                            icon={["far", "trash-can"]}
                            id="testing"
                            onClick={() => {
                              setDeleteDraft(id);
                            }}
                          />
                        </Icon>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_MyDraft;
