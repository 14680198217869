export interface AuthenticatePayload {
  loginDevice: "mobile" | "website";
}

interface UserData {
  address: string;
  area_name: string;
  company_name: string;
  created_at: string;
  email_id: string;
  first_name: string;
  id: string;
  ins_id: string;
  profile_img: string;
  institute_domin: string;
  institute_id: string;
  institute_name: string;
  institute_type: string;
  institute_type_id: string;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  is_active: string;
  last_name: string;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  password: string;
  postal_code: string;
  telephone_number: string;
  title: string;
  title_id: string;
  unique_id: string;
  updated_at: string;
}

export interface UserInfo {
  Role: string;
  data: UserData;
  type: string;
}

export interface LoginPayload {
  email_id: string;
  password: string;
}

export interface expDetailsProps {
  name_of_emp: string;
  duration: string;
  title_of_role: string;
  key_res: string;
}

export interface OtherLanguageObj {
  ratin?: number;
  lang?: string;
  proficiency?: string;
}

export interface OtherLangArr {
  english?: string;
  swedish?: string;
  other: Array<OtherLanguageObj>;
}

export interface RegisterPaylaod {
  first_name: string;
  last_name: string;
  address: string;
  postalcode: string;
  email_id: string;
  area_name: string;
  date_of_brith?: Date | string | null;
  city: string;
  phone_number: string;
  swedish_personal_number: string;
  self_employed: boolean | null;
  iam_retired_pensioner: boolean | null;
  other_occupations: boolean | null;
  info_other_occupations?: string;
  other_occupation_field?: string;
  company_name: string;
  reg_i_have_swedish: any;
  preferred_work_institution: string[];
  reg_short_description?: string;
  reg_iam_teacher_student?: any;
  iam_student?: any;
  type_of_study?: string;
  state_hours_weeks_student: string;
  name_of_institutition?: string;
  logo: string | ArrayBuffer | null;
  proof_of_studies: string | ArrayBuffer | null;
  work_exp_in_school?: boolean | null;
  info_work_exp_in_school: Array<expDetailsProps>;
  work_exp_out_school?: boolean | null;
  info_work_exp_out_school: Array<expDetailsProps>;
  other_work_experience?: boolean | null;
  info_other_work_experience: Array<expDetailsProps>;
  as_soon_as_possible?: boolean | null;
  specify_date?: Date | string | null;
  working_hours_or_weeks?: string;
  right_to_work_in_Sweden?: boolean | null;
  working_evening_or_weekends?: boolean | null;
  language_skills: OtherLangArr;
  Iam_EU_citizen?: boolean | null;
  Iam_non_EU_citizen: boolean | null;
  police_clearance_report?: boolean | null;
  police_report?: string | ArrayBuffer | null;
  Driving_license?: boolean | null;
  How_did_you_hear_about_us?: string;
  file?: any;
  passport?: any;
  permit?: any;
  marketing_purpose: boolean | null;
  accurate_information: boolean | null;
  policy: string;
  terms_and_condition?: boolean | null;
  right_to_work_in_Sweden_attachment: string | ArrayBuffer | null;
  [key: string]: any;
  message: string;
}

export interface CommonFields {
  first_name?: string;
  last_name?: string;
  address?: string;
  postalcode?: string;
  email_id?: string;
  area_name?: string;
  date_of_brith?: Date | string | null;
  city?: string;
  swedish_bank_account: boolean | null;
  reg_i_have_swedish?: any;
  phone_number?: string;
  swedish_rights_to_work?: boolean | null;
  isEuCitizen?: boolean | null;
  police_clearance_report?: boolean | null;
  Driving_license?: boolean | null;
  file?: any;
  passport?: any;
  permit?: any;
  linkedIn?: string;
  eveningweekends?: boolean | null;
  reg_short_description?: any;
  reg_iam_teacher_student?: any;
}

export interface requirProps {
  count: string;
  created_at: string;
  id: string;
  ins_id: string;
  is_active: string;
  title: string;
  title_id: string;
  updated_at: string;
}

export interface createCustomerPaylaod {
  first_name: string;
  last_name: string;
  instructions: string;
  title: string;
  title_id?: string;
  company_name?: string;
  organization_no?: string;
  organization_type?: string;
  organization_type_id?: string;
  email_id: string;
  telephone_number: string;
  address: string;
  postal_code: string;
  area_name: string;
  invoice_address: string;
  invoice_postal_code: string;
  invoice_area_name: string;
  invoice_email_id: string;
  password: string;
  ins_id?: string;
  invoice_reference?: string;
  Location?: string;
  job_title?: string;
}

export interface getMyCustomer {
  address: string;
  area_name: string;
  company_name: string;
  created_at: string;
  is_inactive: any;
  email_id: string;
  first_name: string;
  Routine_instructions_for_the_substitutedh_customer: any;
  id: string;
  ins_id: string;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  is_active: string | number;
  last_name: string;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  password: string;
  postal_code: string;
  telephone_number: string;
  title: string;
  title_id: string;
  unique_id: string;
  updated_at: string;
}

export interface getProfileResponse {
  address: string;
  contact_number: string;
  created_at: string;
  email_id: string;
  id: string;
  institute_domin: string;
  institute_id: string;
  institute_name: string;
  institute_type: string;
  institute_type_id: string;
  is_active: string;
  locality: string;
  password: string;
  postcode: string;
  updated_at: string;
  area_name: string;
  company_name: string;
  first_name: string;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  last_name: string;
  organization_no: string;
  organization_type: string;
  profile_img: string;
  organization_type_id: string;
  postal_code: string;
  telephone_number: string;
  title: string;
}

export interface registerResponse {
  message: string;
}

export interface contactResponse {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  company_name: string;
  message: string;
  know_more?: boolean;
  cons_id?: number;
  cus_id?: number;
  from_web?: boolean;
}

interface UserState {
  isLoggedIn: boolean;
  isLoading: boolean;
  hascustomerCreated: boolean;
  hasError: boolean;
  hasRegister: boolean;
  hascustomerRejected: boolean;
  userInfo: UserInfo | null;
  registerPayload: RegisterPaylaod;
  createCustomerRes: createCustomerPaylaod;
  getCustomerdetaisl: Array<getMyCustomer>;
  getPrfileData: Array<getProfileResponse>;
  getRegisterResponse: registerResponse;
  contactResponse: contactResponse;
  emailvalidation: emailresponse;
  Commonfields: CommonFields;
}

export interface emailresponse {
  success?: boolean | null;
  message?: boolean | null;
}

export const initialState: UserState = {
  isLoggedIn: false,
  isLoading: false,
  hasError: false,
  hasRegister: false,
  hascustomerCreated: false,
  hascustomerRejected: false,
  emailvalidation: {
    success: null,
    message: null,
  },
  userInfo: null,
  registerPayload: {
    first_name: "",
    last_name: "",
    address: "",
    email_id: "",
    city: "",
    area_name: "",
    date_of_brith: null,
    logo: null,
    proof_of_studies: "",
    postalcode: "",
    working_evening_or_weekends: null,
    phone_number: "",
    marketing_purpose: null,
    accurate_information: null,
    policy: "",
    swedish_personal_number: "",
    How_did_you_hear_about_us: "",
    self_employed: null,
    right_to_work_in_Sweden: null,
    iam_retired_pensioner: null,
    other_occupations: null,
    info_other_occupations: "",
    other_occupation_field: "",
    state_hours_weeks_student: "",
    company_name: "",
    type_of_study: "",
    reg_i_have_swedish: null,
    preferred_work_institution: [],
    preferred_location_1: "",
    reg_short_description: "",
    Iam_EU_citizen: null,
    iam_student: false,
    name_of_institutition: "",
    work_exp_in_school: null,
    info_work_exp_in_school: [],
    work_exp_out_school: null,
    info_work_exp_out_school: [],
    Iam_non_EU_citizen: null,
    other_work_experience: null,
    info_other_work_experience: [],
    as_soon_as_possible: null,
    working_hours_or_weeks: "",
    specify_date: null,
    language_skills: { english: "", swedish: "", other: [] },
    police_clearance_report: null,
    police_report: null,
    Driving_license: null,
    file: "",
    terms_and_condition: null,
    right_to_work_in_Sweden_attachment: null,
    message: "",
  },
  createCustomerRes: {
    first_name: "",
    last_name: "",
    instructions: "",
    title: "",
    title_id: "",
    company_name: "",
    organization_no: "",
    organization_type: "",
    organization_type_id: "",
    email_id: "",
    telephone_number: "",
    address: "",
    postal_code: "",
    area_name: "",
    invoice_address: "",
    invoice_postal_code: "",
    invoice_area_name: "",
    invoice_email_id: "",
    password: "",
  },
  getCustomerdetaisl: [],
  getPrfileData: [],
  getRegisterResponse: {
    message: "",
  },
  contactResponse: {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    company_name: "",
    cus_id: 0,
    cons_id: 0,
    know_more: false,
    from_web: true,
    message: "",
  },
  Commonfields: {
    first_name: "",
    last_name: "",
    address: "",
    postalcode: "",
    email_id: "",
    area_name: "",
    date_of_brith: null,
    city: "",
    swedish_bank_account: null,
    reg_i_have_swedish: null,
    phone_number: "",
    swedish_rights_to_work: null,
    isEuCitizen: null,
    police_clearance_report: null,
    Driving_license: null,
    linkedIn: "",
    eveningweekends: null,
  },
};
