import api from "../../../services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import apiEndpoints from "../../../const/apiEndpoints";
import {
  ctreateSeperateVacancyProps,
  completedVacancyprops,
  GetOrganizationProps,
  Role,
  InternalVacancyPayload,
} from "./types";
import history from "../../../const/history";
import ROUTES from "../../../const/routes";
import { RootState } from "../../store";

export const CreateInternalVacancyApi = createAsyncThunk(
  "vacancy/createInVacancy",
  async (requestPayload: InternalVacancyPayload): Promise<any> => {
    const response = await api.post(
      apiEndpoints.createInternalVacancy,
      requestPayload
    );
    if (response.status === 200) {
      history.push(ROUTES.HQ_MANAGEMENT.HQ_COVERED);
    }
    return response.data;
  }
);

export const CreateExternalVacancyApi = createAsyncThunk(
  "vacancy/createExVacancy",
  async (requestPayload: any, { getState }): Promise<any> => {
    const {
      Vacancy: { selectedDraft },
    } = getState() as RootState;

    const response = await api.post(
      apiEndpoints.createExternalVacancy,
      requestPayload
    );
    if (response.status === 200) {
      if (selectedDraft) {
        history.push(ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT);
      } else {
        history.push(ROUTES.HQ_MANAGEMENT.HQ_COVERED);
      }
    }
    return response.data;
  }
);

export const CreatevacancyLesson = createAsyncThunk(
  "vacancy/createExVacancy",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post("/Create_vacancy_web", requestPayload);
    return response.data;
  }
);

export const GetRole = createAsyncThunk(
  "vacancy/getRole",
  async (): Promise<Array<Role>> => {
    const response = await api.get(apiEndpoints.getRole);
    return response.data.message;
  }
);

export const GetOrganization = createAsyncThunk(
  "vacancy/Get_Organization",
  async (): Promise<Array<GetOrganizationProps>> => {
    const response = await api.get(apiEndpoints.get_organization);
    return response.data.message;
  }
);

export const getUnCovered = createAsyncThunk(
  "get/getUncovered",
  async (requestPayload: { ins_id: any }): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getUncovered}`,
      requestPayload
    );
    return response.data.message;
  }
);

export const GetCompletedVacancyApi = createAsyncThunk(
  "vacancy/getCompletedVacancy",
  async (requestPayload: { id?: string }): Promise<completedVacancyprops> => {
    const response = await api.post(
      apiEndpoints.getCompletedVacancy,
      requestPayload
    );
    return response.data.message;
  }
);

export const GetMyDraft = createAsyncThunk(
  "vacancy/getMyDraft",
  async (requestPayload: {
    ins_id?: string;
    is_draft?: any;
    unique_id?: any;
  }): Promise<any> => {
    const response = await api.post(apiEndpoints.getDraft, requestPayload);
    return response.data.message;
  }
);

export const getCoveredVacancy = createAsyncThunk(
  "vacancy/getCoveredVacancy",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getCoverdSchedule}`,
      requestPayload
    );
    return response?.data.message;
  }
);
//seperate Vacancy
export const createSeperateVacancy = createAsyncThunk(
  "vacancy/createVacancy",
  async (requestPayload: ctreateSeperateVacancyProps): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.createSeperateVacancy}`,
      requestPayload
    );
    return response?.data;
  }
);

export const getTeacher = createAsyncThunk(
  "getTeacher",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.getTeacher}`,
      requestPayload
    );
    return response?.data.message;
  }
);

export const giveFeedBack = createAsyncThunk(
  "feedBack/giveFeedBack",
  async (requestPaylaod: any): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.giveFeedBack}`,
      requestPaylaod
    );
    return response?.data;
  }
);

export const getCreatevacancyWithoutLesson = createAsyncThunk(
  "vacancy/createWithoutVacancy",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post("/Get_createdBy_Vacancy", requestPayload);
    return response?.data?.message;
  }
);

export const approveThevacancy = createAsyncThunk(
  "vacancy/approveThevacancy",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      apiEndpoints.Approvevacanccy,
      requestPayload
    );
    return response?.data;
  }
);
