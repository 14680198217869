import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetMyCovered,
  GetICovered,
  GetMyAssignedCover,
  getassignedCoverCount,
  getTmroassignedCoverCount,
  getCustomassignedCoverCount,
  denyMyAssignedVacancy,
} from "./api";
import {
  AssignedProps,
  DenyProsp,
  getIcoverdProps,
  initialState,
  myCoveredProps,
} from "./types";

export const CoverSlice = createSlice({
  name: "coverManament",
  initialState,
  reducers: {
    updateSelecetedVacancyDetails: (
      state,
      action: PayloadAction<DenyProsp>
    ) => {
      state.updateDenyDetails = action?.payload;
    },
  },
  extraReducers: {
    [GetMyCovered.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetMyCovered.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<myCoveredProps>>
    ) => {
      state.isLoading = false;
      state.getMyCovered = action.payload;
    },
    [GetICovered.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetICovered.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<getIcoverdProps>>
    ) => {
      state.isLoading = false;
      state.getIcovered = action.payload;
    },
    [GetMyAssignedCover.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [GetMyAssignedCover.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<AssignedProps>>
    ) => {
      state.isLoading = false;
      state.getMyAssignedCover = action.payload;
    },
    [GetMyAssignedCover.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
    [getassignedCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.gettodayAssignedCoverCount = action.payload;
    },
    [getTmroassignedCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.gettommorowAssignedCoverCount = action.payload;
    },
    [getCustomassignedCoverCount.fulfilled.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.getCustomAssignedCoverCount = action.payload;
    },
    [denyMyAssignedVacancy.pending.toString()]: (state) => {
      state.isLoading = true;
      state.isVacancyDenied = false;
    },
    [denyMyAssignedVacancy.fulfilled.toString()]: (state) => {
      state.isLoading = false;
      state.isVacancyDenied = true;
    },
    [denyMyAssignedVacancy.rejected.toString()]: (state) => {
      state.isLoading = false;
    },
  },
});

export default CoverSlice.reducer;
