import { Card } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../const/theme";

export const CardWraper = styled(Card)`
  width: 100%;
  margin: 1rem auto;
`;

export const TabWrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  .nav-tabs {
    border-bottom: 1px solid ${colors.lightGrey};
  }

  .nav-item {
    button {
      color: ${colors.gray};
    }
  }
  .nav-link.active {
    color: ${colors.white};
    background-color: #2097f5;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

export const Count = styled.p`
  font-weight: 800;
  font-size: 28px;
`;

export const DashBoardWrapper = styled.div`
  margin-left: 4%;
  width: 75%;
`;
export interface CardWrapperProps {
  width?: string;
}

export const Cards = styled(Card)<CardWrapperProps>`
  box-shadow: 0 1px 10px 0 ${({ theme }) => theme.card.border};
  width: ${({ width }) => (width ? `${width}%` : "22%")};
  height: 120px;
  margin-top: 16px;
  padding: 2%;
  margin-left: 2%;
  cursor: pointer;
  &:hover {
    animation-duration: 2s;
    animation-name: filler;
    @keyframes filler {
      from {
        background-color: white;
        color: black;
      }

      to {
        background-color: #2097f5;
        color: white;
      }
    }
  }
`;
