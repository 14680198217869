import { ReactElement, useState } from "react";
import FlexWrapper from "../FlexWrapper";
import Navbar from "../NavBar/NavBar";
import {
  DropdownContentLink,
  HeaderWrapper,
  HumburgerIcon,
  Icon,
  Logo,
  LogoWrapper,
  NavbarDesktopWrapper,
  NavbarMobileWrapper,
  ProfileContainer,
  ProfileFlexWrapper,
  UserName,
  DropdownContent,
  ProfileImage,
} from "./subcomponent";
import user from "../../asserts/user.png";
import ROUTES from "../../const/routes";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateIsLoggedIn } from "../../redux/login/action";
import { RootState } from "../../redux/store";
import LangugeDropdown from "../LangDropDown";
import { updateSelectedLanguage } from "../../redux/Language/action";
import DOHR from "../../asserts/dohrLogo.png";
import { BASEURL } from "../../services";

const Header = (): ReactElement => {
  const history = useHistory();
  const { data } = useSelector(
    (state: RootState) => ({
      data: state.login.userInfo?.data,
    }),
    shallowEqual
  );

  const [extendNavbar, setExtendNavbar] = useState(false);
  const dispatch = useDispatch();

  return (
    <HeaderWrapper>
      <Icon
        onClick={() => {
          setExtendNavbar((nav) => !nav);
        }}
      >
        {" "}
        <HumburgerIcon
          icon={extendNavbar ? ["fas", "xmark"] : ["fas", "bars"]}
          style={{ fontSize: "20px" }}
        />
      </Icon>

      <LogoWrapper>
        <Logo src={DOHR} />
      </LogoWrapper>

      <NavbarDesktopWrapper>
        <Navbar />
      </NavbarDesktopWrapper>
      {extendNavbar && (
        <NavbarMobileWrapper>
          <Navbar />
        </NavbarMobileWrapper>
      )}

      <FlexWrapper
        justifyContent="flex-end"
        style={{ width: "70%", marginRight: "4%" }}
      >
        <LangugeDropdown top="0" />
        {/* <IconWrapper>
          <FontIconbell
            icon={["far", "bell"]}
            style={{ color: "#2097f5", fontSize: "20px" }}
          />
        </IconWrapper>
        <IconWrapper>
          <FontIcon
            icon={["fas", "gear"]}
            style={{ color: "#2097f5", fontSize: "20px" }}
          />
        </IconWrapper> */}

        <ProfileContainer>
          <ProfileFlexWrapper>
            <ProfileImage src={`${BASEURL}${data?.profile_img}` || user} />
            <UserName>
              {data?.institute_name || `${data?.first_name}${data?.last_name}`}
            </UserName>
          </ProfileFlexWrapper>
          <DropdownContent>
            <DropdownContentLink
              onClick={() => {
                history.push(ROUTES.MY_PROFILE);
              }}
            >
              My Profile
            </DropdownContentLink>
            <DropdownContentLink
              onClick={() => {
                localStorage.clear();
                window.location.reload();
                dispatch(updateIsLoggedIn(false));
                dispatch(updateSelectedLanguage("English"));
                history.push(ROUTES.LANDING_PAGE);
              }}
            >
              Logout
            </DropdownContentLink>
          </DropdownContent>
        </ProfileContainer>
      </FlexWrapper>
    </HeaderWrapper>
  );
};
export default Header;
