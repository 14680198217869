export const TableHeaderData = [
  "SNo",
  "Name",
  "Leave Type",
  "From Date",
  "To Date",
  "Start time",
  "End time",
  "Duration",
  "Reason",
  "Comment",
  "Status",
  "Detail view",
  "Action",
];
