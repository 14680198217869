import { createAsyncThunk } from "@reduxjs/toolkit";
import RemoveCookies from "../../components/SaveCookies/removeCookies";
import SetCookies from "../../components/SaveCookies/setCookies";
import apiEndpoints from "../../const/apiEndpoints";
import history from "../../const/history";
import ROUTES from "../../const/routes";
import api from "../../services";
import {
  LoginPayload,
  UserInfo,
  RegisterPaylaod,
  createCustomerPaylaod,
  contactResponse,
} from "./types";

const showAlertForNetworkError = (error: any) => {
  alert(`Network Error:  ${error.message}`); // You can customize the alert message
};

export const handleAuthenticate = createAsyncThunk(
  "user/login",
  async (requestPayload: LoginPayload): Promise<UserInfo> => {
    const response = await api.post(apiEndpoints.login, requestPayload);
    const userInfo = response.data.message;
    let Role = userInfo?.Role.toLowerCase();
    RemoveCookies("userDetails");
    SetCookies(
      "userDetails",
      JSON.stringify({
        mailId: response.data.message?.data?.email_id,
        password: response.data.message?.data?.password,
      })
    );
    if (Role?.toLowerCase() === "teacher") {
      if (userInfo?.data?.is_verfied === 1) {
        history.push(ROUTES.TEACHER.TEC_DASHBOARD);
      }
    } else if (userInfo?.data?.is_verfied === 0) {
      console.log("Please verify your mailid");
    } else {
      history.push(ROUTES.DASHBOARD);
    }
    return userInfo;
  }
);

export const handleRegister = createAsyncThunk(
  "user/register",
  async (requestPayload: RegisterPaylaod, { rejectWithValue }) => {
    try {
      const response = await api.post(
        apiEndpoints.createSubstitute,
        requestPayload
      );
      return response.status;
    } catch (error) {
      showAlertForNetworkError(error);
      return rejectWithValue("Network Error");
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (requestPayload: createCustomerPaylaod): Promise<any> => {
    const response = await api.post(
      apiEndpoints.createCustomer,
      requestPayload
    );
    if (response.data) {
      history.push(ROUTES.INSTITUTE.CREATED_CUSTOMER);
    }
    return response.data.message;
  }
);

export const getCreatedCustomer = createAsyncThunk(
  "getCustomer",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(
      apiEndpoints.getMyCreatedCustomer,
      requestPayload
    );
    return response.data.message;
  }
);

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(apiEndpoints.getProfile, requestPayload);
    return response.data.message;
  }
);
export const contactRegister = createAsyncThunk(
  "contact/register",
  async (requestPayload: contactResponse): Promise<any> => {
    const response = await api.post(
      `${apiEndpoints.createcontact}`,
      requestPayload
    );
    return response.data;
  }
);

export const EmailValidation = createAsyncThunk(
  "EmailValidation",
  async (requestPayload: { email_id: string }): Promise<any> => {
    const response = await api.post(
      apiEndpoints.emailvalidation,
      requestPayload
    );
    return response.data;
  }
);

export const EditProfile = createAsyncThunk(
  "EmailValidation",
  async (requestPayload: any): Promise<any> => {
    const response = await api.post(requestPayload?.payload, requestPayload);
    return response.data;
  }
);
