/* eslint-disable eqeqeq */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  FlexWrapper,
  Loader,
  PageWrapper,
  SectionTitle,
  Icon,
  Input,
  ActionButton,
} from "../../../../../components";
import { PrimaryButton } from "../../../../../components/Button";
import apiEndpoints from "../../../../../const/apiEndpoints";
import { TYPE } from "../../../../../const/getType";
import { CommonDelete } from "../../../../../redux/Common/api";
import { GetMyDraft } from "../../../../../redux/Hq_Management/Vacancy/api";
import { RootState } from "../../../../../redux/store";
import { CardWraper } from "./subcomponents";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";

interface SpecialProps {
  width?: string;
}
const HQ_PublishedVacancies = ({ width }: SpecialProps): ReactElement => {
  const dispatch = useDispatch();
  const { publishedVacancy, userInfo, lang, isLoading, reload } = useSelector(
    (state: RootState) => ({
      publishedVacancy: state.Vacancy.getMyDraft,
      isLoading: state.Vacancy.isLoading,
      userInfo: state.login.userInfo,
      lang: state.Language.language.Tables.published,
      reload: state.common.reload,
    })
  );

  useEffect(() => {
    if (userInfo?.type === TYPE.CUSTOMER) {
      dispatch(
        GetMyDraft({ unique_id: userInfo?.data?.unique_id, is_draft: false })
      );
    } else {
      dispatch(
        GetMyDraft({ unique_id: userInfo?.data?.unique_id, is_draft: false })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { sectionTitle, tableHeader } = lang;

  useEffect(() => {
    if (reload) {
      if (userInfo?.type === TYPE.CUSTOMER) {
        dispatch(
          GetMyDraft({ unique_id: userInfo?.data?.id, is_draft: false })
        );
      } else {
        dispatch(
          GetMyDraft({ unique_id: userInfo?.data?.id, is_draft: false })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const [filter, setFilter] = useState(false);
  const [date, setDate] = useState("");
  const [detail, setDetail] = useState(false);
  const [detaildData, setDetailData] = useState({
    Position: "",
    Vacancydate: "",
    Day: "",
    Originaltime: "",
    Breaktime: "",
    preparationTime: "",
    handoverTime: "",
    Location: "",
    Vacancystatus: "",
    Cancelledbyconsultant: "",
    Cancellationcharges: "",

    totalWorkingHours: "",
    assignedto: "",
    discription: "",
  });

  const sortedVacancies = (publishedVacancy || [])
    .slice()
    .sort((a: any, b: any) => {
      const dateA = new Date(a.v_date);
      const dateB = new Date(b.v_date);
      return dateA.getTime() - dateB.getTime();
    });
  return (
    <PageWrapper>
      <Modal
        show={detail}
        onHide={() => {
          setDetail(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Detail View
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Position: {""}</b>
              {detaildData.Position}
              <br />
              <br />
              <b>Vacancy date: {""}</b>
              {detaildData.Vacancydate}
              <br />
              <br />
              <b>Day: {""}</b> {detaildData.Day}
              <br />
              <br />
              <b>Original time: {""}</b>
              {detaildData.Originaltime}
              <br />
              <br />
              <b>Break time: {""}</b>
              {detaildData.Breaktime}
              <br />
              <br />
              <b>Preparation time: {""}</b> {detaildData.preparationTime}
              <br />
              <br />
              <b>handover time: {""}</b>
              {detaildData.handoverTime}
              <br />
              <br />
              <b>Location: {""}</b> {detaildData.Location}
              <br />
              <br />
              <b>Vacancy status: {""}</b> {detaildData.Vacancystatus}
              <br />
              <br />
              <b>Cancelled by consultant: {""}</b>
              {detaildData.Cancelledbyconsultant}
              <br />
              <br />
              <b>Cancellation charges: {""}</b>{" "}
              {detaildData.Cancellationcharges} <br />
              <br />
              <b>Total working hours: {""}</b>
              {detaildData.totalWorkingHours} <br />
              <br />
              <b>Assigned to: {""}</b>
              {detaildData.assignedto} <br />
              <br />
              <b>Description: {""}</b>
              {detaildData.discription} <br />
              <br />
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                setDetail(false);
              }}
            >
              Close
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper width={width}>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
          {filter && (
            <FlexWrapper noMargin noPadding justifyContent="space-between">
              <div>
                <PrimaryButton
                  onClick={() => {
                    setDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  Today
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    setDate(
                      moment(
                        new Date().setDate(new Date().getDate() + 1)
                      ).format("YYYY-MM-DD")
                    );
                  }}
                >
                  Tomorrow
                </PrimaryButton>
              </div>
              <Input
                value={date}
                inputType="date"
                onChange={(e: any) => {
                  setDate(e);
                }}
                height="auto"
                width="auto"
              />
            </FlexWrapper>
          )}
          <Icon
            onClick={() => {
              setFilter(!filter);
              setDate("");
            }}
          >
            <FontAwesomeIcon icon={["fas", "sliders"]} />
          </Icon>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id= ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedVacancies
                  // eslint-disable-next-line array-callback-return
                  ?.filter((listValues) => {
                    if (date === "") {
                      return listValues;
                    } else if (listValues?.v_date.includes(date)) {
                      return listValues;
                    }
                  })
                  .map((item, index) => {
                    return (
                      <tr key={index} style={{ textAlign: "center" }}>
                        <td>{++index}</td>
                        <td>{item.position}</td>
                        <td>{moment(item.v_date).format("MM/DD/YYYY")}</td>
                        <td>{item.from_time}</td>
                        <td>{item.to_time}</td>
                        <td>
                          {moment(item.created_at).format(
                            "DD-MM-YYYY ,HH:MM:SS"
                          )}
                        </td>
                        <td>
                          {item.publish_to_internal == "1"
                            ? "Internal"
                            : item.publish_to_external == "1"
                            ? "External"
                            : "None"}
                        </td>
                        <td>
                          {item.is_canceled == 1
                            ? "Cancelled"
                            : item.vacancy_status == "0"
                            ? "Unfilled"
                            : item.vacancy_status == "1"
                            ? "Filled"
                            : "Completed"}
                        </td>
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setDetail(true);
                            setDetailData({
                              Position: item.position,
                              Vacancydate: moment(item.v_date).format(
                                "DD-MM-YYYY"
                              ),
                              Day: item.day,
                              Originaltime: `${item.from_time}-${item.to_time}`,
                              Breaktime: item.break_time,
                              preparationTime: item.preparationTime,
                              handoverTime: item.handoverTime,
                              Location: item.location,
                              Vacancystatus:
                                item.is_canceled == 1
                                  ? "Cancelled"
                                  : item.vacancy_status == "0"
                                  ? "Unfilled"
                                  : item.vacancy_status == "1"
                                  ? "Filled"
                                  : "Completed",
                              Cancelledbyconsultant:
                                item.cancelled_by_consultant === 0
                                  ? "No"
                                  : "yes",
                              Cancellationcharges: item.cancellation_charges,
                              totalWorkingHours: item.total_whrs,
                              assignedto:
                                item.publish_to_internal == "1"
                                  ? "Internal"
                                  : item.publish_to_external == "1"
                                  ? "External"
                                  : "None",
                              discription: JSON.parse(item.discription),
                            });
                          }}
                        >
                          View
                        </td>
                        <td>
                          <Icon
                            onClick={() => {
                              dispatch(
                                CommonDelete({
                                  id: item?.id,
                                  endPoint: apiEndpoints.deletePublishedVac,
                                })
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={["far", "trash-can"]} />
                          </Icon>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_PublishedVacancies;
