import { ReactElement, useEffect, useState } from "react";
import {
  ActionButton,
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { CardWraper } from "../myCoveredLesson/subcomponents";
import {
  CreateScheduleButton,
  MyScheduleAction,
  PdfImage,
  TrashIcon,
} from "./subcomponents";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../../const/routes";
import {
  EditScheduleApi,
  GetScheduleApi,
  GetSingleSchedule,
  UpdatePdf,
} from "../../../../../redux/Teachers/MySchedule/api";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { CommonDelete } from "../../../../../redux/Common/api";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { DayDropdown } from "./AddSchedule/const";
import { getMySchedule } from "../../../../../redux/Teachers/MySchedule/types";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";
import FilePicker from "../../../../../components/FilePicker";
import Pdf from "../../../../../asserts/Nice pdf.png";
import AddSymbol from "../../../../../asserts/plus.png";
import EditPlus from "../../../../../asserts/edit.png";
import PdfViewer from "../../../../../components/PdfViewer";
const MySchedule = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();
  const {
    userInfo,
    getScheduleDate,
    mySchedule,
    isLoading,
    reload,
    afterEditReload,
  } = useSelector((state: RootState) => ({
    userInfo: state.login.userInfo,
    getScheduleDate: state.MySchedule.getSchedule,
    isLoading: state.MySchedule.isLoading,
    mySchedule: state.Language.language.Tables.teacher.mySchedule,
    reload: state.common.reload,
    afterEditReload: state.MySchedule.reload,
    getSelectedSchedule: state.MySchedule.getseletedSchedule,
  }));

  const [editModal, setEditModal] = useState(false);
  const [Value, setValue] = useState<any>();
  const [showPdf, setShowPdf] = useState(false);
  const [showPdfView, setShowPdfView] = useState(false);
  const [uplodeFile, setUplodeFile] = useState<any>(null);
  const [base64File, setBase64File] = useState<string | null>(null);
  const [UplodeValue, setUplodeValue] = useState<any>();
  const [pdfUrl, setpdfUrl] = useState<any>("");
  useEffect(() => {
    dispatch(
      GetScheduleApi({
        unique_id: userInfo?.data.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload || afterEditReload]);

  const history = useHistory();

  const { sectionTitle, tableHeader } = mySchedule;

  const weekDays: any = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 7,
  };

  const orderedScheduleList = [...getScheduleDate].sort((a: any, b: any) => {
    return weekDays[a.title] - weekDays[b.title];
  });

  function compare(a: any, b: any) {
    if (
      a.end_time < b.end_time ||
      (a.end_time === b.end_time && a.start_time > b.start_time)
    )
      return -1;
    if (
      a.end_time > b.end_time ||
      (a.end_time == b.end_time && a.start_time < b.start_time)
    )
      return 1;
    return 0;
  }

  let sortedArray: Array<getMySchedule> = [];

  orderedScheduleList?.forEach((dd) => {
    sortedArray?.push({
      title: dd?.title,
      DayData: [...dd?.DayData].sort(compare),
    });
  });

  const convertFileToBase64 = async () => {
    if (!uplodeFile) {
      console.error("No file found to convert.");
      return;
    }

    try {
      const base64 = await new Promise<string | ArrayBuffer | null>(
        (resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result); // On successful read
          reader.onerror = (error) => reject(error); // On error
          reader.readAsDataURL(uplodeFile); // Read file as Base64
        }
      );

      setBase64File(base64 as string); // Store Base64 string in state
      console.log("Base64:", base64);
    } catch (error) {
      console.error("Error converting file to Base64:", error);
    }
  };

  // Automatically convert the file when `uplodeFile` changes
  useEffect(() => {
    if (uplodeFile) {
      convertFileToBase64();
    }
  }, [uplodeFile]);
  return (
    <PageWrapper>
      <Modal
        show={showPdf}
        onHide={() => {
          setShowPdf(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Add Instruction
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper
            noPadding
            justifyContent="center"
            style={{ border: "1px dotted #192cbd" }}
          >
            <Title style={{ textAlign: "center" }}>
              <FilePicker
                setImageFile={setUplodeFile}
                fileName={uplodeFile ? uplodeFile?.name : ""}
              />
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                dispatch(UpdatePdf({ id: UplodeValue, pdf: base64File }))
                  .then((res) => {
                    if (res.payload.message === "Schedule Created") {
                      dispatch(
                        GetScheduleApi({
                          unique_id: userInfo?.data.id,
                        })
                      );
                      alert("uploaded");
                    } else {
                      alert("Failed to upload");
                    }
                    setShowPdf(false);
                    setUplodeFile(null);
                  })
                  .catch((err) => {
                    alert("Failed to upload");
                    console.log(err);
                  });
              }}
            >
              Upload
            </ActionButton>
            <ActionButton
              onClick={() => {
                setShowPdf(false);
              }}
            >
              Cancel
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={showPdfView}
        onHide={() => {
          setShowPdfView(false);
        }}
        size="xl"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Pdf
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper
            noPadding
            justifyContent="center"
            style={{ border: "1px dotted #192cbd" }}
          >
            <PdfViewer pdfUrl={`http://13.60.88.61:4000/${pdfUrl}`} />
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle noMargin title={sectionTitle} />
          <CreateScheduleButton
            onClick={() => {
              history.push(ROUTES.TEACHER.TEC_ADD_SCHEDULE);
            }}
          >
            Create Schedule
          </CreateScheduleButton>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {sortedArray?.map((data, index) => {
                  const { title, DayData } = data;

                  return (
                    <tr
                      style={{ borderBottom: "1px solid lightgray" }}
                      key={index}
                    >
                      <td
                        style={{
                          background: "#eff3fa",
                          fontSize: "15px",
                          textAlign: "center",
                          verticalAlign: "middle",
                          fontWeight: "500",
                        }}
                      >
                        {title}
                      </td>
                      <td>
                        {DayData?.map((data) => (
                          <td style={{ display: "block" }}>
                            {data.start_time}
                          </td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>{data.end_time}</td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>{data.subject}</td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>
                            {data.year_group}
                          </td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>{data.classroom}</td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>
                            {data?.schedule_pdf != null ? (
                              <>
                                <PdfImage
                                  src={Pdf}
                                  onClick={() => {
                                    setShowPdfView(true);
                                    setpdfUrl(data?.schedule_pdf);
                                  }}
                                />
                                <PdfImage
                                  style={{ width: "25px" }}
                                  src={EditPlus}
                                  onClick={() => {
                                    setUplodeValue(data?.id);
                                    setShowPdf(true);
                                  }}
                                />
                              </>
                            ) : (
                              <PdfImage
                                style={{ width: "15px" }}
                                src={AddSymbol}
                                onClick={() => {
                                  setUplodeValue(data?.id);
                                  setShowPdf(true);
                                }}
                              />
                            )}
                            {/* <CreateScheduleButton
                              onClick={() => {
                                setUplodeValue(data?.id);
                                setShowPdf(true);
                              }}
                            >
                              Add
                            </CreateScheduleButton> */}
                          </td>
                        ))}
                      </td>
                      <td>
                        {DayData.map((data) => (
                          <td style={{ display: "block" }}>
                            <MyScheduleAction
                              handleDelete={() => {
                                dispatch(
                                  CommonDelete({
                                    id: data.id,
                                    endPoint: "delete_my_schedule ",
                                  })
                                );
                              }}
                              handleEdit={() => {
                                dispatch(GetSingleSchedule({ id: data.id }));
                                setEditModal(true);
                                setValue(data);
                              }}
                            />
                          </td>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Modals
              center={true}
              show={editModal}
              handleCancel={() => {
                setEditModal(false);
              }}
              handleSubmit={() => {
                dispatch(EditScheduleApi(Value)).then((res) => {
                  if (res?.meta?.requestStatus === "fulfilled") {
                    dispatch(
                      GetScheduleApi({
                        unique_id: userInfo?.data.id,
                      })
                    );
                  }
                });
                setEditModal(false);
                history.push(ROUTES.TEACHER.TEC_MY_SCHEDULE);
              }}
              sizeModal="lg"
              title="Edit Schedule"
            >
              <FlexWrapper justifyContent="space-around">
                <DropdownWrapper width="25">
                  <EditableDropdown
                    dropdownList={DayDropdown}
                    defaultValue={{
                      name: Value?.day,
                      id: Value?.id,
                    }}
                    title="Day"
                    isRequired
                    placeholder="Select Day"
                    handleSelect={(value: DropdownListProps) => {
                      setValue({ ...Value, day: value.name });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.start_time}
                    label="Start time"
                    inputType={"time"}
                    onChange={(value: string) => {
                      setValue({ ...Value, start_time: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.end_time}
                    label="End time"
                    inputType={"time"}
                    onChange={(value: string) => {
                      setValue({ ...Value, end_time: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.subject}
                    label="Subject/Reference"
                    placeholder="Subject/Reference"
                    inputType={"text"}
                    onChange={(value: string) => {
                      setValue({ ...Value, subject: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.year_group}
                    label="Year group/Name"
                    placeholder={"Year group/Name"}
                    inputType={"text"}
                    onChange={(value: string) => {
                      setValue({ ...Value, year_group: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <Input
                    value={Value?.classroom}
                    label="Location/Room/Dept."
                    placeholder={"Location/Room/Dept."}
                    inputType={"text"}
                    onChange={(value: string) => {
                      setValue({ ...Value, classroom: value });
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>
            </Modals>
          </>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default MySchedule;
