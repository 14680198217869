import { FormControl } from "react-bootstrap";
import styled from "styled-components";

export const Title = styled.p`
  color: #2097f5;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-left: 28%;
  margin-bottom: 1%;
`;
export const UserName = styled.p`
  color: black;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
`;

export const Role = styled.p`
  color: black;
  font-size: 14px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
`;

export const ProfileImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-top: 4.5%;
  margin-left: 2%;
`;
export const Wrapper = styled.div`
  font-weight: 500;
  width: 45%;
  justify-content: space-evenly;
  margin-right: 50%;
  padding: 4%;
`;

export const ProfileDetails = styled.p`
  font-family: "Montserrat", sans-serif;
`;

export const Label = styled.label`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.15rem;
`;

export const InputFileUploader = styled(FormControl)`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.09) !important;
  color: #fff;
  opacity: 0.6;
  font-size: 13px;
`;
