import { ReactElement } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DropdownWrapper, FlexWrapper, Input } from "../../../../components";
import { Title } from "../../../../components/EditableDropdown/subcomponents";
import Input2 from "../../../../components/Input2";
import { addVacanciesApplicationUser } from "../../../../redux/Common/action";
import { VacanciesApplicationProps } from "../../../../redux/Common/types";
import { RootState } from "../../../../redux/store";
import { Wrapper } from "../subcomponent";
import { Button } from "react-bootstrap";
import { DropdownWrapperfor } from "../../../../container/Login/ChooseSignIn/register/subcomponent";

const WorkExperience = ({
  error,
  validateforms,
  worIn,
  worOut,
  name_of_emp,
  key_res,
  title_of_role,
  duration,
  addFormFieldsin,
  addFormFieldsOut,
  removeFormFieldsin,
  removeFormFieldsOut,
  name_of_empout,
  durationout,
  title_of_roleout,
  key_resout,
}: Partial<VacanciesApplicationProps>): ReactElement => {
  const { string, values } = useSelector(
    (state: RootState) => ({
      string: state.Language.language,
      common: state.common,
      values: state.common
        .VacanciesuserApplicationProsp as VacanciesApplicationProps,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const {
    register: {
      workExperienceinSchool,
      workExperienceoutSchool,
      nameOfEmploye,
      keyRes,
      titleofRole,
      durations,
      selectDp,
      YESORNO,
    },
  } = string;

  return (
    <>
      <Wrapper style={{ marginTop: "0px" }}>
        <FlexWrapper noPadding>
          <Title style={{ marginLeft: "2%", marginBottom: "0px" }}>
            {workExperienceinSchool}
          </Title>
          <DropdownWrapperfor width="100">
            <Input2
              value={
                values.work_exp_in_school
                  ? YESORNO[0].name
                  : values?.work_exp_in_school === false
                  ? YESORNO[1].name
                  : ""
              }
              label={selectDp}
              width="25%"
              error={error.work_exp_in_school}
              onBlur={() => {
                validateforms("work_exp_in_school");
              }}
              dropdownList={YESORNO}
              onChange={(value: string) => {
                dispatch(
                  addVacanciesApplicationUser({
                    work_exp_in_school:
                      value === YESORNO[0].name ? true : false,
                  })
                );
              }}
            />

            {values?.work_exp_in_school === true ? (
              values.info_work_exp_in_school.length + 1 <= 2 ? (
                <div
                  style={{
                    marginLeft: "2%",
                  }}
                >
                  <Button onClick={addFormFieldsin}>+</Button>
                </div>
              ) : (
                <div style={{ marginLeft: "2%" }}>
                  <Button disabled>+</Button>
                </div>
              )
            ) : null}
          </DropdownWrapperfor>

          {values.work_exp_in_school === true && (
            <>
              <DropdownWrapper>
                <Input2
                  value={worIn.name_of_emp}
                  label={nameOfEmploye}
                  error={error.info_work_exp_in_school}
                  onBlur={() => {
                    validateforms("info_work_exp_in_school");
                  }}
                  onChange={name_of_emp}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={worIn.duration}
                  label={durations}
                  error={error.info_work_exp_in_school}
                  onBlur={() => {
                    validateforms("info_work_exp_in_school");
                  }}
                  onChange={duration}
                />
              </DropdownWrapper>
              <DropdownWrapper>
                <Input2
                  value={worIn.title_of_role}
                  error={error.info_work_exp_in_school}
                  onBlur={() => {
                    validateforms("info_work_exp_in_school");
                  }}
                  onChange={title_of_role}
                  label={titleofRole}
                />
              </DropdownWrapper>
              <FlexWrapper
                noMargin
                noPadding
                style={{ marginTop: "-2%" }}
                width="100%"
              >
                <DropdownWrapper>
                  <Input
                    height="auto"
                    inputType="textarea"
                    error={error.info_work_exp_in_school}
                    onBlur={() => {
                      validateforms("info_work_exp_in_school");
                    }}
                    value={worIn.key_res}
                    onChange={key_res}
                    label={keyRes}
                  />
                </DropdownWrapper>
              </FlexWrapper>
            </>
          )}
          {values.info_work_exp_in_school.map((item, index) => (
            <>
              <DropdownWrapper width="21">
                <Input2
                  onChange={() => {}}
                  value={item.name_of_emp}
                  label={nameOfEmploye}
                />
              </DropdownWrapper>

              <DropdownWrapper width="21">
                <Input2
                  value={item.duration}
                  label={durations}
                  onChange={() => {}}
                />
              </DropdownWrapper>
              <DropdownWrapper width="21">
                <Input2
                  value={item.title_of_role}
                  onChange={() => {}}
                  label={titleofRole}
                />
              </DropdownWrapper>
              <DropdownWrapper width="21">
                <Input2
                  value={item.key_res}
                  onChange={() => {}}
                  label={keyRes}
                />
              </DropdownWrapper>
              <div style={{ marginLeft: "2%", marginTop: "12px" }}>
                <Button
                  onClick={() => {
                    removeFormFieldsin(index);
                  }}
                >
                  -
                </Button>
              </div>
            </>
          ))}
        </FlexWrapper>
        {values?.work_exp_in_school === false && (
          <FlexWrapper noPadding>
            <Title style={{ marginLeft: "2%", marginBottom: "0px" }}>
              {workExperienceoutSchool}
            </Title>
            <DropdownWrapperfor width="100">
              <Input2
                value={
                  values.work_exp_out_school
                    ? YESORNO[0].name
                    : values?.work_exp_out_school === false
                    ? YESORNO[1].name
                    : ""
                }
                label={selectDp}
                width="25%"
                error={error.work_exp_out_school}
                onBlur={() => {
                  validateforms("work_exp_out_school");
                }}
                dropdownList={YESORNO}
                onChange={(value) => {
                  dispatch(
                    addVacanciesApplicationUser({
                      work_exp_out_school:
                        value === YESORNO[0].name ? true : false,
                    })
                  );
                }}
              />

              {values?.work_exp_out_school === true ? (
                values.info_work_exp_out_school.length + 1 <= 2 ? (
                  <div style={{ marginLeft: "2%" }}>
                    <Button onClick={addFormFieldsOut}>+</Button>
                  </div>
                ) : (
                  <div style={{ marginLeft: "2%" }}>
                    <Button disabled>+</Button>
                  </div>
                )
              ) : null}
            </DropdownWrapperfor>

            {values?.work_exp_out_school === true && (
              <>
                <DropdownWrapper>
                  <Input2
                    value={worOut.name_of_emp}
                    label={nameOfEmploye}
                    error={error.info_work_exp_out_school}
                    onBlur={() => {
                      validateforms("info_work_exp_out_school");
                    }}
                    onChange={name_of_empout}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={worOut.duration}
                    label={durations}
                    error={error.info_work_exp_out_school}
                    onBlur={() => {
                      validateforms("info_work_exp_out_school");
                    }}
                    onChange={durationout}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input2
                    value={worOut.title_of_role}
                    onChange={title_of_roleout}
                    error={error.info_work_exp_out_school}
                    onBlur={() => {
                      validateforms("info_work_exp_out_school");
                    }}
                    label={titleofRole}
                  />
                </DropdownWrapper>
                <FlexWrapper
                  noMargin
                  noPadding
                  style={{ marginTop: "-2%" }}
                  width="100%"
                >
                  <DropdownWrapper>
                    <Input
                      height="auto"
                      inputType="textarea"
                      value={worOut.key_res}
                      error={error.info_work_exp_out_school}
                      onBlur={() => {
                        validateforms("info_work_exp_out_school");
                      }}
                      onChange={key_resout}
                      label={keyRes}
                    />
                  </DropdownWrapper>
                </FlexWrapper>
              </>
            )}
          </FlexWrapper>
        )}
        {values.info_work_exp_out_school.map((item, index) => (
          <FlexWrapper noPadding>
            <DropdownWrapper width="21">
              <Input2
                onChange={() => {}}
                value={item.name_of_emp}
                label={nameOfEmploye}
              />
            </DropdownWrapper>

            <DropdownWrapper width="21">
              <Input2
                value={item.duration}
                label={durations}
                onChange={() => {}}
              />
            </DropdownWrapper>
            <DropdownWrapper width="21">
              <Input2
                value={item.title_of_role}
                onChange={() => {}}
                label={titleofRole}
              />
            </DropdownWrapper>
            <DropdownWrapper width="21">
              <Input2 value={item.key_res} onChange={() => {}} label={keyRes} />
            </DropdownWrapper>
            <div style={{ marginLeft: "2%", marginTop: "12px" }}>
              <Button
                onClick={() => {
                  removeFormFieldsOut(index);
                }}
              >
                -
              </Button>
            </div>
          </FlexWrapper>
        ))}
      </Wrapper>
    </>
  );
};

export default WorkExperience;
