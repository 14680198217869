import { DropdownListProps } from "../../components/EditableDropdown/typings";
import { OtherLangArr } from "../login/types";
import {
  ageGrpList,
  avalibalityList,
  OccupitationalList,
  socialOccupationState,
  subjectList,
  teaching,
} from "./const";

export type OnChangeHandler = {
  [key: string]: any;
};

export interface AbsentStaff {
  first_name: string;
  last_name: string;
  leave_type: any;
  company_name: string;
  from_date: string;
  substitute_required: any;
  to_date: string;
  from_time: string;
  to_time: string;
}

export interface getMyconsultant {
  Driving_license: string;
  as_soon_as_possible: string;
  assigned_to: string;
  created_at: string;
  description: string;
  email_id: string;
  first_name: string;
  iam_student: string;
  id: string;
  info_other_occupations: any;
  info_work_exp_in_school: any;
  info_work_exp_out_school: any;
  ins_id: string;
  is_active: string;
  is_approved: string;
  language_skills: any;
  last_name: string;
  name_of_institutition: string;
  other_occupations: string;
  password: string;
  phone_number: string;
  police_clearance_report: string;
  police_report: string;
  preferred_work_institution: string;
  self_employed: string;
  specify_date: string;
  swedish_bank_account: string;
  swedish_personal_number: string;
  terms_and_condition: string;
  updated_at: string;
  vacancy_status: string;
  work_exp_in_school: string;
  work_exp_out_school: string;
}

export interface AbsentStaffData {
  data: AbsentStaff;
  type: string;
}

export interface ChooseConsultant {
  id: string;
  publish_to: string;
}

export interface graphData {
  name: string;
  data: Array<any>;
}

export interface DeleteTypes {
  id: string;
  endPoint: string;
}

export interface EmploymenHistory {
  from: Date | null | string;
  to: Date | null | string;
  role: string;
  responsibility: string;
  employer: string;
}

export interface RelevantEducation {
  ins_name: string;
  education: string;
  from: Date | null | string;
  to: Date | null | string;
}

export interface expDetailsProps {
  name_of_emp?: string;
  duration?: string;
  title_of_role?: string;
  key_res?: string;
}

export interface ApplicationProps {
  first_name: string;
  last_name: string;
  address?: string;
  email_id: string;
  postalcode?: string;
  area_name?: string;
  phone_number: string;
  city?: string;
  date_of_birth?: Date | null | string;
  swedish_bank_account?: any;
  occup_sector: string;
  occup_sector_id: any;
  occup_state?: any;
  preferred_work_institution?: any;
  preferred_location?: any;
  qualified_teacher?: boolean | null;
  specificField: string;
  work_exp_in_school: boolean | null;
  info_work_exp_in_school: Array<expDetailsProps>;
  work_exp_out_school: boolean | null;
  info_work_exp_out_school: Array<expDetailsProps>;
  specify_date?: any;
  work_start?: boolean | null;
  language_skills: OtherLangArr;
  police_clearance_report?: any;
  police_report?: any;
  Driving_license?: boolean | null;
  file?: any;
  employment_history: Array<EmploymenHistory>;
  relevant_education: Array<RelevantEducation>;
  terms_and_condition?: any;
  work_permit?: any;
  right_to_work_in_Sweden?: boolean | null;
  Iam_EU_citizen?: boolean | null;
  Iam_Non_EU_citizen?: boolean | null;
  right_to_work_in_Sweden_attachment: string | ArrayBuffer | null;
  working_evening_or_weekends: boolean | null;
  linkedIn: string;
  description?: string;
  preferred_location_1: string;
  qualifiedTeacher: boolean | null;
  swedishLara: boolean | null;
  studyingteacher: boolean | null;
  qualifiedteacherdate: Date | null | string;
  howDoYouKnowUs?: string;
  subjectlist: Array<string>;
  agegroup: Array<string>;
  subjectlistno: Array<string>;
  agegroupno: Array<string>;
  subjectlistyes: Array<string>;
  agegroupyes: Array<string>;
  instutionstudyteacher: string;
  preferWorkIn: string[];
  occupation: string;
  otheroccupationfield: string;
  position: string;
  position_id: string;
  fileswedishlara: string;
  marketing_purpose: boolean | null;
  accurate_information: boolean | null;
  terms_and_conditions: boolean | null;
  engSkills?: string;
  sweSkills?: string;
  [key: string]: any;
}

export interface VacanciesApplicationProps {
  first_name: string;
  last_name: string;
  address?: string;
  email_id: string;
  postalcode?: string;
  area_name?: string;
  phone_number: string;
  city?: string;
  date_of_birth?: Date | null | string;
  swedish_bank_account?: any;
  occup_sector: string;
  position_id: string;
  occup_sector_id: string;
  occup_state?: any;
  preferred_work_institution?: any;
  preferred_location?: any;
  qualified_teacher?: boolean | null;
  specificField: string;
  work_exp_in_school: boolean | null;
  info_work_exp_in_school: Array<expDetailsProps>;
  work_exp_out_school: boolean | null;
  info_work_exp_out_school: Array<expDetailsProps>;
  specify_date?: any;
  work_start?: boolean | null;
  language_skills: OtherLangArr;
  police_clearance_report?: any;
  police_report?: any;
  Driving_license?: boolean | null;
  file?: any;
  employment_history: Array<EmploymenHistory>;
  relevant_education: Array<RelevantEducation>;
  terms_and_condition?: any;
  work_permit?: any;
  right_to_work_in_Sweden?: boolean | null;
  Iam_EU_citizen?: boolean | null;
  Iam_Non_EU_citizen?: boolean | null;
  right_to_work_in_Sweden_attachment: string | ArrayBuffer | null;
  working_evening_or_weekends: boolean | null;
  linkedIn: string;
  description?: string;
  preferred_location_1: string;
  qualifiedTeacher: boolean | null;
  swedishLara: boolean | null;
  studyingteacher: boolean | null;
  qualifiedteacherdate: Date | null | string;
  howDoYouKnowUs?: string;
  subjectlist: Array<string>;
  agegroup: Array<string>;
  subjectlistno: Array<string>;
  agegroupno: Array<string>;
  subjectlistyes: Array<string>;
  agegroupyes: Array<string>;
  instutionstudyteacher: string;
  preferWorkIn: string[];
  occupation: string;
  otheroccupationfield: string;
  position: string;
  fileswedishlara: string;
  marketing_purpose: boolean | null;
  accurate_information: boolean | null;
  terms_and_conditions: boolean | null;
  engRating?: string;
  sweRating?: string;
  [key: string]: any;
}

export interface GetInsTopHoursResposne {
  created_at: string;
  id: Number;
  ins_id: Number;
  is_active: Number;
  remaining_hours: Number;
  total_hours: Number;
  updated_at: string;
  used_hours: Number;
}

export interface InitialState {
  isLoggedIn: boolean;
  isLoading: boolean;
  hasError: boolean;
  hasRegister: boolean;
  getAbsentStaff: Array<AbsentStaffData>;
  getMyconsultant: Array<getMyconsultant>;
  todaycoverCount: string;
  todayunCoverCount: string;
  todaylessonCount: string;
  todayUnLessonCount: string;
  todayabsenceCount: string;
  customcoverCount: string;
  customunCoverCount: string;
  customlessonCount: string;
  customUnLessonCount: string;
  customabsenceCount: string;
  tommorowcoverCount: string;
  tommorowunCoverCount: string;
  tommorowlessonCount: string;
  tommorowUnLessonCount: string;
  tommorowabsenceCount: string;
  chooseConsultant: Array<ChooseConsultant>;
  addMyConsultantMsg: string;
  graphCount: Array<graphData>;
  Websitecreateadapp: Array<websitecreatead>;
  getmyInstituteCons: Array<getMyconsultant>;
  reload: boolean;
  occupitationalList: Array<DropdownListProps>;
  stateoccupitationalList: Array<DropdownListProps>;
  subjectList: Array<DropdownListProps>;
  ageGroupList: Array<DropdownListProps>;
  avaliabialityList: Array<DropdownListProps>;
  socialOccupationList: Array<DropdownListProps>;
  userApplicationProsp: ApplicationProps;
  VacanciesuserApplicationProsp: VacanciesApplicationProps;
  faq: string;
  errors: Object;
  getInsHoursList: Array<GetInsTopHoursResposne>;
  getPositionList: Array<GetPositionResposne>;
}

export interface GetPositionResposne {
  id: any;
  position: string;
  position_swedish: string;
  created_at: string;
  updated_at: string;
  is_active: string;
}

export interface websitecreatead {
  id?: any;
  ad_title: string;
  location: string;
  sector_id?: string;
  sector: string;
  descrption: string;
  published_date: Date | null;
  end_date: Date | null | string;
  is_active?: number;
  created_at?: Date | null | string;
  updated_at?: Date | null | string;
}

export const initialState: InitialState = {
  isLoading: false,
  isLoggedIn: false,
  hasError: false,
  hasRegister: false,
  getAbsentStaff: [],
  getInsHoursList: [],
  getMyconsultant: [],
  errors: {},
  todaycoverCount: "",
  todayunCoverCount: "",
  todaylessonCount: "",
  todayUnLessonCount: "",
  todayabsenceCount: "",
  customcoverCount: "",
  customunCoverCount: "",
  customlessonCount: "",
  customUnLessonCount: "",
  customabsenceCount: "",
  tommorowcoverCount: "",
  tommorowunCoverCount: "",
  tommorowlessonCount: "",
  tommorowUnLessonCount: "",
  tommorowabsenceCount: "",
  chooseConsultant: [],
  addMyConsultantMsg: "",
  graphCount: [],
  getmyInstituteCons: [],
  reload: false,
  occupitationalList: OccupitationalList,
  stateoccupitationalList: teaching,
  subjectList: subjectList,
  ageGroupList: ageGrpList,
  Websitecreateadapp: [],
  avaliabialityList: avalibalityList,
  userApplicationProsp: {
    first_name: "",
    last_name: "",
    address: "",
    email_id: "",
    postalcode: "",
    area_name: "",
    phone_number: "",
    preferred_location: "",
    description: "",
    city: "",
    date_of_birth: null,
    preferred_location_1: "",

    right_to_work_in_Sweden_attachment: "",
    Iam_EU_citizen: null,
    Iam_Non_EU_citizen: null,
    linkedIn: "",
    occup_sector: "",
    occup_sector_id: "",
    studyingteacher: null,
    swedish_bank_account: null,
    otheroccupationfield: "",
    working_evening_or_weekends: null,
    qualifiedteacherdate: null,
    work_exp_in_school: null,
    language_skills: { english: "", swedish: "", other: [] },
    info_work_exp_in_school: [],
    work_exp_out_school: null,
    info_work_exp_out_school: [],
    subjectlist: [],
    specificField: "",
    agegroup: [],
    subjectlistno: [],
    agegroupno: [],
    subjectlistyes: [],
    agegroupyes: [],
    employment_history: [],
    relevant_education: [],
    qualifiedTeacher: null,
    swedishLara: null,
    instutionstudyteacher: "",
    preferWorkIn: [],
    occupation: "",
    position: "",
    position_id: "",
    work_start: null,
    fileswedishlara: "",
    howDoYouKnowUs: "",
    Driving_license: null,
    right_to_work_in_Sweden: null,
    marketing_purpose: null,
    accurate_information: null,
    terms_and_conditions: null,
  },
  VacanciesuserApplicationProsp: {
    first_name: "",
    last_name: "",
    address: "",
    email_id: "",
    postalcode: "",
    area_name: "",
    phone_number: "",
    preferred_location: "",
    description: "",
    city: "",
    date_of_birth: null,
    preferred_location_1: "",
    right_to_work_in_Sweden_attachment: "",
    Iam_EU_citizen: null,
    Iam_Non_EU_citizen: null,
    linkedIn: "",
    occup_sector: "",
    studyingteacher: null,
    swedish_bank_account: null,
    otheroccupationfield: "",
    occup_sector_id: "",
    position_id: "",
    working_evening_or_weekends: null,
    qualifiedteacherdate: null,
    work_exp_in_school: null,
    language_skills: { english: "", swedish: "", other: [] },
    info_work_exp_in_school: [],
    work_exp_out_school: null,
    info_work_exp_out_school: [],
    subjectlist: [],
    specificField: "",
    agegroup: [],
    subjectlistno: [],
    agegroupno: [],
    subjectlistyes: [],
    agegroupyes: [],
    employment_history: [],
    relevant_education: [],
    qualifiedTeacher: null,
    swedishLara: null,
    instutionstudyteacher: "",
    preferWorkIn: [],
    occupation: "",
    position: "",
    work_start: null,
    fileswedishlara: "",
    howDoYouKnowUs: "",
    Driving_license: null,
    right_to_work_in_Sweden: null,
    marketing_purpose: null,
    accurate_information: null,
    terms_and_conditions: null,
  },
  socialOccupationList: socialOccupationState,
  faq: "third",
  getPositionList: [],
};
