import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavBar = styled(Nav)`
  margin-top: -1%;
  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
`;

export const NavBarContainer = styled.ul`
  display: flex;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  list-style: none;
  width: 850px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-left: -12%;
  }
`;

export const MenuItemList = styled.li`
  position: relative;
  font-size: 14px;
  margin-top: 4%;
`;

export const MenuItemButton = styled.div`
  color: black;
  display: flex;
  border: none;
  width: 215px;
  background: none;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: black;
  }
`;

export const Anchor = styled(Link)`
  color: black;
  text-decoration: none;
  text-align: left;
  padding-left: 8%;
  &:hover,
  &:active,
  &:focus {
    color: black;
  }
`;

export const ChevronIcon = styled(FontAwesomeIcon)`
  margin-left: 2%;
  margin-top: 2%;
  @media (max-width: 600px) {
    margin-left: 2%;
  }
`;

export const MainMenuAnchor = styled(Link)`
  color: black;
  text-decoration: none;
  text-align: left;
  padding-left: 8%;
  padding-right: 16px;
  &:hover,
  &:active,
  &:focus {
    color: black;
  }
`;
export const MainMenuAnchor1 = styled(Link)`
  color: black;
  text-decoration: none;
  text-align: left;
  padding-left: -4%;
  padding-right: 16px;
  &:hover,
  &:active,
  &:focus {
    color: black;
  }
`;
