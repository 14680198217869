import { ReactElement, useEffect } from "react";
import absenceIcon from "../../../asserts/Saam.png";
import recruitmentIcon from "../../../asserts/new_recruitment.png";
import personelIcon from "../../../asserts/personal-growth.png";
import {
  CardWrapper,
  FlexWrapperSign,
  Image,
  CardText,
  SigninButton,
} from "../ChooseSignIn/subcomponents";
import { FlexWrapper, Footers } from "../../../components";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../const/routes";
import { PageWrapper } from "../ChooseSignIn/register/subcomponent";
import { ModalText, OurSolutionWrapper } from "../subcomponent";
import Aos from "aos";

const OurSolution = (): ReactElement => {
  const history = useHistory();
  const { signUpModules } = useSelector((state: RootState) => ({
    signUpModules: state.Language.language.signUp,
  }));
  const {
    absent_management,
    recruitment,
    personal_growth,
    our_Solutions_1,
    our_Solutions_2,
    LearnMore,
  } = signUpModules;

  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: false,
    });
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <PageWrapper>
      <OurSolutionWrapper>
        <FlexWrapper noMargin noPadding justifyContent="center">
          <ModalText data-aos="fade-down" data-aos-duration="3000">
            {our_Solutions_1} <br /> {our_Solutions_2}
          </ModalText>
        </FlexWrapper>
        <FlexWrapperSign>
          <CardWrapper top="0%">
            <FlexWrapper noMargin noPadding justifyContent="center">
              <Image src={absenceIcon} alt="icon" />
              <CardText>{absent_management}</CardText>
              <SigninButton
                margintop="1vw"
                onClick={() => {
                  history.push(ROUTES.STAFF_ABSENCE);
                }}
              >
                {LearnMore}
              </SigninButton>
            </FlexWrapper>
          </CardWrapper>
          <CardWrapper width="24" top="0%">
            <FlexWrapper noMargin noPadding justifyContent="center">
              <Image src={recruitmentIcon} alt="" />
              <CardText>{recruitment}</CardText>
              <SigninButton
                onClick={() => {
                  history.push(ROUTES.RECRUITMENT);
                }}
              >
                {LearnMore}{" "}
              </SigninButton>
            </FlexWrapper>
          </CardWrapper>
          <CardWrapper top="0%">
            <FlexWrapper noMargin noPadding justifyContent="center">
              <Image src={personelIcon} alt="" />
              <CardText>{personal_growth}</CardText>
              <SigninButton
                onClick={() => {
                  history.push(ROUTES.DEVELOPMENT);
                }}
              >
                {LearnMore}
              </SigninButton>
            </FlexWrapper>
          </CardWrapper>
        </FlexWrapperSign>
      </OurSolutionWrapper>
      <Footers />
    </PageWrapper>
  );
};

export default OurSolution;
