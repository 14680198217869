import moment from "moment";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  EditableDropdown,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../components";
import { PrimaryButton } from "../../../../../components/Button";
import { TYPE } from "../../../../../const/getType";
import { getCoveredVacancy } from "../../../../../redux/Hq_Management/Vacancy/api";
import { RootState } from "../../../../../redux/store";
import { CardWraper } from "../../Vacancies/PublishedVacancies/subcomponents";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { updateHasSuccess } from "../../../../../redux/Hq_Management/Vacancy/action";

const HQ_Covered = (): ReactElement => {
  const { userInfo, getCoveredData, lang, isLoading, success } = useSelector(
    (state: RootState) => ({
      userInfo: state.login.userInfo,
      getCoveredData: state.Vacancy.getCovered,
      isLoading: state.Vacancy.isLoading,
      lang: state.Language.language.Tables.cover,
      success: state.Vacancy.hasSuccess,
    })
  );

  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const [filterType, setfilter] = useState("");
  const { sectionTitle, tableHeader } = lang;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useState(true);

  useEffect(() => {
    if (userInfo?.type === TYPE.INSTITUTE) {
      dispatch(
        getCoveredVacancy({
          ins_id: userInfo?.data.id,
        })
      );
    } else {
      dispatch(
        getCoveredVacancy({
          ins_id: userInfo?.data.ins_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dateInputRef = useRef<ReactDatePicker | null>(null);
  const handleLabelClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.setOpen(true);
    }
  };
  return (
    <PageWrapper>
      <CardWraper width="90%">
        <ToastMessage
          show={success}
          onCloseHandler={() => {
            dispatch(updateHasSuccess(false));
          }}
          top="30px"
          bg={"primary"}
          message={"Your vacancy has been published successfully."}
        ></ToastMessage>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title={sectionTitle} noMargin />
          {filter && (
            <FlexWrapper
              noMargin
              noPadding
              justifyContent="space-between"
              style={{ paddingLeft: "25rem" }}
            >
              <div>
                <PrimaryButton
                  onClick={() => {
                    setDate(moment(new Date()).format("YYYY-MM-DD"));
                  }}
                >
                  Today
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => {
                    setDate(
                      moment(
                        new Date().setDate(new Date().getDate() + 1)
                      ).format("YYYY-MM-DD")
                    );
                  }}
                >
                  Tomorrow
                </PrimaryButton>
              </div>
              <Input
                value={date}
                inputType="date"
                onChange={(e: any) => {
                  setDate(e);
                }}
                height="auto"
                width="auto"
              />

              <EditableDropdown
                width="50"
                dropdownList={[
                  {
                    id: "01",
                    name: "All",
                  },
                  {
                    id: "02",
                    name: "Internal",
                  },
                  {
                    id: "03",
                    name: "External",
                  },
                ]}
                placeholder="Filter"
                handleSelect={(value: DropdownListProps) => {
                  setfilter(value.name);
                }}
              />

              {/* <div>
                <label onClick={handleLabelClick} style={{ cursor: "pointer" }}>
                  <Icon>
                    <FontAwesomeIcon icon={faFilter} />
                  </Icon>
                </label>
                <DatePicker
                  onChange={(date) =>
                    setDate(moment(date).format("YYYY-MM-DD"))
                  }
                  ref={dateInputRef}
                  customInput={<Hidden />}
                />
              </div> */}
            </FlexWrapper>
          )}
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {tableHeader.map((data, index) => (
                    <th key={`Header Unique id -${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getCoveredData
                  // eslint-disable-next-line array-callback-return
                  ?.filter((listValues) => {
                    if (date === "") {
                      return listValues;
                    } else if (listValues?.v_date.includes(date)) {
                      return listValues;
                    }
                  })
                  ?.filter((dataFilter) => {
                    if (filterType === "") {
                      return dataFilter;
                    } else if (filterType === "All") {
                      return dataFilter;
                    } else if (filterType === "Internal") {
                      return dataFilter.coveredByInternal === true;
                    } else if (filterType === "external") {
                      return dataFilter.coveredByExternal === true;
                    }
                  })
                  .map((data, i) => {
                    const {
                      start_time,
                      v_date,
                      end_time,
                      year_group,
                      classroom,
                      first_name,
                      last_name,
                      is_external,
                      coveredByName,
                      coveredByInternal,
                      coveredByExternal,
                      subject,
                    } = data;
                    return (
                      <tr key={i} style={{ textAlign: "center" }}>
                        <td>{moment(v_date).format("DD-MM-YYYY")}</td>
                        <td>{start_time}</td>
                        <td>{end_time}</td>
                        <td>{year_group}</td>
                        <td>{subject}</td>
                        <td>{classroom}</td>
                        <td>{`${first_name}
                         ${last_name}`}</td>
                        <td>{coveredByName}</td>
                        <td>
                          {coveredByInternal === true
                            ? "Internal"
                            : coveredByExternal === true
                            ? "External"
                            : "None"}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
    </PageWrapper>
  );
};

export default HQ_Covered;

const Hidden = styled.input`
  display: none;
`;
