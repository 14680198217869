import { ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../const/routes";
import { RootState } from "../../../redux/store";
import {
  HeaderWrapper,
  HumburgerIcon,
  Icon,
  Logo,
  LogoWrapper,
  NavbarDesktopWrapper,
  NavbarMobileWrapper,
  NavBar,
  NavItems,
  NavrightItems,
  DropDownItems,
  DropDownAnchor,
  ChervonIcon,
  ButtonWrapper,
} from "./subcomponents";
import DOHR from "../../../asserts/dohrLogo.png";
import { LangugeDropdown } from "../../../components";

const UserHeader = (): ReactElement => {
  const history = useHistory();
  const [extendNavbar, setExtendNavbar] = useState(false);

  const { language } = useSelector((state: RootState) => ({
    language: state.Language.language,
  }));

  const {
    ourSolutions,
    news,
    aboutUs,
    vacancies,
    contactUs,
    faq,
    apply,
    signIn,
    more,
    integrity,
  } = language.header.main;
  const { SA_and_SM, Recruitment, Personal_Development } =
    language.header.sideHeading;

  return (
    <HeaderWrapper>
      <Icon
        onClick={() => {
          setExtendNavbar((nav) => !nav);
        }}
      >
        <HumburgerIcon
          icon={extendNavbar ? ["fas", "xmark"] : ["fas", "bars"]}
          style={{ fontSize: "14px" }}
        />
      </Icon>

      <NavbarDesktopWrapper>
        <NavBar>
          <NavItems to="#">
            <span
              onClick={() => {
                history.push(ROUTES.OUR_SOLUTION);
              }}
            >
              {ourSolutions}
            </span>
            <ChervonIcon icon={["fas", "chevron-down"]} />

            <DropDownItems>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.STAFF_ABSENCE);
                }}
              >
                {SA_and_SM}
              </DropDownAnchor>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.RECRUITMENT);
                }}
              >
                {Recruitment}
              </DropDownAnchor>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.DEVELOPMENT);
                }}
              >
                {Personal_Development}
              </DropDownAnchor>
            </DropDownItems>
          </NavItems>

          <NavItems
            to="#"
            onClick={() => {
              history.push(ROUTES.VACANCIES);
            }}
          >
            {vacancies}
          </NavItems>
          <NavItems to="#">
            <span>{more}</span>
            <ChervonIcon icon={["fas", "chevron-down"]} />
            <DropDownItems>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.ABOUT);
                }}
              >
                {aboutUs}
              </DropDownAnchor>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.CONTACT_FORM);
                }}
              >
                {contactUs}
              </DropDownAnchor>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.NEWS);
                }}
              >
                {news}
              </DropDownAnchor>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.STATIC_FAQ);
                }}
              >
                {faq}
              </DropDownAnchor>
              <DropDownAnchor
                onClick={() => {
                  history.push(ROUTES.PRIVACY_POLICY);
                }}
              >
                {integrity}
              </DropDownAnchor>
            </DropDownItems>
          </NavItems>
        </NavBar>
      </NavbarDesktopWrapper>
      {/* mobileView */}
      {extendNavbar && (
        <NavbarMobileWrapper>
          <NavBar>
            <NavItems
              to="#"
              onClick={() => {
                history.push(ROUTES.OUR_SOLUTION);
                setExtendNavbar(false);
              }}
            >
              {ourSolutions}

              <DropDownItems>
                <DropDownAnchor
                  onClick={() => {
                    history.push(ROUTES.STAFF_ABSENCE);
                    setExtendNavbar(false);
                  }}
                >
                  {language.signUp.absent_management} ;
                </DropDownAnchor>
                <DropDownAnchor
                  onClick={() => {
                    history.push(ROUTES.RECRUITMENT);
                    setExtendNavbar(false);
                  }}
                >
                  {language.signUp.recruitment}
                </DropDownAnchor>
                <DropDownAnchor
                  onClick={() => {
                    history.push(ROUTES.DEVELOPMENT);
                    setExtendNavbar(false);
                  }}
                >
                  {language.signUp.personal_growth}
                </DropDownAnchor>
              </DropDownItems>
            </NavItems>
            <NavItems
              to="about"
              spy={true}
              smooth={false}
              onClick={() => {
                history.push(ROUTES.VACANCIES);
                setExtendNavbar(false);
              }}
            >
              {vacancies}
            </NavItems>
            <NavItems
              to="about"
              spy={true}
              smooth={false}
              onClick={() => {
                history.push(ROUTES.ABOUT);
                setExtendNavbar(false);
              }}
            >
              {aboutUs}
            </NavItems>
            <NavItems
              to={"#"}
              onClick={() => {
                history.push(ROUTES.CONTACT_FORM);
                setExtendNavbar(false);
              }}
            >
              {contactUs}
            </NavItems>
            <NavItems
              to="news"
              spy={true}
              smooth={false}
              onClick={() => {
                history.push(ROUTES.NEWS);
                setExtendNavbar(false);
              }}
            >
              {news}
            </NavItems>
            <NavItems
              to={"#"}
              onClick={() => {
                history.push(ROUTES.STATIC_FAQ);
                setExtendNavbar(false);
              }}
            >
              {faq}
            </NavItems>
          </NavBar>
        </NavbarMobileWrapper>
      )}
      <LogoWrapper
        onClick={() => {
          history.push(ROUTES.LANDING_PAGE);
        }}
      >
        <Logo src={DOHR} />
      </LogoWrapper>
      <ButtonWrapper>
        <NavrightItems
          onClick={() => {
            history.push(ROUTES.REGISTER_IN_OPTION);
          }}
        >
          {apply}
        </NavrightItems>
        <NavrightItems
          Background="none"
          color="#002c99"
          border="none"
          onClick={() => {
            history.push(ROUTES.SIGN_IN_OPTION);
          }}
        >
          {signIn}
        </NavrightItems>
        <LangugeDropdown top="1%" />
      </ButtonWrapper>
    </HeaderWrapper>
  );
};

export default UserHeader;
