/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { ReactElement, useEffect, useState } from "react";
import {
  ActionButton,
  CardWrapper,
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../components";
import { TableHeaderData } from "./const";
import { Form, Modal, ModalHeader, Table } from "react-bootstrap";
import { CardWraper } from "../../../HqManagement/AbsenceManagement/Vacancies/PublishedVacancies/subcomponents";
import { DisabledIcon, TrashIcon } from "./subcomponents";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  EditAbsenceApi,
  GetLeaveTypeApi,
  getMyAbsencybyTeacherId,
} from "../../../../redux/Teachers/RegisterAbsence/api";
import { RootState } from "../../../../redux/store";
import moment from "moment";
import { PrimaryButton } from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../const/routes";
import { CommonDelete } from "../../../../redux/Common/api";
import apiEndpoints from "../../../../const/apiEndpoints";
import { LeaveTypeDropdown } from "../../../../helpers/dropdown";
import { DropdownListProps } from "../../../../components/EditableDropdown/typings";
import { CardHeader, FormLabel } from "../RegisterLeave/subcomponents";
import { getMyAbsenceByIdProps } from "../../../../redux/Teachers/RegisterAbsence/types";
import { updateEditModal } from "../../../../redux/Teachers/RegisterAbsence/action";
import { Label } from "../../../../typography";
import {
  CancleButton,
  DeleteButton,
} from "../../../Institute/Management/ViewCustomer/subcomponents";
import { Tooltiped } from "../../../HqManagement/AbsenceManagement/unCovered/subcomponent";
import { Title } from "../../../../components/EditableDropdown/subcomponents";

const HQ_MyAbsence = (): ReactElement => {
  const history = useHistory();
  const {
    userInfo,
    absenceData,
    isLoading,
    reload,
    editField,
    TypeofLeave,
    editReload,
    isLoadingEditModal,
  } = useSelector(
    (state: RootState) => ({
      userInfo: state?.login?.userInfo,
      absenceData: state.Absence?.getMyAbsenceByTeacherid,
      isLoading: state.Absence?.isLoading,
      reload: state.common.reload,
      editField: state.Absence.getMyAbsenceById,
      editReload: state.Absence.Reload,
      TypeofLeave: state.Absence.LeaveType,
      isLoadingEditModal: state.Absence.isLoadingEditModal,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [date, setdate] = useState("");
  const [toastmsg, settoastMsg] = useState(false);
  const [Value, setValue] = useState(editField as getMyAbsenceByIdProps);
  const [showModal, setShowModal] = useState(false);
  const [showTimeInput, setShowTimeInput] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [checked, setChecked] = useState(false);
  const [timeTrigger, setTimeTrigger] = useState(false);

  useEffect(() => {
    dispatch(
      getMyAbsencybyTeacherId({
        id: userInfo?.data?.unique_id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload || editReload]);

  useEffect(() => {
    dispatch(GetLeaveTypeApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoadingEditModal) {
      setTimeTrigger(true);
      if (editField?.leave_type === "Parental leave") {
        setShowModal(true);
      } else if (editField?.leave_type !== "Parental leave") {
        setShowModal(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEditModal]);

  useEffect(() => {
    if (timeTrigger) {
      if (editField?.from_date === editField?.to_date) {
        setShowTimeInput(true);
      } else {
        setShowTimeInput(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeTrigger]);

  const LeaveTypeDropDown = TypeofLeave ? LeaveTypeDropdown(TypeofLeave) : [];
  const hideDate = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    setValue(editField);
  }, [editField]);

  const [deleteLeave, setDeleteleave] = useState("");
  const [show, setShow] = useState(false);
  const [ids, setId] = useState("");
  const [filter, setfilter] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [leave, setLeave] = useState({
    Name: "",
    LeaveType: "",
    SubstituteRequired: "",
    FromDate: "",
    ToDate: "",
    Reason: "",
    StartTime: "",
    EndTime: "",
    Comment: "",
    Createdate: "",
    Denyreason: "",
    Parentapercentage: "",
    ChildName: "",
    BirthDate: "",
    AdComment: "",
    DueDate: "",
    DeniedDate: "",
    Status: "",
    DeniedBy: "",
  });
  const duration1 = moment.duration(leave.StartTime);
  const duration2 = moment.duration(leave.EndTime);
  const difference = duration2.subtract(duration1);
  const hours = Math.floor(difference.asHours());
  const day1 = moment(leave.FromDate);
  const day2 = moment(leave.ToDate);

  const totalDays = day2.diff(day1, "days") + 1;
  return (
    <PageWrapper>
      <Modal
        show={deleteLeave ? true : false}
        size={"sm"}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <FlexWrapper justifyContent="center" width="100%">
          <FlexWrapper justifyContent="center">
            <Label>
              Are you sure you want to delete your request for leave?{" "}
            </Label>
          </FlexWrapper>
          <FlexWrapper noPadding>
            <CancleButton
              onClick={() => {
                setDeleteleave("");
              }}
            >
              No
            </CancleButton>
            <DeleteButton
              onClick={() => {
                setDeleteleave("");

                dispatch(
                  CommonDelete({
                    id: deleteLeave,
                    endPoint: apiEndpoints.deleteAbsence,
                  })
                );
              }}
            >
              Yes
            </DeleteButton>
          </FlexWrapper>
        </FlexWrapper>
      </Modal>
      <Modal
        show={showDetail}
        onHide={() => {
          setShowDetail(false);
        }}
        // size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Leave Details
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Name: </b> {leave.Name}
              <br />
              <br />
              <b>Leave type: </b> {leave.LeaveType}
              <br />
              <br />
              <b>Substitute required: </b>
              {leave.SubstituteRequired}
              <br />
              <br />
              <b>From date: </b>
              {leave.FromDate}
              <br />
              <br />
              <b>To date: </b>
              {leave.ToDate}
              <br />
              <br />
              <b>Start time: </b>
              {leave.StartTime}
              <br />
              <br />
              <b>End time: </b>
              {leave.EndTime}
              <br />
              <br />
              <b>Duration: </b>
              {leave.FromDate == leave.ToDate
                ? `${hours} hours`
                : `${totalDays} Days`}
              <br />
              <br />
              <b>Submitted on: </b>
              {leave.Createdate}
              <br />
              <br />
              <b>Reason: </b>
              {leave.Reason}
              <br />
              <br />
              <b>Comment: </b>
              {leave.Comment}
              <br />
              <br />
              <b>Status: </b>
              {leave.Status}
              <br />
              <br />
              {leave.Status === "Denied" ? (
                <>
                  <b>Denied on: </b>
                  {leave.DeniedDate}
                  <br />
                  <br />
                  <b>Denied by: </b>
                  {leave.DeniedBy}
                  <br />
                  <br />
                  <b>Reason for deny: </b>
                  {leave.Denyreason}
                  <br />
                  <br />
                </>
              ) : leave.Status === "Approved" ? (
                <>
                  <b>Approved on: </b>
                  {leave.DeniedDate}
                  <br />
                  <br />
                  <b>Approved by: </b>
                  {leave.DeniedBy}
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
              {leave.LeaveType === "Parental leave" ? (
                <>
                  <b>Percentage of your parental leave: </b>
                  {leave.Parentapercentage}
                  <br />
                  <br />
                  <b>First initial of the childs name: </b>
                  {leave.ChildName}
                  <br />
                  <br />
                  <b>Year and month of the birth date: </b>
                  {leave.BirthDate}
                  <br />
                  <br />

                  <b>Child not born yet</b>

                  <br />
                  <br />
                  <b>Additional comments: </b>
                  {leave.AdComment}
                  <br />
                  <br />

                  <b>Approximate due date: </b>
                  {leave.DueDate}
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                setShowDetail(false);
              }}
            >
              Close
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle title="My Absence" noMargin />
          <FlexWrapper noMargin noPadding>
            <Input
              value={date}
              inputType="date"
              onChange={(value: string) => {
                setdate(value);
              }}
              height="auto"
              width="20"
            />

            <PrimaryButton
              onClick={() => {
                history.push(ROUTES.TEACHER.TEC_REGISTER_LEAVE);
              }}
            >
              Register Absence
            </PrimaryButton>
            {/* <DropdownWrapper> */}
            <div style={{ width: "200px" }}>
              <EditableDropdown
                dropdownList={[
                  { id: "0", name: "All" },
                  { id: "1", name: "Approved" },
                  { id: "2", name: "Pending" },
                  { id: "3", name: "Denied" },
                ]}
                isRequired
                placeholder={"Filter by"}
                handleSelect={(value: DropdownListProps) => {
                  setfilter(value.name);
                }}
              />
              {/* </DropdownWrapper> */}
            </div>
          </FlexWrapper>
        </FlexWrapper>
        <ToastMessage
          show={toastmsg}
          message={
            "Leave requests with 'Approved' or 'Denied' status cannot be removed. Please resubmit your request if needed."
          }
          bg={"primary"}
          onCloseHandler={() => settoastMsg(false)}
          top="-44px"
          right="17rem"
        ></ToastMessage>

        <FlexWrapper justifyContent="center">
          {isLoading ? (
            <Loader />
          ) : absenceData.length === 0 ? (
            ""
          ) : (
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {absenceData
                  // eslint-disable-next-line
                  .filter((listValues) => {
                    if (date === "") {
                      return listValues;
                    } else if (listValues?.from_date.includes(date)) {
                      return listValues;
                    }
                  })
                  .filter((listValuess) => {
                    if (filter === "") {
                      return listValuess;
                    } else if (filter === "All") {
                      return listValuess;
                    } else if (filter === "Approved") {
                      return listValuess?.is_approved == 1;
                    } else if (filter === "Pending") {
                      return (
                        listValuess?.is_approved == 0 &&
                        listValuess?.is_denied == false
                      );
                    } else if (filter === "Denied") {
                      return listValuess?.is_denied == true;
                    }
                  })
                  .map((data, index) => {
                    const {
                      first_name,
                      last_name,
                      leave_type,
                      from_date,
                      to_date,
                      to_time,
                      is_approved,
                      is_denied,
                      from_time,
                      id,
                      reason,
                      additional_comment,
                      deny_reason,
                      updated_at,
                      created_at,
                      substitute_required,
                      Parental_leave_percentage,
                      child_first_name,
                      appro_due_date,
                      who_denied,
                      parental_additional_comment,
                      parental_birth_date,
                      approved_by,
                    } = data;
                    const isBoolean = is_approved === 1 || is_denied === true;
                    const fromTime = moment(from_time, "hh:ss:mm");
                    const endTime = moment(to_time, "hh:ss:mm");

                    const duration = moment.duration(endTime.diff(fromTime));

                    const getHours = duration.hours();
                    const minutes = duration.minutes();
                    let hh = getHours <= 10 ? `${getHours}` : getHours;
                    let mm = minutes <= 10 ? `0${minutes}` : minutes;

                    const fromdateFormate =
                      moment(from_date).format("MM/DD/YYYY");
                    const todateFormate = moment(to_date).format("M/DD/YYYY");
                    var fDt = moment(fromdateFormate, "M/DD/YYYY");
                    var tDt = moment(todateFormate, "M/DD/YYYY");
                    var dayDiff = tDt.diff(fDt, "days");
                    const toolTipId = `tooltip-${index}`;
                    console.log(dayDiff === 0 ? "yes" : "no");
                    console.log(fromTime);
                    console.log(endTime);
                    return (
                      <tr key={index} style={{ textAlign: "center" }}>
                        <td id="testing">{index + 1}</td>
                        <td>{`${first_name}${last_name}`}</td>
                        <td>{leave_type}</td>
                        <td>{moment(from_date).format("DD-MM-YYYY")}</td>
                        <td>{moment(to_date).format("DD-MM-YYYY")}</td>{" "}
                        <td>{dayDiff === 0 ? from_time : "-"}</td>
                        <td>{dayDiff === 0 ? to_time : "-"}</td>
                        <td
                          data-tip={from_time}
                          data-for={toolTipId}
                          style={{ cursor: "pointer" }}
                        >
                          {from_time && to_time != "00:00:00" ? (
                            <Tooltiped
                              title={`${from_time}-${to_time}`}
                              placement="top"
                            >
                              <p style={{ color: "black" }}>{`${
                                hh === "0" && mm === "00"
                                  ? `${`${dayDiff + 1} days`}`
                                  : hh > "1"
                                  ? `${hh}:${mm} hours `
                                  : `${hh}:${mm} hour`
                              }`}</p>
                            </Tooltiped>
                          ) : (
                            <p style={{ color: "black" }}>{`${
                              hh === "0" && mm === "00"
                                ? `${`${dayDiff + 1} days`}`
                                : hh > "1"
                                ? `${hh}:${mm} hours `
                                : `${hh}:${mm} hour`
                            }`}</p>
                          )}
                        </td>
                        <td>{reason ? reason : "-"}</td>
                        <td>{additional_comment ? additional_comment : "-"}</td>
                        <td
                          data-tip={reason}
                          data-for={toolTipId}
                          style={{ cursor: "pointer" }}
                        >
                          {is_denied === true ? (
                            <span
                              style={{ color: "red" }}
                              onMouseOver={() => {
                                setShow(true);
                                setId(id);
                              }}
                              onFocus={() => {
                                setShow(true);
                              }}
                              onMouseLeave={() => {
                                setShow(false);
                              }}
                              onBlur={() => {
                                setShow(false);
                              }}
                            >
                              {" "}
                              {show && ids === id ? (
                                <Tooltiped title={deny_reason} placement="top">
                                  <span>Denied</span>
                                </Tooltiped>
                              ) : (
                                "Denied"
                              )}{" "}
                            </span>
                          ) : is_approved === 0 ? (
                            <span style={{ color: "red" }}>Pending</span>
                          ) : (
                            <span style={{ color: "green" }}>Approved</span>
                          )}
                        </td>
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setShowDetail(true);
                            setLeave({
                              Name: `${first_name} ${last_name}`,
                              LeaveType: leave_type,
                              SubstituteRequired:
                                substitute_required === 1 ? "Yes" : "No",
                              FromDate: moment(from_date).format("YYYY-MM-DD"),
                              ToDate: moment(to_date).format("YYYY-MM-DD"),
                              Reason: reason,
                              StartTime: from_time,
                              EndTime: to_time,
                              Comment: additional_comment,
                              Createdate:
                                moment(created_at).format("YYYY-MM-DD"),
                              Denyreason: deny_reason,
                              Parentapercentage: Parental_leave_percentage,
                              ChildName: child_first_name,
                              BirthDate: parental_birth_date
                                ? moment(parental_birth_date).format("YYYY/MM")
                                : "N/A",
                              AdComment: parental_additional_comment,
                              DueDate: appro_due_date
                                ? moment(appro_due_date).format("YYYY-MM-DD")
                                : "N/A",
                              DeniedDate:
                                moment(updated_at).format("YYYY-MM-DD"),
                              Status:
                                is_denied === true
                                  ? "Denied"
                                  : is_approved === 0
                                  ? "Pending"
                                  : "Approved",
                              DeniedBy: who_denied ? who_denied : approved_by,
                            });
                          }}
                        >
                          View
                        </td>
                        <td>
                          {isBoolean ? (
                            <>
                              <Icon
                                onClick={() => {
                                  settoastMsg(true);
                                  window.scroll({ top: 0 });
                                }}
                              >
                                <DisabledIcon icon={["far", "trash-can"]} />
                              </Icon>
                            </>
                          ) : (
                            <>
                              <Icon
                                onClick={() => {
                                  setDeleteleave(id);
                                }}
                              >
                                <TrashIcon
                                  icon={["far", "trash-can"]}
                                  id="testing"
                                />
                              </Icon>
                            </>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          )}
          {isLoadingEditModal ? (
            <Modals
              center={true}
              show={isLoadingEditModal}
              handleCancel={() => {
                setValue(editField);
                dispatch(updateEditModal(false));
                setTimeTrigger(false);
              }}
              handleSubmit={() => {
                dispatch(EditAbsenceApi(Value));
                dispatch(updateEditModal(false));
                setTimeTrigger(false);
              }}
              sizeModal="xl"
              title="Edit Absence Details"
            >
              <FlexWrapper>
                <DropdownWrapper>
                  <Input
                    label="Start date"
                    minDate={hideDate}
                    value={Value?.from_date}
                    inputType={"date"}
                    onChange={(value: string) => {
                      setValue({ ...Value, from_date: value });
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper>
                  <Input
                    label="End date"
                    minDate={hideDate}
                    value={Value?.to_date}
                    inputType={"date"}
                    onChange={(value: string) => {
                      setValue({ ...Value, to_date: value });
                    }}
                  />
                </DropdownWrapper>
                {showTimeInput ? (
                  <>
                    <DropdownWrapper>
                      <Input
                        isRequired
                        label="Start Time"
                        value={Value?.from_time}
                        inputType={"time"}
                        onChange={(value: string) => {
                          setValue({ ...Value, from_time: value + ":00" });
                        }}
                      />
                    </DropdownWrapper>
                    <DropdownWrapper>
                      <Input
                        isRequired
                        label="End Time"
                        value={Value?.to_time}
                        inputType={"time"}
                        onChange={(value: string) => {
                          setValue({ ...Value, to_time: value + ":00" });
                        }}
                      />
                    </DropdownWrapper>
                  </>
                ) : null}
                <DropdownWrapper>
                  <EditableDropdown
                    defaultValue={{
                      name: editField.leave_type,
                      id: editField.ins_id,
                    }}
                    isRequired
                    title="Leave Type"
                    dropdownList={LeaveTypeDropDown}
                    placeholder={"Leave Type"}
                    handleSelect={(value: DropdownListProps) => {
                      setValue({
                        ...Value,
                        leave_type: value.name,
                      });
                      if (value.name === "Parental leave") {
                        setShowModal(true);
                      } else if (value.name === " Leave with Pay") {
                        setShowReason(true);
                        setShowModal(false);
                      } else if (value.name === "Leave without pay") {
                        setShowReason(true);
                        setShowModal(false);
                      } else {
                        setShowModal(false);
                        setShowReason(false);
                      }
                    }}
                  />
                </DropdownWrapper>
                <DropdownWrapper width="25">
                  <EditableDropdown
                    title="Is Substitute Required ?"
                    dropdownList={[
                      { id: "1", name: "Yes" },
                      { id: "2", name: "No" },
                    ]}
                    placeholder={"Yes or No"}
                    defaultValue={
                      Value?.substitute_required === 1
                        ? { id: "1", name: "Yes" }
                        : { id: "2", name: "No" }
                    }
                    handleSelect={(value: DropdownListProps) => {
                      setValue({
                        ...Value,
                        substitute_required:
                          value.name === "Yes" ? true : false,
                      });
                    }}
                  />
                </DropdownWrapper>
              </FlexWrapper>

              {showModal ? (
                <>
                  <CardWrapper width="100" padding="0">
                    <FlexWrapper justifyContent="space-around">
                      <FormLabel>Parental Leave*</FormLabel>
                      <Form>
                        <Form.Group controlId="LeavePercent">
                          <Form.Check
                            value="100%"
                            type="radio"
                            aria-label="radio 1"
                            label="100%"
                            onChange={(e: any) => {
                              setValue({
                                ...Value,
                                Parental_leave_percentage: e.target.value,
                              });
                            }}
                            checked={
                              Value?.Parental_leave_percentage === "100%"
                            }
                          />
                          <Form.Check
                            value="75%"
                            type="radio"
                            aria-label="radio 2"
                            label="75%"
                            onChange={(e: any) => {
                              setValue({
                                ...Value,
                                Parental_leave_percentage: e.target.value,
                              });
                            }}
                            checked={Value?.Parental_leave_percentage === "75%"}
                          />
                          <Form.Check
                            value="25%"
                            type="radio"
                            aria-label="radio 2"
                            label="25%"
                            onChange={(e: any) => {
                              setValue({
                                ...Value,
                                Parental_leave_percentage: e.target.value,
                              });
                            }}
                            checked={Value.Parental_leave_percentage === "25%"}
                          />
                        </Form.Group>
                      </Form>
                    </FlexWrapper>

                    <CardHeader>Specify your Childs details</CardHeader>
                    <FlexWrapper justifyContent="space-between" noMargin>
                      <FormLabel>Child's First Name</FormLabel>
                      <DropdownWrapper width="40">
                        <Input
                          value={Value?.child_first_name}
                          placeholder={"Child's First Name"}
                          inputType={"text"}
                          onChange={(value: string) => {
                            setValue({
                              ...Value,
                              child_first_name: value,
                            });
                          }}
                        />
                      </DropdownWrapper>
                    </FlexWrapper>
                    <FlexWrapper justifyContent="space-between" noMargin>
                      <FormLabel>Child's Personal Number</FormLabel>
                      <DropdownWrapper width="40">
                        <Input
                          value={Value?.child_personal_number}
                          inputType={"date"}
                          placeholder={"Child's Personal Number"}
                          onChange={(value: string) => {
                            setValue({
                              ...Value,
                              child_personal_number: value,
                            });
                          }}
                        />
                      </DropdownWrapper>
                    </FlexWrapper>
                    <FlexWrapper>
                      <Form.Group>
                        <Form.Check
                          value=""
                          aria-label="radio 4"
                          label="Child Not Born Yet"
                          onChange={() => {
                            setChecked((prev) => !prev);
                          }}
                          checked={checked}
                        />
                      </Form.Group>
                    </FlexWrapper>
                    {checked ? (
                      <FlexWrapper justifyContent="space-between" noMargin>
                        <FormLabel>Approximate Due Date</FormLabel>
                        <DropdownWrapper width="40">
                          <Input
                            value={Value?.appro_due_date}
                            inputType={"date"}
                            onChange={(value: string) => {
                              setValue({
                                ...Value,
                                appro_due_date: value,
                              });
                            }}
                          />
                        </DropdownWrapper>{" "}
                      </FlexWrapper>
                    ) : null}
                  </CardWrapper>
                </>
              ) : null}
            </Modals>
          ) : null}
        </FlexWrapper>
      </CardWraper>
      <Modals
        show={showReason}
        center={true}
        sizeModal="sm"
        title="Reason"
        handleCancel={() => {
          setValue({
            ...Value,
            reason: "",
          });
          setShowReason(false);
        }}
        handleSubmit={() => {
          setShowReason(false);
        }}
      >
        <FlexWrapper justifyContent="space-between" noMargin>
          <DropdownWrapper width="85">
            <Input
              value={Value?.reason}
              inputType={"textarea"}
              onChange={(value: string) => {
                setValue({
                  ...Value,
                  reason: value,
                });
              }}
            />
          </DropdownWrapper>
        </FlexWrapper>
      </Modals>
    </PageWrapper>
  );
};

export default HQ_MyAbsence;
