export interface GetAllLeaveReqProps {
  id: string;
  email_id: string;
  unique_id: string;
  first_name: string;
  last_name: string;
  leave_type_id: string;
  leave_type: string;
  from_date: string;
  is_denied: any;
  from_time: any;
  to_date: string;
  to_time: any;
  Parental_leave_percentage: string;
  child_first_name: string;
  child_last_name: string;
  appro_due_date: string;
  with_pay: string;
  without_pay: string;
  reason: string;
  substitute_required: any;
  is_approved: any;
  approved_by: string;
  additional_comment: string;
  approved_or_denied_on: string;
  deny_reason: string;
  created_at: any;
  date_info: any;
  parental_additional_comment: string;
  parental_birth_date: any;
}

export interface RequrInfo {
  title?: any;
  title_id?: any;
}

export interface getMyInstituteResponse {
  address: string;
  contact_number: string;
  id: string;
  institute_domin: string;
  institute_id: string;
  institute_name: string;
  institute_type: string;
  institute_type_id: string;
  locality: string;
  postcode: string;
  requirement_info: Array<RequrInfo>;
}

export interface InitialState {
  isLoggedIn: boolean;
  isLoading: boolean;
  getAllLeaveReq: Array<GetAllLeaveReqProps>;
  getMyidInstitute: Array<getMyInstituteResponse>;
  message: string;
  isSuccess: boolean;
}

export const initialState: InitialState = {
  isLoggedIn: false,
  isLoading: false,
  getAllLeaveReq: [],
  getMyidInstitute: [],
  message: "",
  isSuccess: false,
};
