import { ReactElement, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FlexWrapper, Footers } from "../../../../components";
import ROUTES from "../../../../const/routes";
import { colors } from "../../../../const/theme";
import { ApplyButton, PageWrapper } from "../../subcomponent";
import {
  CardContainer,
  CardImage,
  ContentContainer,
  InfoHeader,
  Recruit3Header,
  RecruitContainer,
  RecruitHeader,
  StartButton,
  ThirdWrapper,
  StyledCard,
  StyledText,
  StyledTexts,
  StyledTextss,
  Demo,
} from "../staffAbsence/subcomponent";
import { recruitment_text } from "./const";
import team from "../../../../asserts/Recruitment/team.png";
import Outsourcing from "../../../../asserts/Recruitment/piggy-bank.png";
import talent from "../../../../asserts/Recruitment/talent.png";
import bank from "../../../../asserts/Recruitment/bank.png";
import lazy from "../../../../asserts/Recruitment/lazy.png";
import { CardTitle } from "../../../GetStart/Customer/subcomponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const Recruitment = (): ReactElement => {
  const history = useHistory();
  const [count, setCount] = useState(0);
  const { lang } = useSelector((state: RootState) => ({
    lang: state.Language.language,
  }));

  const {
    make,
    tofind,
    strategy,
    heading1,
    heading2,
    heading3,
    heading4,
    heading5,
    heading6,
    heading7,
    heading8,
    heading9,
    heading10,
    heading11,
    heading12,
    heading13,
    heading14,
    means,
    stress,
    outsource,
    turn,
    best,
    cost,
  } = lang.signUp.recruitments;

  setTimeout(ImagineFunc, 5000);

  function ImagineFunc() {
    if (count < recruitment_text.length - 1) {
      setCount(count + 1);
    } else {
      setCount(0);
    }
  }
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <PageWrapper>
      <RecruitContainer>
        <FlexWrapper justifyContent="center" noPadding noMargin>
          <RecruitHeader>{make}</RecruitHeader>
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noPadding noMargin>
          <StartButton
            margintop="2vw"
            onClick={() => {
              history.push(ROUTES.CONTACT_FORM);
            }}
          >
            {lang.header.main.contactUs}
          </StartButton>
        </FlexWrapper>
      </RecruitContainer>
      <ContentContainer color="rgb(242, 243, 243)">
        <ThirdWrapper data-aos="zoom-in">
          <Recruit3Header>{strategy}</Recruit3Header>
          <StyledText
            left="1%"
            color="#002C99"
            width="80%"
            bold="500"
            center
            size="1.4vw"
          >
            <span style={{ marginBottom: "0.5vw" }}>
              {heading1} <Demo>{heading2}</Demo>
            </span>{" "}
            <span>
              {heading3}
              <Demo> {heading4} </Demo>
            </span>{" "}
            <span>{heading5}</span>{" "}
            <span style={{ marginBottom: "0.5vw" }}>{heading6}</span>{" "}
            <span>{heading7}</span> <span>{heading8}</span> <span></span>{" "}
            <span>{heading9}</span> <span> {heading10}</span>
          </StyledText>
          <StyledTexts
            left="1%"
            color="#002C99"
            width="80%"
            bold="500"
            center
            size="1.4vw"
          >
            <span style={{ marginBottom: "0.5vw" }}>
              {heading11}
              <Demo> {heading12} </Demo>
              {heading13} <Demo>{heading14}</Demo>{" "}
            </span>{" "}
          </StyledTexts>

          <StyledTextss
            left="1%"
            width="80%"
            color="#002C99"
            bold="500"
            size="1.4vw"
            center
          >
            <span>{tofind}</span>
          </StyledTextss>
        </ThirdWrapper>
        <FlexWrapper justifyContent="center" noMargin noPadding>
          <ApplyButton
            marginLeft="1%"
            margintop="1vw"
            onClick={() => {
              history.push(ROUTES.CONTACT_FORM);
            }}
          >
            {lang.header.main.contactUs}
          </ApplyButton>
        </FlexWrapper>
      </ContentContainer>
      <ContentContainer>
        <FlexWrapper noPadding noMargin justifyContent="center">
          <InfoHeader>{means}</InfoHeader>
        </FlexWrapper>
        <CardContainer>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={lazy} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{stress}</CardTitle>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={Outsourcing} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{outsource}</CardTitle>
            </Card.Body>
          </StyledCard>
          <StyledCard style={{ width: "16rem" }} className="text-center">
            <CardImage data-aos="zoom-in" variant="top" src={team} />
            <Card.Body>
              <CardTitle
                color={colors.darkBlue}
                // onClick={() => {
                //   history.push(ROUTES.REGISTER_FORM);
                // }}
              >
                {turn}
              </CardTitle>
            </Card.Body>
          </StyledCard>
        </CardContainer>
        <CardContainer paddingTop="4px">
          <StyledCard
            style={{ width: "16rem", marginLeft: "10%" }}
            className="text-center"
          >
            <CardImage data-aos="zoom-in" variant="top" src={talent} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{best}</CardTitle>
            </Card.Body>
          </StyledCard>
          <StyledCard
            style={{ width: "16rem", marginRight: "10%" }}
            className="text-center"
          >
            <CardImage data-aos="zoom-in" variant="top" src={bank} />
            <Card.Body>
              <CardTitle color={colors.darkBlue}>{cost}</CardTitle>
            </Card.Body>
          </StyledCard>
        </CardContainer>
      </ContentContainer>

      <Footers />
    </PageWrapper>
  );
};

export default Recruitment;
