import moment from "moment";
import { ReactElement, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BackButton,
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Input,
  Loader,
  PageWrapper,
  SectionTitle,
  ToastMessage,
} from "../../../../../../components";
import { PrimaryButton } from "../../../../../../components/Button";
import { DropdownListProps } from "../../../../../../components/EditableDropdown/typings";
import { hasFormError } from "../../../../../../helpers/formValidation";
import { AppDispatch, RootState } from "../../../../../../redux/store";
import {
  AddScheduleApi,
  GetScheduleApi,
} from "../../../../../../redux/Teachers/MySchedule/api";
import {
  getMySchedule,
  scheduleProps,
} from "../../../../../../redux/Teachers/MySchedule/types";
import { CardWraper, FormLable } from "../subcomponents";
import { CreateScheduleButton } from "../subcomponents";
import { DayDropdown, resetInitialValue } from "./const";
import ScheduleValidation, {
  CheckingTimeInDb,
  CheckingTimeInFinalvalue,
} from "./helper";
import { ScheduleField } from "./typings";

const AddSchedule = (): ReactElement => {
  const history = useHistory();
  const { userInfo, isLoading, schedule } = useSelector((state: RootState) => ({
    userInfo: state?.login.userInfo?.data,
    isLoading: state?.MySchedule.isLoading,
    schedule: state?.MySchedule.getSchedule,
  }));
  const dispatch: AppDispatch = useDispatch();

  const [FinalValues, setFinalValues] = useState<Array<scheduleProps>>([]);
  const [AddSchedule, setAddSchedule] = useState<scheduleProps>(
    {} as scheduleProps
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resetValue, setResetValue] = useState(resetInitialValue);
  const [errors, setErrors] = useState({} as Record<string, string>);
  const [sameTimeWarning, setSameTimeWarning] = useState(false);
  const [betweenTime, setBetweenTime] = useState(false);

  let addedData: Array<getMySchedule> = [];
  addedData =
    schedule?.length === 0 ? [{ title: "Day", DayData: [] }] : [...schedule];

  const addFormField = () => {
    const ValidationError = ScheduleValidation({
      AddSchedule,
      errors,
    });
    var startTime = moment(AddSchedule.start_time, "hh:mm");
    var endTime = moment(AddSchedule.end_time, "hh:mm");
    let isAfter = startTime.isAfter(endTime);
    if (isAfter) {
      setShow(true);
    } else {
      if (hasFormError(ValidationError)) {
        setErrors(ValidationError);
      } else {
        const isIncluded = FinalValues.some(
          (item) => item.day === AddSchedule.day
        );
        const isIncludedDB = schedule.some(
          (item) => item.title === AddSchedule.day
        );

        if (isIncluded || isIncludedDB) {
          // eslint-disable-next-line array-callback-return
          addedData?.map((data) => {
            if (data.title === AddSchedule.day || isIncluded) {
              // eslint-disable-next-line array-callback-return

              if (
                CheckingTimeInFinalvalue({
                  schduleValues: AddSchedule,
                  schduleArrayvalue: FinalValues,
                }) ||
                CheckingTimeInDb({
                  schduleList: data,
                  schduleValues: AddSchedule,
                })
              ) {
                if (
                  CheckingTimeInFinalvalue({
                    schduleValues: AddSchedule,
                    schduleArrayvalue: FinalValues,
                  }) ||
                  CheckingTimeInDb({
                    schduleList: data,
                    schduleValues: AddSchedule,
                  })
                ) {
                  setBetweenTime(true);
                }
              } else {
                setFinalValues([
                  ...FinalValues,
                  {
                    ...AddSchedule,
                    unique_id: userInfo?.unique_id,
                    cus_id: userInfo?.id,
                    ins_id: userInfo?.ins_id,
                  },
                ]);
                setAddSchedule({
                  day: "",
                  cus_id: "",
                  ins_id: "",
                  start_time: "",
                  end_time: "",
                  subject: "",
                  classroom: "",
                  year_group: "",
                } as scheduleProps);
                setResetValue({ day: true });
              }
            }
          });
        } else {
          setFinalValues([
            ...FinalValues,
            {
              ...AddSchedule,
              unique_id: userInfo?.unique_id,
              cus_id: userInfo?.id,
              ins_id: userInfo?.ins_id,
            },
          ]);
          setAddSchedule({
            day: "",
            cus_id: "",
            ins_id: "",
            start_time: "",
            end_time: "",
            subject: "",
            classroom: "",
            year_group: "",
          } as scheduleProps);
          setResetValue({ day: true });
        }
      }
    }
  };

  const handleRemove = (i: number) => {
    const value = [...FinalValues];
    value.splice(i, 1);
    setFinalValues(value);
  };

  // const handleSubmit = () => {
  //   // setControlTrigger(true);
  //   const ValidationError = ScheduleValidation({
  //     AddSchedule,
  //     errors,
  //   });
  //   if (hasFormError(ValidationError)) {
  //     setErrors(ValidationError);
  //   } else {
  //     const isIncludedDB = schedule.some(
  //       (item) => item.title === AddSchedule.day
  //     );

  //     const isIncluded = FinalValues.some(
  //       (item) => item.day === AddSchedule.day
  //     );

  //     if (isIncludedDB || isIncluded) {
  //       // eslint-disable-next-line array-callback-return
  //       addedData?.map((data) => {
  //         if (data.title === AddSchedule.day || isIncluded) {
  //           // eslint-disable-next-line array-callback-return
  //           if (
  //             CheckingTimeInFinalvalue({
  //               schduleValues: AddSchedule,
  //               schduleArrayvalue: FinalValues,
  //             }) ||
  //             CheckingTimeInDb({
  //               schduleList: data,
  //               schduleValues: AddSchedule,
  //             })
  //           ) {
  //             if (
  //               CheckingTimeInFinalvalue({
  //                 schduleValues: AddSchedule,
  //                 schduleArrayvalue: FinalValues,
  //               }) ||
  //               CheckingTimeInDb({
  //                 schduleList: data,
  //                 schduleValues: AddSchedule,
  //               })
  //             ) {
  //               setBetweenTime(true);
  //             }
  //           } else {
  //             console.log("Numbers");
  //             dispatch(
  //               AddScheduleApi({
  //                 dates_info: [
  //                   ...FinalValues,
  //                   {
  //                     ...AddSchedule,
  //                     unique_id: userInfo?.unique_id,
  //                     cus_id: userInfo?.id,
  //                     ins_id: userInfo?.ins_id,
  //                   },
  //                 ],
  //               })
  //             ).then((res) => {
  //               if (res?.meta?.requestStatus === "fulfilled") {
  //                 history.push("tcDashboard");
  //               }
  //             });

  //             setAddSchedule({
  //               day: "",
  //               cus_id: "",
  //               ins_id: "",
  //               start_time: "",
  //               end_time: "",
  //               subject: "",
  //               classroom: "",
  //               year_group: "",
  //             } as scheduleProps);
  //             setResetValue({ day: true });
  //           }
  //         }
  //       });
  //     } else {
  //       dispatch(
  //         AddScheduleApi({
  //           dates_info: [
  //             ...FinalValues,
  //             {
  //               ...AddSchedule,
  //               unique_id: userInfo?.unique_id,
  //               cus_id: userInfo?.id,
  //               ins_id: userInfo?.ins_id,
  //             },
  //           ],
  //         })
  //       ).then((res) => {
  //         if (res?.meta?.requestStatus === "fulfilled") {
  //           history.push("tcDashboard");
  //         }
  //       });

  //       setAddSchedule({
  //         day: "",
  //         cus_id: "",
  //         ins_id: "",
  //         start_time: "",
  //         end_time: "",
  //         subject: "",
  //         classroom: "",
  //         year_group: "",
  //       } as scheduleProps);
  //       setResetValue({ day: true });
  //       setFinalValues([]);
  //     }
  //   }
  // };

  const handleSubmit = () => {
    const ValidationError = ScheduleValidation({
      AddSchedule,
      errors,
    });

    if (hasFormError(ValidationError)) {
      setErrors(ValidationError);
    } else {
      const isTimeConflict = addedData.some((data) => {
        if (data.title === AddSchedule.day) {
          return (
            CheckingTimeInDb({
              schduleList: data,
              schduleValues: AddSchedule,
            }) ||
            CheckingTimeInFinalvalue({
              schduleValues: AddSchedule,
              schduleArrayvalue: FinalValues,
            })
          );
        }
        return false;
      });

      if (isTimeConflict) {
        // Handle time conflict, e.g., show an error message
        setBetweenTime(true);
      } else {
        // Combine FinalValues and AddSchedule into a single array
        const allScheduleData = [...FinalValues, AddSchedule];

        // Dispatch the AddScheduleApi with the combined data
        if (betweenTime) {
          setBetweenTime(true);
        } else {
          dispatch(
            AddScheduleApi({
              dates_info: allScheduleData.map((schedule) => ({
                ...schedule,
                unique_id: userInfo?.unique_id,
                cus_id: userInfo?.id,
                ins_id: userInfo?.ins_id,
              })),
            })
          ).then((res) => {
            if (res?.meta?.requestStatus === "fulfilled") {
              history.push("tcDashboard");
              // Clear the form fields and FinalValues after successful API call
              setAddSchedule({
                day: "",
                cus_id: "",
                ins_id: "",
                start_time: "",
                end_time: "",
                subject: "",
                classroom: "",
                year_group: "",
              });
              setFinalValues([]);
            }
          });
        }
      }
    }
  };
  const ValidateField = (field: ScheduleField): void => {
    setErrors(ScheduleValidation({ AddSchedule, errors, field }));
  };

  const [show, setShow] = useState(false);

  useEffect(() => {
    var startTime = moment(AddSchedule.start_time, "hh:mm");
    var endTime = moment(AddSchedule.end_time, "hh:mm");
    let isAfter = startTime.isAfter(endTime);
    if (isAfter) {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AddSchedule.end_time]);

  useEffect(() => {
    dispatch(
      GetScheduleApi({
        unique_id: userInfo?.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      addFormField();
    }
  };

  const canSubmit =
    !!AddSchedule?.day &&
    !!AddSchedule?.start_time &&
    !!AddSchedule?.end_time &&
    !!AddSchedule?.subject &&
    !!AddSchedule?.year_group &&
    !!AddSchedule?.classroom;

  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin>
          <BackButton
            handleBack={() => {
              history.goBack();
            }}
          />
          <SectionTitle noMargin title="Create Schedule" />
        </FlexWrapper>
        <FlexWrapper noPadding>
          <DropdownWrapper width="14">
            <EditableDropdown
              dropdownList={DayDropdown}
              title="Day"
              isRequired
              isSearchable
              reset={resetValue.day}
              onBlur={() => {
                ValidateField("day");
              }}
              error={errors.day}
              placeholder="Select Day"
              handleSelect={(value: DropdownListProps) => {
                setAddSchedule({ ...AddSchedule, day: value?.name });
                setResetValue({
                  ...resetValue,
                  day: false,
                });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="14">
            <Input
              label="Start Time"
              value={AddSchedule.start_time}
              inputType={"time"}
              isRequired
              height="90px"
              onBlur={() => {
                ValidateField("start_time");
              }}
              error={errors.start_time}
              onChange={(value: string) => {
                setAddSchedule({ ...AddSchedule, start_time: value });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="14">
            <Input
              label="End Time"
              value={AddSchedule.end_time}
              inputType={"time"}
              isRequired
              height="90px"
              onBlur={() => {
                ValidateField("end_time");
              }}
              error={errors.end_time}
              onChange={(value: string) => {
                setAddSchedule({ ...AddSchedule, end_time: value });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="14">
            <Input
              label="Subject/Reference"
              isRequired
              onBlur={() => {
                ValidateField("subject");
              }}
              error={errors.subject}
              value={AddSchedule.subject}
              placeholder="N/A if not applicable"
              onChange={(value: string) => {
                setAddSchedule({ ...AddSchedule, subject: value });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="14">
            <Input
              label="Year group/Name"
              isRequired
              onBlur={() => {
                ValidateField("year_group");
              }}
              error={errors.year_group}
              value={AddSchedule.year_group}
              placeholder="N/A if not applicable"
              onChange={(value: string) => {
                setAddSchedule({ ...AddSchedule, year_group: value });
              }}
            />
          </DropdownWrapper>
          <DropdownWrapper width="12">
            <Form.Group>
              <FormLable>
                Location/Room/Dept<b style={{ color: "red" }}>*</b>
              </FormLable>
              <Form.Control
                type="text"
                id="edu-input"
                value={AddSchedule.classroom}
                onBlur={() => {
                  ValidateField("classroom");
                }}
                isValid={!!AddSchedule.classroom}
                isInvalid={!!errors.classroom}
                onChange={(e) => {
                  setAddSchedule({
                    ...AddSchedule,
                    classroom: e.target.value,
                  });
                }}
                onKeyDown={handleKeyDown}
                placeholder="N/A if not applicable"
              />
              <Form.Control.Feedback type="invalid">
                {errors.classroom}
              </Form.Control.Feedback>
            </Form.Group>
          </DropdownWrapper>

          <CreateScheduleButton
            onClick={addFormField}
            disabled={
              !canSubmit ||
              AddSchedule?.start_time === AddSchedule?.end_time ||
              sameTimeWarning
            }
            marginTop="37px"
          >
            +
          </CreateScheduleButton>
        </FlexWrapper>
        <FlexWrapper justifyContent="center" noPadding>
          {isLoading ? (
            <Loader />
          ) : (
            <PrimaryButton
              style={{ marginTop: "-30px" }}
              onClick={() => {
                if (!canSubmit) {
                  alert(
                    'Please ensure all mandatory fields are completed. If a specific field does not apply to you, enter "N/A"'
                  );
                } else {
                  handleSubmit();
                }
              }}
            >
              Submit
            </PrimaryButton>
          )}
        </FlexWrapper>
        {FinalValues.map((element, i) => (
          <FlexWrapper key={i} noPadding>
            <DropdownWrapper width="14" marginTop="0">
              <Input
                inputType="text"
                height="10px"
                isDisabled={true}
                value={element.day}
              />
            </DropdownWrapper>
            <DropdownWrapper width="14" marginTop="0">
              <Input
                inputType="text"
                height="1px"
                isDisabled={true}
                value={element.start_time}
              />
            </DropdownWrapper>
            <DropdownWrapper width="14" marginTop="0">
              <Input
                inputType="text"
                isDisabled={true}
                height="1px"
                value={element.end_time}
              />
            </DropdownWrapper>
            <DropdownWrapper width="14" marginTop="0">
              <Input
                inputType="text"
                height="1px"
                isDisabled={true}
                value={element.subject}
              />
            </DropdownWrapper>
            <DropdownWrapper width="14" marginTop="0">
              <Input
                inputType="text"
                height="1px"
                isDisabled={true}
                value={element.year_group}
              />
            </DropdownWrapper>
            <DropdownWrapper width="12" marginTop="0">
              <Input
                height="1px"
                inputType="text"
                isDisabled={true}
                value={element.classroom}
              />
            </DropdownWrapper>
            <CreateScheduleButton
              marginTop="auto"
              backgroundColor="red"
              onClick={() => {
                handleRemove(i);
              }}
            >
              -
            </CreateScheduleButton>
          </FlexWrapper>
        ))}
      </CardWraper>

      <ToastMessage
        show={show}
        onCloseHandler={() => {
          setShow(false);
        }}
        top="100px"
        bg={"primary"}
        message={
          " 1It appears there is an entry with overlapping start or end times for this day. Please adjust the schedule times to proceed."
        }
      />
      <ToastMessage
        show={sameTimeWarning}
        onCloseHandler={() => {
          setSameTimeWarning(false);
        }}
        top="100px"
        right="20rem"
        bg={"primary"}
        message={
          " 2 It appears there is an entry with overlapping start or end times for this day. Please adjust the schedule times to proceed."
        }
      />
      <ToastMessage
        show={betweenTime}
        onCloseHandler={() => {
          setBetweenTime(false);
        }}
        top="100px"
        right="20rem"
        bg={"primary"}
        message={
          " 3 It appears there is an entry with overlapping start or end times for this day. Please adjust the schedule times to proceed."
        }
      />
    </PageWrapper>
  );
};

export default AddSchedule;
