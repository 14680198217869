import { ReactElement, useEffect, useState } from "react";
import {
  ActionButton,
  DropdownWrapper,
  FlexWrapper,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { TableHeaderData } from "./const";
import { Table } from "react-bootstrap";
import { CardWraper } from "../myCoveredLesson/subcomponents";
import { GetICovered } from "../../../../../redux/Teachers/CoverManagenment/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { updateSelectedcosultId } from "../../../../../redux/Hq_Management/Vacancy/action";
import { giveFeedBack } from "../../../../../redux/Hq_Management/Vacancy/api";

const ICoveredLesson = (): ReactElement => {
  const {
    userInfo,
    getIcovered,
    isLoading,
    isFeedBackGiven,
    setSelectedConsultantId,
  } = useSelector((state: RootState) => ({
    userInfo: state.login.userInfo,
    getIcovered: state.coverManagement.getIcovered,
    isLoading: state.coverManagement.isLoading,
    isFeedBackGiven: state.Vacancy.isFeedBackGiven,
    setSelectedConsultantId: state.Vacancy.setSelectedConsultantId,
  }));

  const [date, setdate] = useState("");
  const dispatch = useDispatch();

  const [showFeedBack, setshowFeedBack] = useState<any>([]);
  const [rating, setRating] = useState(0);
  const [cmtValues, setcmtValues] = useState("");

  useEffect(() => {
    dispatch(
      GetICovered({
        id: userInfo?.data.id,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isFeedBackGiven) {
      setshowFeedBack([]);
      setRating(0);
      setcmtValues("");
    }
  }, [isFeedBackGiven]);
  return (
    <PageWrapper>
      <CardWraper>
        <FlexWrapper
          backgroundColor="#eff3fa"
          noPadding
          noMargin
          justifyContent="space-between"
        >
          <SectionTitle noMargin title="I Covered Lesson" />
          <FlexWrapper>
            <Input
              value={date}
              inputType="date"
              onChange={(value: string) => {
                setdate(value);
              }}
              height="auto"
              width="20"
            />
          </FlexWrapper>
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getIcovered
                  // eslint-disable-next-line array-callback-return
                  ?.filter((listValues) => {
                    if (date === "") {
                      return listValues;
                    } else if (listValues?.v_date.includes(date)) {
                      return listValues;
                    }
                  })
                  ?.map((data, index) => {
                    const {
                      v_date,
                      from_time,
                      to_time,
                      first_name,
                      last_name,
                      subject,
                      year_group,
                      vid,
                    } = data;
                    const fromTime = moment(from_time, "hh:ss:mm");
                    const endTime = moment(to_time, "hh:ss:mm");
                    const duration = moment.duration(endTime.diff(fromTime));
                    const getHours = duration.hours();
                    const minutes = duration.minutes();
                    let hh = getHours <= 10 ? `${getHours}` : getHours;
                    let mm = minutes <= 10 ? `0${minutes}` : minutes;

                    return (
                      <tr style={{ textAlign: "center" }} key={index}>
                        <td>{index + 1}</td>
                        <td>{moment(v_date).format("DD-MM-YYYY")}</td>
                        <td>{from_time}</td>
                        <td>{to_time}</td>
                        <td>{`${hh}:${mm} hours `}</td>
                        <td>{subject}</td>
                        <td>{year_group}</td>
                        <td>{`${first_name}${last_name}`}</td>
                        <td>
                          <ActionButton
                            onClick={() => {
                              setshowFeedBack([data]);
                              dispatch(
                                updateSelectedcosultId({
                                  id: data?.id,
                                })
                              );
                            }}
                          >
                            Give FeedBack
                          </ActionButton>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
      {showFeedBack?.length && (
        <Modals
          center={true}
          show={showFeedBack?.length}
          handleSubmit={() => {
            dispatch(
              giveFeedBack({
                feedback: cmtValues,
                rating: rating,
                cons_id: setSelectedConsultantId?.id,

                created_by: userInfo?.data?.unique_id,
                postion: showFeedBack[0]?.position,
                v_date: showFeedBack[0]?.v_date,
                vid: showFeedBack[0]?.vid,
              })
            );
          }}
          handleCancel={() => setshowFeedBack([])}
          title="FeedBack"
          sizeModal={"lg"}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <FlexWrapper justifyContent="center">
              <DropdownWrapper width="100">
                <Rating
                  onClick={(rate: number) => {
                    setRating(rate);
                  }}
                  ratingValue={rating}
                  iconsCount={5}
                  transition={true}
                />
              </DropdownWrapper>
              <DropdownWrapper width="100">
                <Input
                  value={cmtValues}
                  label="Comment"
                  inputType="textarea"
                  placeholder="Your Comment"
                  onChange={(value: string) => {
                    setcmtValues(value);
                  }}
                />
              </DropdownWrapper>
            </FlexWrapper>
          )}
        </Modals>
      )}
    </PageWrapper>
  );
};

export default ICoveredLesson;
