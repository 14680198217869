import { ReactElement, useEffect, useState } from "react";
import {
  ActionButton,
  DropdownWrapper,
  EditableDropdown,
  FlexWrapper,
  Icon,
  Input,
  Loader,
  Modals,
  PageWrapper,
  SectionTitle,
} from "../../../../../components";
import { TableHeaderData } from "./const";
import { Modal, ModalHeader, Table } from "react-bootstrap";
import { CardWraper, IsApprovedAction, ReportButton } from "./subcomponents";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import {
  ApprovemyAssignedVacancy,
  denyMyAssignedVacancy,
  GetMyAssignedCover,
  ReportmyAssignedVacancy2,
} from "../../../../../redux/Teachers/CoverManagenment/api";
import moment from "moment";
import { updateSelecetedVacancyDetails } from "../../../../../redux/Teachers/CoverManagenment/action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "../../../../../components/EditableDropdown/subcomponents";
import { BreakTimeDropDown2 } from "../../../../HqManagement/AbsenceManagement/unCovered/createSeperateVacancy/const";
import { DropdownListProps } from "../../../../../components/EditableDropdown/typings";
import { Tooltiped } from "../../../../HqManagement/AbsenceManagement/unCovered/subcomponent";
import PdfViewer from "../../../../../components/PdfViewer";

const MyAssignedCover = (): ReactElement => {
  const dispatch: AppDispatch = useDispatch();

  const {
    AssignedCover,
    id,
    coverManagement: { isLoading, isVacancyDenied, updateDenyDetails },
  } = useSelector((state: RootState) => ({
    AssignedCover: state.coverManagement.getMyAssignedCover,
    coverManagement: state.coverManagement,
    id: state.login.userInfo?.data.id,
  }));

  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const [coverList1, setCoverList1] = useState<any>([]);
  const [coverList2, setCoverList2] = useState<any>([]);
  const [report, setReport] = useState(false);
  const [reportEdit, setReportEdit] = useState(false);
  const [ReportHours, setReportHours] = useState<any>({
    from_time: "",
    to_time: "",
    break_time: "",
    created_by: "",
    vid: "",
    v_date: "",
    day: "",
    report_start_time: "",
    report_end_time: "",
    report_reason: "",
    report_wrkhrs: "",
  });
  const [showPdfView, setShowPdfView] = useState(false);
  const [pdfUrl, setpdfUrl] = useState<any>("");
  const start = moment(ReportHours?.from_time, "HH:mm:ss");
  const end = moment(ReportHours?.to_time, "HH:mm:ss");

  const duration = moment.duration(end.diff(start));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  // Format the result
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")} hours`;
  useEffect(() => {
    dispatch(GetMyAssignedCover({ cus_id: id, status: 0 })).then((res) => {
      setCoverList1(res.payload);
      dispatch(GetMyAssignedCover({ cus_id: id, status: 1 })).then((res) => {
        setCoverList2(res.payload);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Combained = [...coverList1, ...coverList2];
  useEffect(() => {
    if (isVacancyDenied) {
      setShow(false);
      setReason("");
    }
  }, [isVacancyDenied]);
  const isDateTimePassed = (
    targetDate: string,
    targetTime: string
  ): boolean => {
    // Combine the date and time into a single Date object
    const targetDateTime = new Date(`${targetDate}T${targetTime}`);
    // Get the current date and time
    const currentDateTime = new Date();

    // Compare the two
    return currentDateTime > targetDateTime;
  };
  return (
    <PageWrapper>
      <Modal
        show={showPdfView}
        onHide={() => {
          setShowPdfView(false);
        }}
        size="xl"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Pdf
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper
            noPadding
            justifyContent="center"
            style={{ border: "1px dotted #192cbd" }}
          >
            <PdfViewer pdfUrl={`http://13.60.88.61:4000/${pdfUrl}`} />
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={report}
        onHide={() => {
          setReport(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Approve vacancies
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "center" }}>
              Are the hours worked correct and can they be submitted for
              approval?
              <br />
              <br />
              {ReportHours.from_time}-{ReportHours.to_time}
              <br />
              <br />
              Unpaid break time {""}
              {ReportHours.break_time ? ReportHours.break_time : "0 min"}
              <br />
              <br />
              Total worked hours{""} {formattedTime}
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                const payload = {
                  report_start_time: ReportHours.from_time,
                  report_end_time: ReportHours.to_time,
                  report_break_time: ReportHours.break_time
                    ? ReportHours.break_time
                    : "00:00",
                  report_total_whours: formattedTime,
                  report_reason: "null",
                  created_by: ReportHours.created_by,
                  vid: ReportHours.vid,
                };

                dispatch(ReportmyAssignedVacancy2(payload))
                  .then((res) => {
                    if (res?.meta?.requestStatus === "rejected") {
                      alert("Failed ");
                    } else {
                      alert("Success ");
                      dispatch(
                        ApprovemyAssignedVacancy({
                          id: ReportHours?.vid,
                          status: "2",
                        })
                      );
                      dispatch(
                        GetMyAssignedCover({ cus_id: id, status: 0 })
                      ).then((res) => {
                        setCoverList1(res.payload);
                        dispatch(
                          GetMyAssignedCover({ cus_id: id, status: 1 })
                        ).then((res) => {
                          setCoverList2(res.payload);
                        });
                      });
                    }
                  })

                  .catch(() => alert("Failed"));
                setReport(false);
              }}
            >
              ok
            </ActionButton>
            <ActionButton
              onClick={() => {
                setReport(false);
                setReportHours({
                  ...ReportHours,
                  break_time: "",
                });

                setReportEdit(true);
              }}
            >
              Edit
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        show={reportEdit}
        onHide={() => {
          setReportEdit(false);
        }}
        size="sm"
      >
        <ModalHeader closeButton style={{ textAlign: "center" }}>
          Report the actual hours worked
        </ModalHeader>
        <Modal.Body>
          <FlexWrapper justifyContent="center" noPadding>
            <Title style={{ textAlign: "start" }}>
              <b>Vacancy date:</b>
              {""}
              {ReportHours.v_date}
              <br /> <br />
              <b> Day:</b> {ReportHours.day}
              <br /> <br />
              <b>Original time:</b> {ReportHours.from_time}-
              {ReportHours.to_time}
              <br /> <br />
              <Input
                label="Report Start Time"
                value={ReportHours.report_start_time}
                inputType={"time"}
                isRequired
                height="90px"
                onChange={(value: string) => {
                  setReportHours({ ...ReportHours, report_start_time: value });
                }}
              />
              <Input
                label="Report End Time"
                value={ReportHours.report_end_time}
                inputType={"time"}
                isRequired
                height="90px"
                onChange={(value: string) => {
                  setReportHours({ ...ReportHours, report_end_time: value });
                }}
              />
              <EditableDropdown
                title="Unpaid Break time"
                placeholder="Break time"
                dropdownList={BreakTimeDropDown2}
                handleSelect={(value: DropdownListProps) => {
                  setReportHours({ ...ReportHours, break_time: value.id });
                }}
              />
              <br /> <br />
              <Input
                label="Total worked hours"
                value={ReportHours.report_wrkhrs}
                inputType={"time"}
                isRequired
                height="90px"
                onChange={(value: string) => {
                  setReportHours({ ...ReportHours, report_wrkhrs: value });
                }}
              />
              <Input
                label="Report reason"
                value={ReportHours.report_reason}
                inputType={"text"}
                height="90px"
                info
                infoText="Give a reason, e.g.(late arrival, request for later finish,
                 flexible start time allowed) max.50 words"
                onChange={(value: string) => {
                  setReportHours({ ...ReportHours, report_reason: value });
                }}
              />
            </Title>
          </FlexWrapper>
          <FlexWrapper justifyContent="center" noPadding noMargin>
            <ActionButton
              onClick={() => {
                if (
                  !ReportHours.report_start_time ||
                  !ReportHours.report_end_time ||
                  !ReportHours.report_wrkhrs
                ) {
                  alert("Fill all the mandatory filed ");
                } else {
                  const payload = {
                    report_start_time: ReportHours.report_start_time,
                    report_end_time: ReportHours.report_end_time,
                    report_break_time: ReportHours.break_time
                      ? ReportHours.break_time
                      : "00:00",
                    report_total_whours: ReportHours.report_wrkhrs,
                    report_reason: ReportHours.report_reason
                      ? ReportHours.report_reason
                      : "null",
                    created_by: ReportHours.created_by,
                    vid: ReportHours.vid,
                  };
                  dispatch(ReportmyAssignedVacancy2(payload))
                    .then((res) => {
                      if (res?.meta?.requestStatus === "rejected") {
                        alert("Failed ");
                      } else {
                        alert("Success ");
                        dispatch(
                          ApprovemyAssignedVacancy({
                            id: ReportHours?.vid,
                            status: "2",
                          })
                        );
                        dispatch(
                          GetMyAssignedCover({ cus_id: id, status: 0 })
                        ).then((res) => {
                          setCoverList1(res.payload);
                          dispatch(
                            GetMyAssignedCover({ cus_id: id, status: 1 })
                          ).then((res) => {
                            setCoverList2(res.payload);
                          });
                        });
                      }
                    })

                    .catch(() => alert("Failed"));
                  setReportEdit(false);
                }
              }}
            >
              Report
            </ActionButton>
          </FlexWrapper>
        </Modal.Body>
      </Modal>
      <CardWraper>
        <FlexWrapper backgroundColor="#eff3fa" noMargin>
          <SectionTitle noMargin title="My assigned cover" />
        </FlexWrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <Table striped borderless hover>
              <thead
                style={{
                  backgroundColor: "#2097f5",
                  color: "white",
                  fontFamily: "Montserrat",
                }}
              >
                <tr style={{ textAlign: "center" }}>
                  {TableHeaderData.map((data, index) => (
                    <th key={`Header Unique id - ${index}`}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Combained?.map((data, index) => {
                  const {
                    v_date,
                    from_time,
                    to_time,
                    id,
                    subject,
                    vid,
                    first_name,
                    last_name,
                    uncovered_id,
                    cus_id,
                    area_name,
                    year_group,
                    vacancy_status,
                    break_time,
                    created_by,
                    day,
                    lesson_plan_pdf,
                  } = data;
                  // const hasTimePassed = isDateTimePassed(targetDate, targetTime);
                  return (
                    <tr key={index} style={{ textAlign: "center" }}>
                      <td>{index + 1}</td>
                      <td>{moment(v_date).format("DD-MM-YYYY")}</td>
                      <td>{from_time}</td>
                      <td>{to_time}</td>
                      <td>{subject}</td>
                      <td>{year_group}</td>
                      <td>{area_name}</td>
                      <td>{`${first_name}${last_name}`}</td>
                      <td>
                        <Icon>
                          <FontAwesomeIcon
                            icon={["fas", "file-pdf"]}
                            onClick={() => {
                              setShowPdfView(true);
                              setpdfUrl(lesson_plan_pdf);
                            }}
                          />
                        </Icon>
                      </td>
                      <td style={{ width: "150px" }}>
                        {vacancy_status === 1 ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <ReportButton
                              disabled={
                                isDateTimePassed(v_date, to_time) ? false : true
                              }
                              onClick={() => {
                                setReportHours({
                                  from_time: from_time,
                                  to_time: to_time,
                                  break_time:
                                    break_time === "NaN:NaN" ? "" : break_time,
                                  created_by: created_by,
                                  vid: vid,
                                  v_date: v_date,
                                  day: day,
                                });
                                setReport(true);
                              }}
                            >
                              Report
                            </ReportButton>
                            {""}
                            {""}
                            {isDateTimePassed(v_date, to_time) ? (
                              ""
                            ) : (
                              <Tooltiped
                                title="Enable after the End time"
                                placement="top"
                              >
                                <span>
                                  <FontAwesomeIcon
                                    icon={["fas", "info-circle"]}
                                    size="sm"
                                  />
                                </span>
                              </Tooltiped>
                            )}
                          </div>
                        ) : (
                          <IsApprovedAction
                            handleApprove={() => {
                              dispatch(
                                GetMyAssignedCover({ cus_id: id, status: 0 })
                              ).then((res) => {
                                setCoverList1(res.payload);
                                dispatch(
                                  GetMyAssignedCover({ cus_id: id, status: 1 })
                                ).then((res) => {
                                  setCoverList2(res.payload);
                                });
                              });
                              dispatch(
                                ApprovemyAssignedVacancy({
                                  id: vid,
                                  status: "1",
                                })
                              );
                            }}
                            handleDeny={() => {
                              dispatch(
                                updateSelecetedVacancyDetails({
                                  id: vid,
                                  uncoveredId: uncovered_id,
                                  cus_id: cus_id,
                                })
                              );
                              dispatch(
                                GetMyAssignedCover({ cus_id: id, status: 0 })
                              ).then((res) => {
                                setCoverList1(res.payload);
                                dispatch(
                                  GetMyAssignedCover({ cus_id: id, status: 1 })
                                ).then((res) => {
                                  setCoverList2(res.payload);
                                });
                              });
                              setShow(true);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </FlexWrapper>
        )}
      </CardWraper>
      <Modals
        handleCancel={() => {
          setShow(false);
        }}
        center={true}
        handleSubmit={() => {
          dispatch(
            denyMyAssignedVacancy({
              id: updateDenyDetails?.id,
              uncovered_id: updateDenyDetails?.uncoveredId,
              cus_id: updateDenyDetails?.cus_id,
              reason: reason,
            })
          );
        }}
        show={show}
        sizeModal="lg"
        title="Provide reason"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <FlexWrapper justifyContent="center">
            <DropdownWrapper width="90">
              <Input
                inputType="textarea"
                value={reason}
                onChange={(value: string) => {
                  setReason(value);
                }}
                placeholder="Provide a brief reason for declining"
              />
            </DropdownWrapper>
          </FlexWrapper>
        )}
      </Modals>
    </PageWrapper>
  );
};

export default MyAssignedCover;
