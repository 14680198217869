import { Route, Switch } from "react-router-dom";
import ROUTES from "./const/routes";
import ProtectedRoute from "./container/ProtectedRoute";
import {
  ICoveredLesson,
  MyAbsence,
  MyAssignedCover,
  MyCoveredLesson,
  RegisterAbsence,
  MyProfile,
  MySchedule,
  AddSchedule,
  HQ_MyDraft,
  HQ_Covered,
  HQ_AbcentStaff,
  HQ_PendingLeave,
  HQ_CompletedVacancies,
  HQ_PublishedVacancies,
  HQ_ApprovedLeaveRequest,
  HQ_MyAbsence,
  HQ_UnCoveredLesson,
  Dashboard,
  HQUncobveredTable,
  CreatedCustomer,
  CreateCustomer,
  TeacherDashboard,
  CreateSeperateVacancy,
  MyConsultants,
  MyCompletedCover,
  MyAssignedVacancy,
  Statiscs,
  Chat,
  ChatMessage,
  FAQ,
  CreatevacancyWithoutLesson,
  DenyleaveList,
} from "./pages";
import UncoveredVacancyWithoutLesson from "./pages/HqManagement/AbsenceManagement/CoverManagament/unCoveredWithoutLesson";

const Routes = () => {
  return (
    <Switch>
      <Route path={ROUTES.MY_PROFILE} component={MyProfile} />
      <ProtectedRoute path={ROUTES.DASHBOARD} component={Dashboard} />
      <ProtectedRoute path={ROUTES.FAQ} component={FAQ} />
      <ProtectedRoute
        path={ROUTES.INSTITUTE.CREATE_CUSTOMER}
        component={CreateCustomer}
      />
      <ProtectedRoute
        path={ROUTES.INSTITUTE.CREATED_CUSTOMER}
        component={CreatedCustomer}
      />
      <ProtectedRoute
        path={ROUTES.INSTITUTE.MY_CONSULTANTS}
        component={MyConsultants}
      />
      <ProtectedRoute path={ROUTES.INSTITUTE.STATICSTIS} component={Statiscs} />
      <ProtectedRoute path={ROUTES.NEWS} component={Chat} />
      {/* ------------------------------ TEACHER---------------------------------------- */}
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_MY_COVERED_LESSON}
        component={MyCoveredLesson}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_ICOVERED_LESSON}
        component={ICoveredLesson}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_MY_ABSENCE}
        component={MyAbsence}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_MY_ASSIGNED_COVER}
        component={MyAssignedCover}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_REGISTER_LEAVE}
        component={RegisterAbsence}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_MY_SCHEDULE}
        component={MySchedule}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_ADD_SCHEDULE}
        component={AddSchedule}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_DASHBOARD}
        component={TeacherDashboard}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_MY_COMPLETED_COVER}
        component={MyCompletedCover}
      />
      <ProtectedRoute
        path={ROUTES.TEACHER.TEC_MY_ASSIGNEDVACANCY}
        component={MyAssignedVacancy}
      />
      {/* ------------------------------ SCHOOL---------------------------------------- */}
      {/* ------------------------------ HQ_MANAGEMENT---------------------------------------- */}
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_PUBLISHED_VACANCIES}
        component={HQ_PublishedVacancies}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_COMPLETED_VACANCIES}
        component={HQ_CompletedVacancies}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_PENDING_LEAVE}
        component={HQ_PendingLeave}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_APPROVED_LEAVE_REQUEST}
        component={HQ_ApprovedLeaveRequest}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_DENIED_LEAVE_REQUEST}
        component={DenyleaveList}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_ABCENT_STAFF}
        component={HQ_AbcentStaff}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_COVERED}
        component={HQ_Covered}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_MY_DRAFT}
        component={HQ_MyDraft}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_UN_COVERED_LESSON}
        component={HQ_UnCoveredLesson}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_MY_ABSENCE}
        component={HQ_MyAbsence}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_UNCOVERED_TABEL}
        component={HQUncobveredTable}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_UNCOVEREDSEPERATEVACANCY}
        component={CreateSeperateVacancy}
      />
      <ProtectedRoute
        path={ROUTES.HQ_MANAGEMENT.HQ_CHAT_MESSAGE}
        component={ChatMessage}
      />{" "}
      <ProtectedRoute path={ROUTES.HQ_MANAGEMENT.CHAT} component={Chat} />
      <ProtectedRoute
        path={ROUTES.CREATE_VACANCY}
        component={CreatevacancyWithoutLesson}
      />{" "}
      <ProtectedRoute
        path={ROUTES.UNCOVERED_VACANCY_W_LESSON}
        component={UncoveredVacancyWithoutLesson}
      />
    </Switch>
  );
};

export default Routes;
