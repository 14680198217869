export interface AssignedProps {
  absence_id: string;
  absence_staff_name: string;
  assigned_from: string;
  subject?: string;
  location?: string;
  assigned_to_external: string;
  assigned_to_internal: string;
  break_time: string;
  instruction: string;
  created_at: string;
  day: string;
  to_time: string;
  id: string;
  area_name: string;
  year_group: any;
  ins_id: string;
  is_active: string;
  is_draft: string;
  other_info: string;
  position: string;
  position_id: string;
  last_name: string;
  first_name: string;
  publish_to_external: string;
  publish_to_internal: string;
  report_break_time: string;
  report_end_time: string;
  report_reason: string;
  report_start_time: string;
  report_total_whours: string;
  vid: any;
  from_time: string;
  total_whrs: string;
  uncovered_id: string;
  updated_at: string;
  v_date: string;
  cus_id: any;
  vacancy_status: string;
}

export interface getIcoverdProps {
  absence_id: string;
  assigned_from: string;
  assigned_to_external: string;
  assigned_to_internal: string;
  break_time: string;
  subject: string;
  year_group: any;
  created_at: string;
  day: string;
  discription: string;
  end_time: string;
  external_type: string;
  id: string;
  ins_id: string;
  is_active: string;
  is_draft: string;
  location: string;
  other_info: string;
  position: string;
  position_id: string;
  publish_to_external: string;
  publish_to_internal: string;
  report_break_time: string;
  report_end_time: string;
  report_reason: string;
  report_start_time: string;
  report_total_whours: string;
  start_time: string;
  total_whrs: string;
  uncovered_id: string;
  v_date: string;
  vacancy_status: string;
  address: string;
  area_name: string;
  company_name: string;
  email_id: string;
  first_name: string;
  from_time: any;
  to_time: any;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  last_name: string;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  password: string;
  postal_code: string;
  telephone_number: string;
  title: string;
  title_id: string;
  unique_id: string;
  updated_at: string;
  vid: any;
}

export interface myCoveredProps {
  absence_id: string;
  assigned_from: string;
  subject: string;
  assigned_to_external: string;
  assigned_to_internal: string;
  break_time: string;
  created_at: string;
  day: string;
  discription: string;
  to_time: string;
  external_type: string;
  vid: string;
  id: string;
  ins_id: string;
  is_active: string;
  is_draft: string;
  location: string;
  other_info: string;
  position: string;
  is_feedback_given: any;
  position_id: string;
  publish_to_external: string;
  publish_to_internal: string;
  report_break_time: string;
  report_end_time: string;
  report_reason: string;
  report_start_time: string;
  report_total_whours: string;
  from_time: string;
  total_whrs: string;
  uncovered_id: string;
  v_date: string;
  vacancy_status: string;
  address: string;
  area_name: string;
  company_name: string;
  email_id: string;
  first_name: string;
  year_group: any;
  feedback: any;
  invoice_address: string;
  invoice_area_name: string;
  invoice_email_id: string;
  invoice_postal_code: string;
  last_name: string;
  organization_no: string;
  organization_type: string;
  organization_type_id: string;
  postal_code: string;
  telephone_number: string;
  title: string;
  title_id: string;
  unique_id: string;
}

export interface DenyProsp {
  id: any;
  uncoveredId: any;
  cus_id: any;
}

interface InitialState {
  isLoading: boolean;
  getMyCovered: Array<myCoveredProps>;
  getIcovered: Array<getIcoverdProps>;
  gettodayAssignedCoverCount: any;
  gettommorowAssignedCoverCount: any;
  getCustomAssignedCoverCount: any;
  getMyAssignedCover: Array<AssignedProps>;
  isVacancyDenied: boolean;
  updateDenyDetails: DenyProsp;
}

export const initialState: InitialState = {
  isLoading: false,
  getIcovered: [],
  getMyCovered: [],
  getMyAssignedCover: [],
  gettodayAssignedCoverCount: "",
  gettommorowAssignedCoverCount: "",
  getCustomAssignedCoverCount: "",
  isVacancyDenied: false,
  updateDenyDetails: {
    id: "",
    uncoveredId: "",
    cus_id: "",
  },
};
